import { Alert } from 'antd';
import { Link } from 'react-router';
import styled from 'styled-components';

const ComplianceProfileAlert = ({
  isParty,
  partyName,
  complianceProfileName,
  organizationNamespace,
}) => (
  <ComplianceProfileAlert.Alert
    message={
      <>
        {isParty ? (
          <div>
            Editing requirements will create a custom compliance profile,
            assigned only to {partyName}. If you want to update the profile “
            {complianceProfileName}” and apply changes to all associated
            parties, go to the{' '}
            <ComplianceProfileAlert.AlertLink
              to={`${organizationNamespace}/settings/requirements`}
            >
              Compliance profile settings
            </ComplianceProfileAlert.AlertLink>{' '}
            page.
          </div>
        ) : (
          <div>
            Editing requirements might affect all parties currently associated
            with this compliance profile.
          </div>
        )}
      </>
    }
    type="warning"
    showIcon
  />
);

ComplianceProfileAlert.Alert = styled(Alert)`
  position: relative;
  margin-top: 20px !important;
  margin-bottom: 0 !important;
  align-items: baseline;
  font-size: 12px;
  width: 100%;
`;

ComplianceProfileAlert.AlertLink = styled(Link)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  text-decoration: underline;
`;

export default ComplianceProfileAlert;
