import useSetState from '@common/hooks/useSetState';
import { useMemo, useRef, useState } from 'react';
import type { CustomSelectionChangedEvent, TableRef } from '../Table';
import type { FilterChangedParams, TableFilters, TableState } from '../types';

const DEFAULT_STATE: TableState = {
  filters: {},
  sorting: {},
  pagination: {},
};

export const useTableControl = <TState extends TableState>(
  initialState: TState = DEFAULT_STATE as TState,
) => {
  const tableRef = useRef<TableRef>(null);

  const [state, setState] = useSetState<TState>(initialState);
  const [isEmpty, setIsEmpty] = useState(false);

  const [selection, setSelection] = useSetState({
    isSelectAllChecked: false,
    selectedIds: [] as string[],
    selectedRowsData: [] as any[],
  });

  const isBulkActionsButtonsVisible =
    selection.isSelectAllChecked || selection.selectedIds.length > 0;

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
    activeFilters: 0,
  });

  const handleSelectionChanged = (params: CustomSelectionChangedEvent) => {
    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleFilterChanged = (params: FilterChangedParams) => {
    setCounters((c) => ({
      ...c,
      activeFilters: params.activeFiltersCount,
    }));
    setState((s) => ({
      ...s,
      filters: params.activeFilters,
    }));
  };

  const handleSelectAll = () => {
    tableRef.current?.selectAllRows();
  };

  const handleSelectVisible = () => {
    tableRef.current?.selectVisibleRows();
  };

  const handleSelectNone = () => {
    tableRef.current?.resetSelection();
  };

  const refetch = () => {
    tableRef.current?.refreshTable();
  };

  const setFilters = (filters: TableFilters) => {
    tableRef.current?.setFilters(filters, state.filters);
  };

  const hasActiveFilters = useMemo(() => {
    return Object.keys(state.filters || {}).length > 0;
  }, [state.filters]);

  const isTableEmpty = isEmpty && !hasActiveFilters;

  return {
    tableRef,
    handleSelectAll,
    handleSelectVisible,
    handleSelectNone,
    isBulkActionsButtonsVisible,
    selection,
    counters,
    handleSelectionChanged,
    state,
    setState,
    setFilters,
    refetch,
    handleTableEmptyChange: setIsEmpty,
    handleFilterChanged,
    isTableEmpty,
  };
};

export type TableControl = ReturnType<typeof useTableControl>;
