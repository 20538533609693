import { Form, type FormInstance, Input } from 'antd';
import type { Rule } from 'antd/es/form';
import { useEffect } from 'react';
import styled from 'styled-components';

import DatePicker from '@common/components/DatePicker';
import RichTextEditor from '@common/components/RichTextEditor';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import type { Project } from '@graphql/types/graphql';

interface GeneralTabFormProps {
  form: FormInstance;
  project: Project | null;
  visible: boolean;
}

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 18 },
} as const;
const nameRules = [
  {
    required: true,
    whitespace: true,
    message: 'This field is required',
  },
] satisfies Rule[];

const GeneralTabForm = ({ form, project, visible }: GeneralTabFormProps) => {
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }

    if (visible && project) {
      form.setFieldsValue({ name: project.name });
    }
  }, [form, project, visible]);

  return (
    <StyledForm
      data-cy="generalProjectForm"
      form={form}
      name="generalTabForm"
      layout="horizontal"
      wrapperCol={layout.wrapperCol}
      labelCol={layout.labelCol}
    >
      <Form.Item name="name" label="Project name" rules={nameRules}>
        <Input
          data-cy="saveProjectInputName"
          autoFocus
          placeholder="Enter project name..."
        />
      </Form.Item>
      <Form.Item label="Description" name="description">
        <RichTextEditor
          isValid
          minHeight={50}
          placeholder="Enter description..."
          editorState={form.getFieldValue('description')}
          onChange={(editorState) => {
            form.setFieldsValue({ description: editorState });
          }}
        />
      </Form.Item>
      <Form.Item label="Dates" name="dates">
        <DatePicker.RangePicker
          data-cy="projectDates"
          format={defaultDateFormats}
          allowEmpty={[true, true]}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  .ant-form-item-label {
    padding: 0;
  }
`;

export default GeneralTabForm;
