import qs from 'query-string';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useSearchParams from '@common/hooks/useSearchParams';

import type { TableState } from '..';
import { mapTableStateToURLSearchParams } from '../../../utils/mapDocumentsTableStateToURLSearchParams';
import { mapURLSearchParamsToTableState } from '../../../utils/mapURLSearchParamsToDocumentsTableState';

export const useConnectionBetweenTableAndURL = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { searchParams } = useSearchParams();

  const updateURLFromTableState = useCallback(
    (tableState: TableState) => {
      const updatedSearchParams = mapTableStateToURLSearchParams(tableState);
      navigate(`${pathname}?${qs.stringify(updatedSearchParams)}`, {
        replace: true,
      });
    },
    [navigate, pathname],
  );

  const getTableStateFromURL = useCallback(() => {
    return mapURLSearchParamsToTableState(searchParams);
  }, [searchParams]);

  return {
    updateURLFromTableState,
    getTableStateFromURL,
  };
};
