import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import qs from 'query-string';
import * as R from 'ramda';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import { CloudUploadOutlined } from '@ant-design/icons';
import ListPageHeader from '@common/components/ListPageHeader';
import { getDocumentTypes } from '@modules/document-type/selectors';
import ConnectivityBadge from '@modules/integration/components/ConnectivityBadge';
import { getActiveOrganizationData } from '@modules/organization/selectors';
import { getCurrentProject } from '@modules/project/selectors';
import { ReportType } from '@modules/report/constants';
import { getReportById } from '@modules/report/selectors';
import UploadDocumentModal from '@modules/request/components/UploadDocumentModal';
import { Button } from 'antd';
import { getTotalDocumentsCount } from '../selectors';

const DocumentsHeaderContainer = ({
  url,
  showCurrentContext,
  onArchiveStatusChange,
}) => {
  const { partyId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();

  const [isShowUploadModal, setIsShowUploadModal] = useState(false);
  const total = useSelector(getTotalDocumentsCount);
  const documentTypes = useSelector(getDocumentTypes);
  const organization = useSelector(getActiveOrganizationData);
  const currentProject = useSelector(getCurrentProject);
  const currentContext = showCurrentContext ? (
    isEmpty(currentProject) ? (
      `in ${R.prop('name', organization)}`
    ) : (
      <span>
        from parties in{' '}
        {
          <>
            &quot;
            <ConnectivityBadge entity={currentProject} showTitle />{' '}
            {R.prop('name', currentProject)}&quot;
          </>
        }
      </span>
    )
  ) : (
    ''
  );

  const searchParams = useMemo(() => qs.parse(search), [search]);
  const isArchived = R.propEq('status', 'archived', searchParams);
  const filterDocumentTypes = R.prop('type', searchParams);
  const selectedDocumentTypes = R.compose(
    R.join(', '),
    R.map(
      (documentType) =>
        documentType.pluralName || pluralize.plural(documentType.name),
    ),
    R.values,
    R.pick(
      Array.isArray(filterDocumentTypes)
        ? filterDocumentTypes
        : [filterDocumentTypes],
    ),
    R.indexBy(R.prop('_id')),
  )(documentTypes);

  const toggleDocumentisArchiveStatus = () => {
    const newParams = isArchived
      ? R.dissoc('status', searchParams)
      : R.assoc('status', 'archived', searchParams);
    navigate(`${url}?${qs.stringify(R.dissoc('page', newParams))}`, {
      replace: true,
    });
    onArchiveStatusChange();
  };

  const report = useSelector((state) =>
    getReportById(state, R.prop('report', searchParams)),
  );

  const toggleStatusLabel = `show ${isArchived ? 'active' : 'archived'}`;

  return (
    <>
      <UploadDocumentModal
        isShow={isShowUploadModal}
        setIsShow={setIsShowUploadModal}
        party={partyId}
      />
      <ListPageHeader
        report={report}
        controlButtons={
          <Button
            type="primary"
            icon={<CloudUploadOutlined />}
            onClick={() => setIsShowUploadModal(true)}
          >
            Upload documents
          </Button>
        }
        fallbackEntitiesName="documents"
        statusTitleLabel={isArchived ? 'Archived' : 'Active'}
        onToggleStatusClick={toggleDocumentisArchiveStatus}
        toggleStatusButtonLabel={toggleStatusLabel}
        total={total}
        selectedType={selectedDocumentTypes}
        reportsType={ReportType.Documents}
        currentContext={currentContext}
      />
    </>
  );
};

export default DocumentsHeaderContainer;
