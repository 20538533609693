import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

export const UPDATE_NOTES_MUTATION = graphql(`
  mutation UpdateAdditionalNotes($data: UpdateAdditionalNotesInput!) {
    updateAdditionalNotes(data: $data) {
      _id
    }
  }
`);

export const useUpdatePrimaryRecordNote = (primaryRecordId: string) => {
  const [updateNoteMutation, { loading }] = useMutation(UPDATE_NOTES_MUTATION);

  const updateNote = useCallback(
    (note: string | null) => {
      return updateNoteMutation({
        variables: {
          data: { partyId: primaryRecordId, additionalNotes: note },
        },
      });
    },
    [updateNoteMutation, primaryRecordId],
  );

  return {
    updateNote,
    loading,
  };
};
