import { AlignLeftOutlined } from '@ant-design/icons';
import { Button, Cascader, Input, Radio, Tooltip } from 'antd';
import styled from 'styled-components';

import CheckIcon from '@common/icons/CheckIcon';

import { displayRule } from '../utils/rule-helpers';
import CustomRequirement from './CustomRequirement';
import DefinedRequirement from './DefinedRequirement';

const { TextArea } = Input;

const AddRequirement = ({
  description,
  matchingCriteria,
  cascaderOptions,
  isShowForm,
  isCustom,
  isCustomSubject,
  isShowDescription,
  setDescription,
  setMatchingCriteria,
  selectedAttribute,
  selectedOperator,
  onChangeHandle,
  targetValue,
  searchFilter,
  setTargetValue,
  setSelectedAttribute,
  setIsShowDescription,
  setIsShowForm,
  setIsCustom,
  isActiveCreate,
  createRequirement,
  isValidRequirement,
  validateCustomRequirement,
  resetState,
}) =>
  isShowForm ? (
    <StyledWrapper>
      <StyledInputWrapper>
        {!isCustom && (
          <StyledCascaderWrapper>
            <StyledCascader
              data-cy="requirementCascader"
              allowClear={false}
              clearIcon={false}
              options={cascaderOptions}
              value={
                selectedAttribute.attributeId && [
                  selectedAttribute.attributeId,
                  selectedOperator,
                ]
              }
              onChange={onChangeHandle}
              displayRender={(labels) => displayRule(labels)}
              placeholder="Click to select an item or start typing"
              showSearch={{ filter: searchFilter }}
              dropdownMenuColumnStyle={{
                fontSize: '14px',
                fontWeight: '400',
                padding: '5px',
              }}
            />
          </StyledCascaderWrapper>
        )}
        {isCustom ? (
          <CustomRequirement
            selectedAttribute={selectedAttribute}
            setSelectedAttribute={setSelectedAttribute}
            validateCustomRequirement={validateCustomRequirement}
            targetValue={targetValue}
            selectedOperator={selectedOperator}
            setTargetValue={setTargetValue}
          />
        ) : (
          <DefinedRequirement
            targetValue={targetValue}
            selectedOperator={selectedOperator}
            fieldType={selectedAttribute.attributeType}
            setTargetValue={setTargetValue}
          />
        )}
        <Tooltip title="Provide additional information for this requirement">
          <StyledFormButton
            data-cy="addDescriptionButton"
            type="default"
            icon={<AlignLeftOutlined />}
            onClick={() => setIsShowDescription(!isShowDescription)}
          />
        </Tooltip>
        <StyledFormButton
          data-cy="cancelAddRequirementButton"
          onClick={() => {
            setIsShowForm(false);
            resetState();
          }}
        >
          Cancel
        </StyledFormButton>
        <StyledFormButton
          data-cy="addRequirementButton"
          type="primary"
          disabled={!isActiveCreate() || !isValidRequirement}
          onClick={() => {
            if (isValidRequirement) {
              createRequirement();
            }
          }}
        >
          Add
        </StyledFormButton>
      </StyledInputWrapper>
      {isShowDescription && (
        <>
          <StyledDescriptionWrapper>
            <TextArea
              data-cy="descriptionInput"
              rows={2}
              placeholder="Additional information..."
              value={description}
              autoSize={{ minRows: 2, maxRows: 8 }}
              onChange={({ target }) => setDescription(target.value)}
            />
          </StyledDescriptionWrapper>
          <StyledMatchingCriteria>
            <span>Matching criteria:</span>
            <Radio.Group
              defaultValue={matchingCriteria || 'matchOnce'}
              onChange={(e) => {
                setMatchingCriteria(e.target.value);
              }}
            >
              <Radio value="matchOnce">Match once</Radio>
              <Radio value="matchRepeatedly">Match repeatedly</Radio>
            </Radio.Group>
          </StyledMatchingCriteria>
        </>
      )}
    </StyledWrapper>
  ) : (
    <div data-cy="addNewRequirementButton">
      <StyledCustomButton
        onClick={() => {
          setIsShowForm(true);
          setIsCustom(isCustomSubject);
        }}
      >
        <CheckIcon muted />
        <StyledCustomButtonTitle>
          Add new requirement...
        </StyledCustomButtonTitle>
      </StyledCustomButton>
    </div>
  );

const StyledWrapper = styled.div`
  margin: 5px 0 10px;
  padding: 10px;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid #ffe58e;
  background: #fefbe6;
`;

const StyledInputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  > * {
    margin: 0 9px 0 0 !important;
  }

  .ant-input-number {
    flex: 1;
  }
`;

const StyledCascaderWrapper = styled.span`
  flex: 3;
  margin: 0;
`;

const StyledCascader = styled(Cascader)`
  && {
    width: 100%;
  }
`;

const StyledFormButton = styled(Button)`
  margin-left: 10px;

  &:last-child {
    margin-right: 0 !important;
  }
`;

const StyledDescriptionWrapper = styled.div`
  margin: 10px 0 0;
`;

const StyledMatchingCriteria = styled.div`
  margin: 10px 0 0;

  .ant-radio-wrapper,
  > span {
    font-size: 13px;
  }

  > span {
    margin-right: 10px;
  }
`;

const StyledCustomButton = styled.div`
  margin: 5px 0 10px;
  padding: 4px 3px 4px 10px;
  width: 100%;
  border: 1px dashed #cdcdcd;
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const StyledCustomButtonTitle = styled.span`
  margin-left: 10px;
  font-size: 13px;
`;

export default AddRequirement;
