import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { PrimaryRecord_PortalQuery } from '@graphql/types/graphql';
import { useCallback } from 'react';

export type PrimaryRecordData = PrimaryRecord_PortalQuery['primaryRecord'];

export const PRIMARY_RECORD_PORTAL_QUERY = graphql(`
  query PrimaryRecord_Portal($primaryRecordInput: PrimaryRecordInput!, $input: PortalAttachmentsInput) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      portal {
        attachments(input: $input) {
          nodes {
            friendlyName
            s3Key
            uploadedAt
          }
          totalCount
        }
        message
      }
    }
  }
`);

export const usePortalData = ({
  recordId,
  onFetchCompleted,
}: {
  recordId: string;
  onFetchCompleted?: (data: PrimaryRecordData) => void;
}) => {
  const { data, loading, refetch, fetchMore } = useQuery(
    PRIMARY_RECORD_PORTAL_QUERY,
    {
      variables: {
        primaryRecordInput: { id: recordId },
        input: { offset: 0, first: 10 },
      },
      fetchPolicy: 'cache-first',
      skip: !recordId,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        onFetchCompleted?.(data.primaryRecord);
      },
    },
  );

  const fetchMoreAttachments = useCallback(async () => {
    const currentNodes = data?.primaryRecord?.portal?.attachments?.nodes;
    if (!currentNodes) return;

    await fetchMore({
      variables: {
        input: {
          offset: currentNodes.length,
          first: 10,
        },
      },
    });
  }, [fetchMore, data?.primaryRecord?.portal?.attachments]);

  return {
    data: data?.primaryRecord.portal,
    loading,
    refetchPortalData: refetch,
    fetchMoreAttachments,
  };
};
