import { useQuery } from '@graphql/hooks';

import { graphql } from '@graphql/types';

const REQUEST_RECORD_COMPLIANCE_QUERY = graphql(`
  query RequestRecordCompliance($requestRecordInput: RequestRecordInput!) {
    requestRecord(input: $requestRecordInput) {
      _id
      name
      status
      contextRecord {
        _id
        name
      }
      complianceModules {
        code
        label
        subjects {
          code
          label
          status
          effectiveDate
          latestExpirationDate
          requirements {
            attributeCode
            attributeType
            attributeLabel
            attributeDescription
            documentAttributeCode
            matchingCriteria
            operator
            targetValue
            value
            status
            invalidatedBy
            notes
            publicNotes
            resetOn
          }
          notes
          resetOn
          expiring
          expired
        }
      }
      complianceProfile {
        _id
        name
        baseComplianceProfile
      }
      fillableForms(input: { filler: "requester" }) {
        _id
        status
        name
        additionalInfo
      }
      complianceStats {
        compliantSubjects
        totalSubjects
      }
      documentsChecklist {
        documentType {
          _id
          name
        }
        attributes {
          code
          label
        }
      }
    }
  }
`);

export const useRequestRecordComplianceQuery = ({
  requestId,
}: {
  requestId?: string;
}) => {
  const { data, loading, error, refetch } = useQuery(
    REQUEST_RECORD_COMPLIANCE_QUERY,
    {
      variables: {
        requestRecordInput: { id: requestId },
      },
      skip: !requestId,
    },
  );

  return {
    requestRecord: data?.requestRecord,
    loadingRequestRecordCompliance: loading,
    errorRequestRecordCompliance: error,
    refetchRequestRecordCompliance: refetch,
  };
};
