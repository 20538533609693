import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

interface DebouncedFilterOptions<T> {
  delay?: number;
  initialValue?: T | null;
}

/**
 * A custom hook that creates a debounced version of a filter update function with state management.
 * Useful for handling frequent updates (like search inputs) while limiting API calls or expensive operations.
 *
 * @param fn - The function to be debounced
 * @param options - Configuration options
 * @param options.delay - Debounce delay in milliseconds
 * @param options.initialValue - Initial state value
 * @returns Array containing current state and setState function
 *
 * @example
 * const [searchTerm, setSearchTerm] = useStateWithDebouncedCallback(
 *   (value: string) => updateSearch(value),
 *   { delay: 500, initialValue: '' }
 * );
 */
export const useStateWithDebouncedCallback = <T>(
  fn: (value: T | null) => void,
  { delay = 250, initialValue = null }: DebouncedFilterOptions<T> = {},
): [T | null, React.Dispatch<React.SetStateAction<T | null>>] => {
  const debouncedFn = useMemo(() => debounce(fn, delay), [fn, delay]);
  const stateAndSetter = useState<T | null>(() => initialValue);
  const [state] = stateAndSetter;

  // Handle debounced updates and cleanup
  useEffect(() => {
    debouncedFn(state);

    return () => {
      debouncedFn.cancel();
    };
  }, [debouncedFn, state]);

  return stateAndSetter;
};
