import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { Typography, message } from 'antd';
import { useActivateRequestRecordsMutation } from '../../hooks/useActivateRequestRecordsMutation';

type ActivateRequestRecordsModalProps = {
  open: boolean;
  count: number;
  filters?: ServerFilterInput;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const ActivateRequestRecordsModal = ({
  open,
  count = 1,
  filters,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: ActivateRequestRecordsModalProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const { activateRequestRecords, isActivatingRequestRecords } =
    useActivateRequestRecordsMutation();

  const handleActivate = async () => {
    const res = await activateRequestRecords(filters);

    const status = res.data?.activateRequestRecords.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `${count} ${count > 1 ? 'requests have been' : 'request has been'} activated successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${count} ${count > 1 ? 'requests have been' : 'request has been'} scheduled for activation.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The activation of your ${count > 1 ? 'requests have' : 'request has'} failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const name = count > 1 ? 'requests' : 'request';

  return (
    <ModalConfirm
      data-cy="activate-request-records-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        loading: isActivatingRequestRecords,
      }}
      okText="Make Active"
      title={`Are you sure you want to activate ${count} ${name}?`}
      onCancel={onCancel}
      onOk={handleActivate}
    >
      <Typography.Paragraph>
        Please confirm that you want to activate {count} {name}
      </Typography.Paragraph>
      <ul>
        <li>
          {count > 1 ? 'They' : 'It'} will show up in your reports
          {isComplianceRequirementsAddOnEnabled ? ' and dashboard' : ''}
        </li>
        <li>Automatic requests for expired documents will be sent</li>
      </ul>
    </ModalConfirm>
  );
};
