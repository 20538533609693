import qs from 'query-string';
import * as R from 'ramda';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

import ListPageFilterTags from '@common/components/ListPageFilterTags';
import { getProjectCustomFields } from '@modules/custom-field/selectors';
import { ReportType } from '@modules/report/constants';
import SaveReportModal from '@modules/report/containers/SaveReportModal.legacy';

import { normalizedProjectsFilters } from '../utils/normalizedProjectsFilters';

const ProjectFilterTagsContainer = ({ onResetFilters }) => {
  const [isVisibleSaveReportModal, setIsVisibleSaveReportModal] =
    useState(false);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const customFields = useSelector(getProjectCustomFields);

  const searchParams = qs.parse(search);

  const handleOnCloseChip = (filterCode) => {
    const omittedParams = R.compose(
      (keys) => R.omit(keys, searchParams),
      R.filter(
        (filterKey) => ~filterKey.indexOf(filterCode) || filterKey === 'report',
      ),
      R.keys,
    )(searchParams);
    navigate(`${pathname}?${qs.stringify(omittedParams)}`, { replace: true });
  };

  const normalizedFilters = normalizedProjectsFilters({
    customFields,
    activeFilters: searchParams,
  });

  return (
    <ProjectFilterTagsContainer.Wrapper>
      <SaveReportModal
        reportsType={ReportType.Projects}
        isVisible={isVisibleSaveReportModal}
        onClose={() => setIsVisibleSaveReportModal(false)}
      />
      <ListPageFilterTags
        isSaveReportVisible={!searchParams?.report}
        filters={normalizedFilters}
        onSaveReport={() => setIsVisibleSaveReportModal(true)}
        onResetFilters={onResetFilters}
        onClose={handleOnCloseChip}
      />
    </ProjectFilterTagsContainer.Wrapper>
  );
};

ProjectFilterTagsContainer.Wrapper = styled.div`
  display: flex;
`;

ProjectFilterTagsContainer.SaveReportButton = styled.ins`
  display: flex;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export default ProjectFilterTagsContainer;
