import { Drawer, Form, type FormInstance, Modal } from 'antd';
import type { DocumentChecklistFormData } from '../DocumentCheckListForm';

type DocumentCheckListFormDrawerProps = {
  visible: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onSubmit: (data: DocumentChecklistFormData) => void;
  form: FormInstance<DocumentChecklistFormData>;
  onValuesChange?: (
    changedValues: any,
    allValues: DocumentChecklistFormData,
  ) => void;
};

export const DocumentCheckListFormDrawer = ({
  onClose,
  children,
  onValuesChange,
  onSubmit,
  visible,
  form,
}: DocumentCheckListFormDrawerProps) => {
  const handleSubmit = async (values: DocumentChecklistFormData) => {
    const isValid = await form.validateFields();

    if (!isValid) return;

    onSubmit(values);
  };

  const handleClose = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: 'There are unsaved changes. Do you want to save them?',
        okText: 'Save changes',
        cancelText: 'Close without saving',
        width: 480,
        onCancel: () => {
          onClose();
        },
        onOk: () => {
          onClose();
          handleSubmit(form.getFieldsValue());
        },
        zIndex: 1005,
      });
    } else {
      onClose();
    }
  };

  return (
    <Drawer
      title="Checklists"
      onClose={handleClose}
      open={visible}
      width={800}
      contentWrapperStyle={{ minWidth: '550px' }}
    >
      <Form
        form={form}
        initialValues={{
          name: '',
          documentTypesChecklists: [],
        }}
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
      >
        {children}
      </Form>
    </Drawer>
  );
};
