import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Button, Dropdown, Menu, Switch } from 'antd';
import React from 'react';
import styled from 'styled-components';

import AdCircleInfo from '@common/components/AdCircleInfo';
import DotsIcon from '@common/icons/Dots';
import {
  getIsCustomModule,
  getIsFillableFormModule,
  getIsSurveyModule,
  getSubjectsByModuleDividedByStatus,
} from '@modules/compliance-profile/utils/compliance-attributes-helpers';

import DrawerSubjectsContainer from '../containers/DrawerSubjectsContainer';

import deleteEntityModal from './DeleteEntityModal';

const ComplianceDrawerModule = ({
  moduleLabel,
  templates,
  moduleId,
  requirements,
  modulesRefs,
  index,
  setCurrentRequirements,
  setIsActiveSubject,
  setEditSubjectId,
  setEditSubjectValue,
  setEditModuleId,
  setEditModuleValue,
  setIsVisibleAddSubjectModal,
  setIsVisibleAddFillableFormModal,
  setSelectedSubjectData,
  openSelectSurveyModal,
}) => {
  const { activeSubjects, inactiveSubjects } =
    getSubjectsByModuleDividedByStatus(moduleId, requirements, templates);

  const isCustomModule = getIsCustomModule(moduleId, templates);
  const isFillableFormModule = getIsFillableFormModule({ moduleId });
  const isSurveyModule = getIsSurveyModule({ moduleId });

  return (
    <Draggable draggableId={`${moduleId}`} index={index}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <StyledModuleWrapper
            data-cy="drawerModuleWrapper"
            ref={(node) => (modulesRefs[moduleId] = node)}
            id={`drawer_${moduleId}`}
            isDragging={snapshot.isDragging}
          >
            <StyledModuleTitle data-cy="drawerModuleTitle">
              <StyledDragIconWrapper
                {...provided.dragHandleProps}
                className="dragIconWrapper"
                data-cy="dragModule"
              >
                <StyledDragIcon />
              </StyledDragIconWrapper>
              <p>{moduleLabel}</p>
              {isCustomModule && (
                <AdCircleInfo
                  tooltipText="Custom module"
                  fontSize="16px"
                  marginLeft="-10px"
                />
              )}
              {isCustomModule && (
                <StyledKebabContainer>
                  <Dropdown
                    data-cy="dropdownMenu"
                    dropdownRender={() => (
                      <Menu>
                        <Menu.Item
                          key="complianceEditorCustomModuleEdit"
                          onClick={() => {
                            setEditModuleId(moduleId);
                            setEditModuleValue(moduleLabel);
                          }}
                        >
                          Edit custom module
                        </Menu.Item>
                        <Menu.Item
                          key="complianceEditorCustomModuleDelete"
                          onClick={() => {
                            deleteEntityModal({
                              entityName: 'module',
                              entityLabel: moduleLabel,
                              onOk: () => {
                                setCurrentRequirements(
                                  requirements.filter(
                                    (x) => x.moduleId !== moduleId,
                                  ),
                                );
                              },
                            });
                          }}
                        >
                          Delete custom module
                        </Menu.Item>
                      </Menu>
                    )}
                    trigger={['click']}
                  >
                    <StyledActionButton
                      data-cy="complianceDrawerActionButton"
                      shape="circle"
                      type="link"
                    >
                      <DotsIcon width="21" height="24" />
                    </StyledActionButton>
                  </Dropdown>
                </StyledKebabContainer>
              )}
            </StyledModuleTitle>
            <Droppable droppableId={`${moduleId}`} type={`${moduleId}_subject`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {activeSubjects.map((subject, index) => (
                    <React.Fragment key={`key_${subject.subjectId}`}>
                      <DrawerSubjectsContainer
                        moduleId={moduleId}
                        moduleLabel={moduleLabel}
                        subject={subject}
                        index={index}
                        setIsActiveSubject={setIsActiveSubject}
                        setEditSubjectId={setEditSubjectId}
                        setEditSubjectValue={setEditSubjectValue}
                        draggable={true}
                      />
                    </React.Fragment>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {inactiveSubjects.map((subject, index) => (
              <React.Fragment key={`key_${subject.subjectId}`}>
                <DrawerSubjectsContainer
                  moduleId={moduleId}
                  moduleLabel={moduleLabel}
                  subject={subject}
                  index={index}
                  setIsActiveSubject={setIsActiveSubject}
                  setEditSubjectId={setEditSubjectId}
                  setEditSubjectValue={setEditSubjectValue}
                  draggable={false}
                />
              </React.Fragment>
            ))}
            <StyledAddButtonContainer>
              <StyledCustomButton
                data-cy="addNewRequirementGroupButton"
                onClick={() => {
                  if (isFillableFormModule) {
                    setIsVisibleAddFillableFormModal(true);
                  } else if (isSurveyModule) {
                    openSelectSurveyModal();
                  } else {
                    setIsVisibleAddSubjectModal(true);
                    setSelectedSubjectData({ moduleId, moduleLabel });
                  }
                }}
              >
                <Switch size="small" />
                <StyledCustomButtonTitle>
                  {isFillableFormModule || isSurveyModule
                    ? 'Add new form...'
                    : 'Add new requirement group...'}
                </StyledCustomButtonTitle>
              </StyledCustomButton>
            </StyledAddButtonContainer>
          </StyledModuleWrapper>
        </div>
      )}
    </Draggable>
  );
};

const StyledModuleWrapper = styled.div`
  position: relative;
  margin: 30px 0;
  padding-left: 10px;
  box-shadow: ${(props) =>
    props.isDragging
      ? `0 0 50px 2px rgba(110, 110, 110, 0.2)`
      : `0 0 15px 2px rgba(110, 110, 110, 0.1)`};
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  transition: box-shadow 0.3s;
  background: white;
`;

const StyledModuleTitle = styled.div`
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;

  p {
    margin: 0 20px 0 0;
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.grayishBlue};
  }

  &:hover {
    .dragIconWrapper {
      div {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
`;

const StyledDragIconWrapper = styled.div`
  position: absolute;
  left: 12px;
  width: 10px;
  height: 16px;
  overflow: hidden;
`;

const StyledDragIcon = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/drag-and-drop.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  transform: translateX(-100%);
  opacity: 0;
  transition:
    transform 0.2s,
    opacity 0.2s;
`;

const StyledKebabContainer = styled.div`
  margin-left: auto;
`;

const StyledActionButton = styled(Button)`
  && {
    opacity: 0.5;
    font-size: 16px;
    top: 2px;
    color: ${(props) => props.theme.colors.paleGray};

    &&:hover {
      opacity: 1;
      color: initial;
    }
  }
`;

const StyledAddButtonContainer = styled.div`
  margin: 8px 0 20px 28px;
`;

const StyledCustomButton = styled.div`
  margin-left: -16px;
  padding: 4px 3px 4px 8px;
  width: 100%;
  border: 1px dashed #cdcdcd;
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const StyledCustomButtonTitle = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;

export default ComplianceDrawerModule;
