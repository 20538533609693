import { type ReactElement, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import AppConfig from '@config/AppConfig';

import { FirebaseAuthContext } from '../components/FirebaseAuthProvider';
import { LOGOUT_REASONS } from '../constants';
import { getIsAuthenticated } from '../selectors';

type WithAuthSessionExpirationCheck = (
  Page: () => JSX.Element,
) => (props: any) => ReactElement<any, any> | null;

const CHECK_INTERVAL = 60 * 1000; // 1 minute

const withAuthSessionExpirationCheck: WithAuthSessionExpirationCheck = (Page) =>
  function InnerAuthSessionExpirationCheck(props) {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const navigate = useNavigate();
    const { auth } = useContext(FirebaseAuthContext);

    useEffect(() => {
      if (!isAuthenticated) {
        return;
      }

      const checkAuthSessionExpiration = async () => {
        try {
          const token = await auth?.currentUser?.getIdToken();
          const res = await fetch(`${AppConfig.apiUrl}/auth-check`, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          });
          const data = await res.json();

          if (!data?.authenticated) {
            clearInterval(intervalId);
            navigate(`/logout?logout_reason=${LOGOUT_REASONS.SESSION_TIMEOUT}`);
          }
        } catch (error) {
          if (error instanceof Error && !error.message.includes('Network')) {
            throw error;
          }
        }
      };

      const intervalId = setInterval(
        checkAuthSessionExpiration,
        CHECK_INTERVAL,
      );

      return () => clearInterval(intervalId);
    }, [isAuthenticated, auth?.currentUser, navigate]);

    return <Page {...props} />;
  };

export default withAuthSessionExpirationCheck;
