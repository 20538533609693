import { Link } from 'react-router';
import styled from 'styled-components';

import FileNameWithBadges from '@common/components/FileName/FileNameWithBadges';

const Documents = ({
  title,
  documents,
  reviewPath,
  totalDocumentsCount,
  projectId,
  organizationNamespace,
}) =>
  documents.length > 0 && (
    <Documents.Wrapper>
      <Documents.Title>
        {title} ({totalDocumentsCount})
      </Documents.Title>
      <div>
        {documents.map((document) => (
          <FileNameWithBadges
            key={document._id}
            filename={document.friendlyName || document.s3Key}
            fileData={document}
            party={null}
            projectId={projectId}
            organizationNamespace={organizationNamespace}
          />
        ))}
      </div>
      {documents.length < totalDocumentsCount && (
        <Documents.Link to={reviewPath}>
          + {totalDocumentsCount - documents.length} more...
        </Documents.Link>
      )}
    </Documents.Wrapper>
  );

Documents.Title = styled.div`
  color: ${(props) => props.theme.colors.grayishBlue};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
`;

Documents.Wrapper = styled.div`
  margin-bottom: 30px;
`;

Documents.Link = styled(Link)`
  font-size: 12px;
`;

export default Documents;
