import Icon, {
  CloudDownloadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Badge, Dropdown, Menu, Popover, Tooltip, Typography } from 'antd';
import { Link } from 'react-router';
import styled from 'styled-components';

import ExpirationBadgePopover from '@common/components/Badges/components/ExpirationBadgePopover';
import FlagBadgePopover from '@common/components/Badges/components/FlagBadgePopover';
import ProjectAssociationBadgePopover from '@common/components/Badges/components/ProjectAssociationBadgePopover';
import VerificationBadgePopover from '@common/components/Badges/components/VerificationBadgePopover';
import Column from '@common/components/Column';
import DocumentLinkName from '@common/components/DocumentLinkName';
import PopoverContent from '@common/components/PopoverContent';
import { DAYJS_TABLE_FORMAT } from '@common/constants';
import DotsIcon from '@common/icons/Dots';
import { utc } from '@common/utils/date';
import { trackEvent } from '@common/utils/track-helpers';
import { ROLES } from '@modules/organization-member/models/data';

import { getSubjects } from '../utils/document-helpers';
import { getDocumentFirstExpirationDate } from '../utils/getDocumentFirstExpirationDate';
import { TypeCount } from './DocumentCard';
import DocumentReviewStatusTag from './DocumentReviewStatusTag';
import SubjectBadgePopover from './SubjectBadgePopover';

const { Paragraph } = Typography;

const NormalizedDocument = ({
  organizationNamespace,
  partyId,
  document,
  isArchived,
  isContextsAddOnEnabled,
  isBaseAddOnEnabled,
  detachFromCurrentParty,
  assignToParty,
  associateToProject,
  detachFromCurrentProject,
  updateDocumentArchiveStatus,
  reviewDocument,
  deleteDocument,
  onHandleClickDocumentName,
  currentProjectId,
  downloadDocument,
  memberRole,
}) => {
  const friendlyName = document?.friendlyName || '';
  const notes = (document?.notes || '').trim();
  const flagData = document?.flag || {};
  const verificationData = document?.verification || {};
  const [type, ...types] = (document?.types || []).map(
    (type) => type?.name || '',
  );

  const shouldDetachFromProjectDisable =
    currentProjectId === null ||
    currentProjectId === undefined ||
    !(document.projects || []).some(
      (project) => project._id === currentProjectId,
    ) ||
    document.appliesToAllProjects;

  const isViewer = memberRole === ROLES.VIEWER;
  const inDocumentsPage = !Boolean(partyId);

  const actionItems = [
    {
      label: 'Assign to party',
      key: 'document-assign-to-party',
      isVisible: inDocumentsPage,
      onClick: () => assignToParty(document),
    },
    {
      label: 'Detach from current party',
      key: 'document-detach-from-party',
      disabled: !Boolean(document.party),
      isVisible: inDocumentsPage && !isViewer,
      onClick: () => detachFromCurrentParty(document),
    },
    {
      label: 'Associate to project',
      key: 'document-associate-to-party',
      isVisible: isContextsAddOnEnabled && inDocumentsPage && !isViewer,
      onClick: () => associateToProject(document),
    },
    {
      label: 'Detach from current project',
      key: 'document-detach-from-project',
      disabled: shouldDetachFromProjectDisable,
      isVisible: isContextsAddOnEnabled && !isViewer,
      onClick: () => detachFromCurrentProject(document),
    },
    {
      label: 'Mark as reviewed',
      key: 'document-review-check',
      isVisible: !isViewer,
      onClick: () => reviewDocument(document),
    },
    {
      label: isArchived ? 'Unarchive document' : 'Archive document',
      key: 'document-archive-check',
      isVisible: !isViewer,
      onClick: () => updateDocumentArchiveStatus(document),
    },
    {
      label: 'Delete document',
      key: 'document-delete',
      isVisible: !isViewer,
      onClick: () => deleteDocument(document),
    },
  ];

  const availableActions = actionItems.filter((item) => item.isVisible);

  const expirationDate = isBaseAddOnEnabled
    ? document.expirationDate
    : getDocumentFirstExpirationDate({
        metadata: document.metadata,
        recordRequirements: document.party?.requirements,
      });

  return {
    key: document._id,
    friendlyName: (
      <Column>
        <div>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            {document?.party ? (
              <DocumentLinkName document={document}>
                <NormalizedDocument.DocumentName
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                  }}
                >
                  {friendlyName}
                </NormalizedDocument.DocumentName>
              </DocumentLinkName>
            ) : (
              <NormalizedDocument.DocumentName
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
                onClick={() => onHandleClickDocumentName(document)}
              >
                <Tooltip
                  mouseEnterDelay={0.5}
                  placement="topLeft"
                  title={friendlyName}
                >
                  {friendlyName}
                </Tooltip>
              </NormalizedDocument.DocumentName>
            )}
            {Boolean(notes.length) && (
              <NormalizedDocument.Notes
                title="Notes"
                trigger="click"
                content={
                  <PopoverContent>
                    <Paragraph
                      ellipsis={{ rows: 4, expandable: notes.length > 20 }}
                      style={{ width: '400px' }}
                    >
                      {notes}
                    </Paragraph>
                  </PopoverContent>
                }
              >
                <InfoCircleOutlined />
              </NormalizedDocument.Notes>
            )}
          </div>
          <div>
            <NormalizedDocument.TypeName>
              {type || <i>No document type</i>}
            </NormalizedDocument.TypeName>
            {types?.length > 0 && (
              <span
                style={{ display: 'inline-block', margin: '0 10px 0 -10px' }}
              >
                <Tooltip title={types.join(', ')}>
                  <TypeCount>+{types.length}</TypeCount>
                </Tooltip>
              </span>
            )}
            <DocumentReviewStatusTag
              isReviewed={Boolean(document.reviewedAt)}
            />
          </div>
        </div>
        <NormalizedDocument.Badges>
          {Boolean(document) && (
            <FlagBadgePopover
              documentData={{
                _id: document._id || '',
                issuedBy: document.issuedBy || {},
                documentName: document.friendlyName || null,
              }}
              flagData={flagData}
              sizeIcon="Large"
              defaultVisible
              isShortVersion
            />
          )}
          {Boolean(document) && (
            <VerificationBadgePopover
              documentData={{
                _id: document._id || '',
                issuedBy: document.issuedBy || {},
                documentName: document.friendlyName || null,
              }}
              verificationData={verificationData}
              sizeIcon="Large"
              isShortVersion
            />
          )}
          {Boolean(document) && (
            <ProjectAssociationBadgePopover
              documentData={{
                _id: document._id || '',
                appliesToAllProjects: document.appliesToAllProjects || false,
                projects: document.projects || [],
                issuedBy: document.issuedBy || {},
                documentName: document.friendlyName || null,
              }}
              sizeIcon="Large"
              currentProjectId={currentProjectId}
            />
          )}
        </NormalizedDocument.Badges>
      </Column>
    ),
    party: (
      <Column>
        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {document?.party?.name ? (
            <Link
              to={`${organizationNamespace}/parties/${document.party._id}/overview`}
            >
              <NormalizedDocument.PartyName
                ellipsis={{ rows: 2, expandable: false }}
                data-cy="documentsListPartyName"
              >
                {document.party.name}
              </NormalizedDocument.PartyName>
            </Link>
          ) : (
            'Not assigned'
          )}
        </div>
      </Column>
    ),
    subject: (
      <Column>
        {getSubjects(document).length > 0 ? (
          <NormalizedDocument.Subjects data-cy="subjectsBadgeWrapper">
            {getSubjects(document).map((subject) => (
              <SubjectBadgePopover
                key={`key_${subject.name}`}
                subject={subject}
              >
                <NormalizedDocument.Badge
                  dot={!!subject.expColor}
                  color={subject.expColor}
                >
                  <NormalizedDocument.Abbr data-cy="subjectBadgeAbbr">
                    {subject.abbr}
                  </NormalizedDocument.Abbr>
                </NormalizedDocument.Badge>
              </SubjectBadgePopover>
            ))}
          </NormalizedDocument.Subjects>
        ) : (
          '—'
        )}
      </Column>
    ),
    createdAt: (
      <Column>
        <span>{utc(document.createdAt).format(DAYJS_TABLE_FORMAT)}</span>
      </Column>
    ),
    expirationDate: (
      <Column>
        <NormalizedDocument.ExpirationDateContainer>
          <ExpirationBadgePopover
            expirationDate={expirationDate}
            sizeIcon="Medium"
          />
          <span>
            {expirationDate
              ? utc(expirationDate).format(DAYJS_TABLE_FORMAT)
              : '—'}
          </span>
        </NormalizedDocument.ExpirationDateContainer>
      </Column>
    ),
    menu: (
      <Column>
        <NormalizedDocument.MenuWrapper data-cy="normalizedDocumentMenuWrapper">
          <NormalizedDocument.CloudDownloadOutlined
            disabled={document?.connectedLicense}
            onClick={() => {
              if (document?.connectedLicense) {
                return;
              }
              downloadDocument(document);
              trackEvent('User downloaded a document');
            }}
          />
          {availableActions.length > 0 && (
            <Dropdown
              dropdownRender={() => <Menu items={availableActions} />}
              trigger={['click']}
            >
              <NormalizedDocument.IconButton
                data-cy="documentListMenuButton"
                component={DotsIcon}
              />
            </Dropdown>
          )}
        </NormalizedDocument.MenuWrapper>
      </Column>
    ),
  };
};

NormalizedDocument.DocumentName = styled(Paragraph)`
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
  color: inherit;
`;

NormalizedDocument.Notes = styled(Popover)`
  position: relative;
  top: 7px;
  left: 5px;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

NormalizedDocument.PartyName = styled(Paragraph)`
  color: inherit;
  margin-bottom: 0 !important;
`;

NormalizedDocument.TypeName = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  margin-right: 10px;

  i {
    font-style: normal;
    color: #aaa;
  }
`;

NormalizedDocument.Badges = styled.div`
  display: grid;
  grid-template-columns: 20px 20px 20px;
  gap: 13px;
  padding: 0 10px;
`;

NormalizedDocument.RequestId = styled.span`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.blue};
`;

NormalizedDocument.Subjects = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 7px;

  &:empty {
    margin-top: 0;
  }
`;

NormalizedDocument.ExpirationDateContainer = styled.div`
  display: flex;
  align-items: center;
`;

NormalizedDocument.Badge = styled(Badge)`
  background-color: #fff;
  margin-right: 5px !important;
  margin-bottom: 5px !important;

  .ant-badge-dot {
    background-color: ${(props) => props.color} !important;
    z-index: auto;
  }
`;

NormalizedDocument.Abbr = styled.div`
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.gray};
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grayText};
  user-select: none;
`;

NormalizedDocument.Download = styled.div`
  width: 35px;
  height: 35px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-size: 20px;
  color: ${(props) => props.theme.colors.grayText};
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
  }
`;

NormalizedDocument.MenuWrapper = styled.div`
  margin-left: auto;
  white-space: nowrap;
`;

NormalizedDocument.IconButton = styled(Icon)`
  font-size: 18px;
  color: #595959;

  &:last-child {
    margin-left: 30px;
    margin-right: 10px;
  }
`;

NormalizedDocument.CloudDownloadOutlined = styled(CloudDownloadOutlined)`
  font-size: 18px;
  color: ${(props) => (props.disabled ? '#c5c5c5' : '#595959')};

  &.anticon[tabindex] {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }

  &:last-child {
    margin-left: 30px;
    margin-right: 10px;
  }
`;

export default NormalizedDocument;
