import { PlusOutlined } from '@ant-design/icons';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import {
  type CustomSelectionChangedEvent,
  LinkCell,
  type SetFilter,
  Table,
  type TableRef,
  type TextFilter,
} from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';
import { Button, Space } from 'antd';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { ActionsMenuWithButtons } from '@common/components/ActionsMenuWithButtons';
import { ComplianceStats } from '@common/components/ComplianceStats';
import RefreshDataBanner from '@common/components/RefreshDataBanner';
import { FilterButton } from '@common/components/Table';
import {
  BulkOperationStatus,
  type ContextRecord,
  type RequestRecord,
} from '@graphql/types/graphql';
import {
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';
import BaseComplianceProfileIcon from '@modules/compliance-profile/components/BaseComplianceProfileIcon';
import { useOperationsUpdates } from '@modules/organization/hooks/useOperationsUpdates';
import {
  type ActionType,
  REQUEST_RECORDS_ACTION_TYPES,
  REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES,
} from '@modules/request-records/constants';
import { CreateRequestsModal } from '@modules/request-records/containers/CreateRequestsModal';
import { DeleteRequestsModal } from '@modules/request-records/containers/DeleteRequestsModal/DeleteRequestsModal';
import {
  ContextRecordStatus,
  PrimaryRecordStatus,
  type RECORD_TYPES,
  type RecordTypes,
  RequestRecordStatus,
} from '@trustlayer/common';
import { getActionsMenuItems } from '../../utils/getActionsMenuItems';
import { ActivateRequestRecordsModal } from '../ActivateRequestRecordsModal';
import { ArchiveRequestRecordsModal } from '../ArchiveRequestRecordsModal';
import { getDeleteFilters } from './RequestsTable.utils';
import { ContextRecordCell } from './components/ContextRecordCell';
import { COLUMN_FIELDS, DYNAMIC_FIELDS_BY_RECORD_TYPE } from './constants';
import { FILTERABLE_COLUMN_FIELDS } from './constants';
import { useRequests } from './hooks';

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedRequestIds: string[];
  selectedRowsData: any[];
};

type PivotalRecordTypes = Exclude<RecordTypes, typeof RECORD_TYPES.Request>;

type TableFiltersModel = {
  [FILTERABLE_COLUMN_FIELDS.name]?: TextFilter;
  [FILTERABLE_COLUMN_FIELDS.status]?: SetFilter;
};

type RequestRecordsTableState = {
  filters: TableFiltersModel;
  pagination: {
    currentPage?: number;
    pageSize?: number;
  };
};

type SetFilterValue = {
  code: string;
  name: string;
};

const commonSetFilterParams = {
  keyCreator: ({ value }: { value: SetFilterValue }) => value.code,
  valueFormatter: ({ value }: { value: SetFilterValue }) => value.name,
};

const getDefaultTableState = (
  relatedRecordStatus: ContextRecordStatus | PrimaryRecordStatus,
) => ({
  filters: {
    [FILTERABLE_COLUMN_FIELDS.status]: {
      filterType: 'set' as const,
      values: [relatedRecordStatus],
    },
  },
  pagination: {
    currentPage: 0,
    pageSize: 12,
  },
});

const initState = (
  relatedRecordStatus: ContextRecordStatus | PrimaryRecordStatus,
): RequestRecordsTableState => {
  return getDefaultTableState(relatedRecordStatus);
};

export const RequestsTable = ({
  recordId,
  source,
  relatedRecordStatus = 'active',
}: {
  recordId: string;
  source: PivotalRecordTypes;
  relatedRecordStatus?: ContextRecordStatus | PrimaryRecordStatus;
}) => {
  const tableRef = useRef<TableRef>(null);
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();

  const [isCreateRequestsModalVisible, setIsCreateRequestsModalVisible] =
    useState(false);
  const [isRequestDeleteModalVisible, setIsRequestDeleteModalVisible] =
    useState(false);

  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);

  const recordType = DYNAMIC_FIELDS_BY_RECORD_TYPE[source];

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
    activeFilters: 0,
  });

  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedRequestIds: [],
    selectedRowsData: [],
  });

  const [shouldRefreshData, setShouldRefreshData] = useState(false);

  const [tableState, setTableState] = useSetState<RequestRecordsTableState>(
    initState(relatedRecordStatus),
  );

  const inputSearchValue =
    tableState.filters[FILTERABLE_COLUMN_FIELDS.name]?.filter || '';

  const { getRequestsList } = useRequests({
    recordId: recordId,
    recordType: source,
  });

  const [isBulkArchiveModalVisible, setIsBulkArchiveModalVisible] =
    useState(false);
  const [isBulkActivateModalVisible, setIsBulkActivateModalVisible] =
    useState(false);

  useOperationsUpdates({
    operationNames: Object.values(REQUEST_RECORDS_ASYNC_OPERATIONS_NAMES),
    operationStatuses: [BulkOperationStatus.Completed],
    onUpdate: () => {
      setShouldRefreshData(true);
    },
  });

  const refreshData = () => {
    setShouldRefreshData(false);
    tableRef?.current?.refreshTable();
  };

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Request Name',
        field: COLUMN_FIELDS.name,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
        },
        lockPosition: 'left' as const,
        pinned: 'left' as const,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        minWidth: 200,
        sortable: false,
        cellRenderer: ({ data }: { data: RequestRecord }) => (
          <LinkCell to={`${data._id}`}>{data.name}</LinkCell>
        ),
      },
      {
        headerName: 'Status',
        field: COLUMN_FIELDS.status,
        hide: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          ...commonSetFilterParams,
          values: [
            {
              code: RequestRecordStatus.Active,
              name: 'Active',
            },
            {
              code: RequestRecordStatus.Archived,
              name: 'Archived',
            },
          ],
        },
      },

      {
        //? Here we've decided to use the Party/Project name instead of Related for easely introduce the feature to the users
        //? we will change it again to Related in the future
        headerName: recordType.headerName,
        field: COLUMN_FIELDS.related,
        minWidth: 200,
        sortable: false,
        hide: !isContextsAddOnEnabled,
        valueGetter: ({ data }: { data: RequestRecord }) =>
          data[recordType.dataField],
        cellRenderer: ({ value }: { value: ContextRecord }) => (
          <ContextRecordCell name={value?.name} />
        ),
      },
      {
        headerName: 'Compliance',
        hide: !isComplianceRequirementsAddOnEnabled,
        field: COLUMN_FIELDS.compliance,
        minWidth: 200,
        sortable: false,
        valueGetter: ({ data }: { data: any }) => data?.complianceStats,
        cellRenderer: ({ value }: any) => (
          <ComplianceStats
            compliantCount={value.compliantSubjects}
            totalCount={value.totalSubjects}
          />
        ),
      },
      {
        headerName: 'Checklist',
        minWidth: 200,
        sortable: false,
        field: COLUMN_FIELDS.complianceProfile,
        hide: isComplianceRequirementsAddOnEnabled,
        valueGetter: ({ data }: { data: any }) => data?.complianceProfile,
        cellRenderer: ({ value }: { value: any }) => (
          <StyledComplianceProfileCell>
            {value?.baseComplianceProfile ? (
              <BaseComplianceProfileIcon />
            ) : null}
            {value?.name || '-'}
          </StyledComplianceProfileCell>
        ),
      },
    ];
  }, [
    recordType,
    isComplianceRequirementsAddOnEnabled,
    isContextsAddOnEnabled,
  ]);

  const handleSelectionChange = (params: CustomSelectionChangedEvent) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedRequestIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleSelectVisibleItems = () => {
    tableRef?.current?.selectVisibleRows();
  };

  const resetItemsTableSelection = () => {
    tableRef?.current?.resetSelection();
    setCounters({ selectedRows: 0 });
    setSelection({
      isSelectAllChecked: false,
      selectedRequestIds: [],
    });
  };

  const bulkActionsMenuItems = useMemo(() => getActionsMenuItems({}), []);

  const handleSelectAllItems = () => {
    tableRef?.current?.selectAllRows();
  };

  const handleOnFiltersChanged = (params: {
    activeFiltersCount: number;
    activeFilters: TableFiltersModel;
  }) => {
    setCounters({
      activeFilters: params.activeFiltersCount,
    });

    setTableState({
      filters: params.activeFilters,
    });
  };

  const handleOnPaginationChanged = (params: any) => {
    setTableState({
      pagination: params,
    });
  };

  const handleBulkActionClick = (code: ActionType) => {
    switch (code) {
      case REQUEST_RECORDS_ACTION_TYPES.archive:
        setIsBulkArchiveModalVisible(true);
        break;
      case REQUEST_RECORDS_ACTION_TYPES.makeActive:
        setIsBulkActivateModalVisible(true);
        break;
      case REQUEST_RECORDS_ACTION_TYPES.delete:
        setIsRequestDeleteModalVisible(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <DeleteRequestsModal
        count={counters.selectedRows}
        open={isRequestDeleteModalVisible}
        filters={getDeleteFilters({
          recordId,
          source,
          ids: selection.selectedRequestIds,
          textValue: inputSearchValue,
        })}
        onCompleted={() => {
          setIsRequestDeleteModalVisible(false);
          tableRef?.current?.refreshTable();
          resetItemsTableSelection();
        }}
        onScheduled={() => {
          setIsRequestDeleteModalVisible(false);
          resetItemsTableSelection();
        }}
        onCancel={() => {
          setIsRequestDeleteModalVisible(false);
        }}
      />
      <ArchiveRequestRecordsModal
        open={isBulkArchiveModalVisible}
        count={counters.selectedRows}
        filters={getDeleteFilters({
          recordId,
          source,
          ids: selection.selectedRequestIds,
          textValue: inputSearchValue,
        })}
        onCompleted={() => {
          setIsBulkArchiveModalVisible(false);
          tableRef?.current?.refreshTable();
          resetItemsTableSelection();
        }}
        onScheduled={() => {
          setIsBulkArchiveModalVisible(false);
          resetItemsTableSelection();
        }}
        onCancel={() => {
          setIsBulkArchiveModalVisible(false);
        }}
      />
      <ActivateRequestRecordsModal
        open={isBulkActivateModalVisible}
        count={counters.selectedRows}
        filters={getDeleteFilters({
          recordId,
          source,
          ids: selection.selectedRequestIds,
          textValue: inputSearchValue,
        })}
        onCompleted={() => {
          setIsBulkActivateModalVisible(false);
          tableRef?.current?.refreshTable();
          resetItemsTableSelection();
        }}
        onScheduled={() => {
          setIsBulkActivateModalVisible(false);
          resetItemsTableSelection();
        }}
        onCancel={() => {
          setIsBulkActivateModalVisible(false);
        }}
      />
      <CreateRequestsModal
        open={isCreateRequestsModalVisible}
        recordId={recordId}
        source={source}
        onCancel={() => {
          setIsCreateRequestsModalVisible(false);
        }}
        onCompleted={() => {
          setIsCreateRequestsModalVisible(false);
          tableRef?.current?.refreshTable();
        }}
        onScheduled={() => {
          setIsCreateRequestsModalVisible(false);
        }}
      />
      <StyledWrapper>
        <TableHeader>
          <TableHeader.BottomRightSection>
            <Button
              icon={<PlusOutlined />}
              disabled={
                relatedRecordStatus === ContextRecordStatus.Archived ||
                // @ts-expect-error - ContextRecordStatus and PrimaryRecordStatus are identical the check is not needed, but we keep it for readability
                relatedRecordStatus === PrimaryRecordStatus.Archived
              }
              onClick={() => {
                setIsCreateRequestsModalVisible(true);
              }}
            >
              Request
            </Button>
          </TableHeader.BottomRightSection>

          <TableHeader.BottomLeftSection>
            {areBulkActionsButtonsVisible ? (
              <Space>
                <SelectionDropdown
                  areAllSelected={selection.isSelectAllChecked}
                  selectedEntitiesCount={counters.selectedRows}
                  onSelectAll={handleSelectAllItems}
                  onSelectAllVisible={handleSelectVisibleItems}
                  onSelectNone={resetItemsTableSelection}
                  totalCount={counters.totalRows}
                  totalVisible={counters.visibleRows}
                />
                <ActionsMenuWithButtons
                  items={bulkActionsMenuItems}
                  onClick={handleBulkActionClick}
                  expandedItemsCount={2}
                />
              </Space>
            ) : (
              <>
                <StyledSearchInput
                  key={inputSearchValue}
                  defaultValue={inputSearchValue}
                  onSearch={(value) => {
                    tableRef.current?.setFilters({
                      [FILTERABLE_COLUMN_FIELDS.name]: {
                        filterType: 'text',
                        filter: value,
                        type: 'contains',
                      },
                    });
                  }}
                />
                <FilterButton
                  activeFiltersCount={counters.activeFilters}
                  onClick={() => {
                    tableRef?.current?.toggleFiltersPanel();
                  }}
                  size="small"
                />
              </>
            )}
          </TableHeader.BottomLeftSection>
        </TableHeader>
        <StyledTable>
          {shouldRefreshData && (
            <RefreshDataBanner onRefreshClick={refreshData} />
          )}
          <Table
            ref={tableRef}
            columnDefs={columns}
            getRowData={getRequestsList}
            onSelectionChanged={handleSelectionChange}
            onPaginationChanged={handleOnPaginationChanged}
            onFilterChanged={handleOnFiltersChanged}
            tableState={tableState}
          />
        </StyledTable>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 200px;
`;

const StyledTable = styled.section`
  flex: 1;
`;

const StyledComplianceProfileCell = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
