import { Button, Empty } from 'antd';
import styled from 'styled-components';

type Props = {
  isEmptyConversations: boolean;
  isEmptyContacts: boolean;
  onSendEmail?: () => void;
};

export const ConversationEmptyState = ({
  isEmptyConversations,
  isEmptyContacts,
  onSendEmail,
}: Props) => {
  let description: string | React.ReactNode = '';

  if (isEmptyContacts) {
    description = 'No contact information available';
  } else {
    description = isEmptyConversations ? (
      <StyledNoEmailsMessage>
        You haven’t sent any emails yet.{' '}
        <StyledButton onClick={onSendEmail} type="link">
          Send an email
        </StyledButton>{' '}
        to get started.
      </StyledNoEmailsMessage>
    ) : (
      'No conversation selected'
    );
  }

  return (
    <StyledWrapper>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={description}
        imageStyle={{
          width: '150px',
          height: '100px',
        }}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};

  && .ant-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  && svg {
    height: 100px;
    width: 150px;
  }
`;

const StyledNoEmailsMessage = styled.div`
  color: ${(props) => props.theme.colors.darkGrayText};
`;

const StyledButton = styled(Button)`
  padding: 0;
`;
