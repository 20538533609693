import { FILLABLE_FORMS_MODULE_ID } from '@modules/fillable-form/constants';
import { SURVEY_MODULE_ID } from '@modules/surveys/constants';
import * as R from 'ramda';

import { Operator, operators } from '../constants';

/**
 * Get exist attributes.
 */
const getExistAttribute = (
  templates,
  requirements,
  moduleId,
  subjectId,
  attributeId,
) => {
  const attributes = R.compose(
    R.uniq,
    R.map((definition) => ({
      attributeId: definition.attributeId,
      attributeLabel: definition.attributeLabel,
      attributeType: definition.attributeType,
    })),
    R.filter(
      (definition) =>
        requirements.every(
          (requirement) => requirement.attributeId !== definition.attributeId,
        ) && definition.attributeType !== 'date',
    ),
    R.filter(R.propEq('subjectId', subjectId)),
    R.filter(R.propEq('moduleId', moduleId)),
  )(templates);

  const addAttribute = R.find(
    R.propEq('attributeId', attributeId),
    requirements,
  );

  return attributeId && addAttribute
    ? R.append(
        {
          attributeId: addAttribute.attributeId,
          attributeLabel: addAttribute.attributeLabel,
          //? Here we need to force the type to the one defined inside rules because as of now only
          //? the rules with type number can be "Must be Present" and "Must be Greater or Equal to"
          attributeType: templates.find(
            (rule) => rule.attributeId === attributeId,
          )?.attributeType,
        },
        attributes,
      )
    : attributes;
};

export const generateDefaultRequirements = (templates) => {
  const requirement1 = R.find(
    R.propEq(
      'attributeId',
      'evidenceOfInsuranceCommercialGeneralLiabilityEachOccurrence',
    ),
    templates,
  );

  const formModule = R.find(
    R.propEq('moduleId', FILLABLE_FORMS_MODULE_ID),
    templates,
  );

  const surveyModule = R.find(
    R.propEq('moduleId', SURVEY_MODULE_ID),
    templates,
  );

  // formModule, surveyModule may be undefined because the feature flag is not enabled in the BE
  const req = [
    {
      ...requirement1,
      operator: Operator.GREATER_OR_EQUAL,
      targetValue: '1000000',
    },
    formModule,
    surveyModule,
  ].filter(Boolean);

  return req;
};

/**
 * generate cascader Options.
 */
export const generateCascaderOptions = (
  templates,
  rules,
  moduleId,
  subjectId,
  attributeId,
) => {
  const options = getExistAttribute(
    templates,
    rules,
    moduleId,
    subjectId,
    attributeId,
  ).map((attr) => ({
    value: attr.attributeId,
    label: attr.attributeLabel,
    children: (operators[attr.attributeType] || []).map((operator) => ({
      value: operator,
      label: operator,
    })),
  }));

  return options;
};

/**
 * Display labels for cascader.
 */
export const displayRule = (labels) =>
  labels.map((label, i) => `${i ? ' »' : ''} ${label}`).join(' ');
