import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';
import { useAppSelector } from '@store/hooks';
import { Alert } from 'antd';
import { Link } from 'react-router';
import styled from 'styled-components';

export const ChecklistProfileAlert = ({
  requestName,
  profileName,
}: {
  requestName?: string;
  profileName?: string;
}) => {
  const organizationNamespace = useAppSelector(getOrganizationNamespaceUrl);

  return (
    <StyledAlert
      message={
        <>
          {requestName ? (
            <div>
              Editing items will create a custom checklist, assigned only to{' '}
              {requestName}. If you want to update the checklist “{profileName}”
              and apply changes to all associated Requests, go to the{' '}
              <StyledAlertLink
                to={`${organizationNamespace}/settings/checklists`}
              >
                Checklist settings
              </StyledAlertLink>{' '}
              page.
            </div>
          ) : (
            <div>
              Editing items might affect all associated Requests with this
              checklist.
            </div>
          )}
        </>
      }
      type="warning"
      showIcon
    />
  );
};

const StyledAlert = styled(Alert)`
  position: relative;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  align-items: baseline;
  font-size: 12px;
  width: 100%;
`;

const StyledAlertLink = styled(Link)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
  text-decoration: underline;
`;
