import type {
  CreateDocumentChecklistInput,
  UpdateComplianceProfileInput,
} from '@graphql/types/graphql';
import type { DocumentChecklist } from '@modules/document-checklist/types';
import type {
  DocumentChecklistFormData,
  DocumentChecklistFormItem,
} from '../DocumentCheckListForm/types';

export const getFormInitialValues = (
  documentChecklists: DocumentChecklist[],
  name: string = '',
) => {
  const documentTypeChecklistCache: Record<string, DocumentChecklist[]> = {};

  const documentTypesChecklists: DocumentChecklistFormItem[] = [];

  documentChecklists.forEach((documentChecklist) => {
    if (!documentChecklist?.type) return;

    const checklistItem = documentChecklist as DocumentChecklist;
    const type = documentChecklist.type;

    if (documentTypeChecklistCache[type._id]) {
      documentTypeChecklistCache[type._id].push(checklistItem);
      return;
    }

    const checklists = [checklistItem];
    documentTypeChecklistCache[type._id] = checklists;
    documentTypesChecklists.push({ ...type, checklists });
  });

  return { documentTypesChecklists, name };
};

export const getFormValuesAsUpdatePayload = (
  values: DocumentChecklistFormData,
  complianceProfileId?: string,
): UpdateComplianceProfileInput => ({
  _id: complianceProfileId,
  name: values.name,
  rules: [],
  documentChecklists: values.documentTypesChecklists.flatMap((documentType) =>
    documentType.checklists.map<CreateDocumentChecklistInput>((checklist) => ({
      type: documentType._id,
      attributeId: checklist.attributeId,
      isActive: checklist.isActive,
      attributeLabel: checklist.attributeLabel,
      ...(complianceProfileId && { complianceProfile: complianceProfileId }),
    })),
  ),
});
