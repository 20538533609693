import { UserOutlined } from '@ant-design/icons';
import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import type { Contact } from '@graphql/types/graphql';
import { FullWidthSpace } from '@trustlayer/ui';
import { Input } from 'antd';
import type { DefaultOptionType } from 'antd/es/select';
import { useContactsEmailsForSearch } from '../hooks/useContactsEmailsForSearch';

const formatContactName = (
  contactPersonName: string | null | undefined,
  email: string | null | undefined,
) => {
  return contactPersonName ? `${contactPersonName} - ${email || ''}` : email;
};

export function ContactsInput({
  onChange = () => {},
  onSelect,
  onClear,
  placeholder = 'Enter contact email...',
  email,
}: Readonly<{
  onSelect: (contact: Contact) => void;
  onClear: () => void;
  onChange?: (value: string) => void;
  placeholder?: string;
  email?: string;
}>) {
  const {
    contactsList,
    contactsLoading,
    contactsLoadingMore,
    fetchMoreContactsForSearch,
    contactsListTotalCount,
  } = useContactsEmailsForSearch(email);

  const handleOnSelect = (_: string, option: DefaultOptionType) => {
    const contact = contactsList?.find((contact) => contact._id === option.key);

    if (contact) {
      onSelect?.(contact);
    }
  };

  return (
    <InfiniteScrollSelect
      as="AutoComplete"
      isDataLoading={contactsLoading || contactsLoadingMore}
      onSelect={handleOnSelect}
      notFoundContent={null}
      onClear={onClear}
      suffixIcon={null}
      hasMore={contactsListTotalCount > contactsList?.length}
      loadMore={fetchMoreContactsForSearch}
      value={email}
      options={contactsList?.map((option) => ({
        key: option._id,
        value: option._id,
        'data-cy': 'select_option',
        label: (
          <FullWidthSpace align="center" size="small">
            <UserOutlined />
            <span>
              {formatContactName(option.contactPersonName, option.email)}
            </span>
          </FullWidthSpace>
        ),
        contactData: option,
      }))}
    >
      <Input.Search
        allowClear
        loading={contactsLoading}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={placeholder}
      />
    </InfiniteScrollSelect>
  );
}
