import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import styled from 'styled-components';

import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';

const DocumentLinkName = ({ children, document }) => {
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const documentPartyId = R.pathOr('every', ['party', '_id'], document);

  return (
    <DocumentLinkName.Link
      to={`${organizationNamespace}/parties/${documentPartyId}/documents-review/${document._id}`}
    >
      {children}
    </DocumentLinkName.Link>
  );
};

DocumentLinkName.Link = styled(Link)`
  text-decoration: none;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
`;

export default DocumentLinkName;
