import {
  CalendarOutlined,
  CaretDownOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Modal, Tooltip } from 'antd';
import qs from 'query-string';
import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

// Modules
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';
import { normalizeTagsFilter } from '@modules/party/components/TagsFilter';
import { getCurrentProjectId } from '@modules/system-settings/selectors';

// Modals Components
import { deleteReport } from '../actions';
import { DropdownMenuKey } from '../constants';
import { getReportsByType } from '../selectors';

import { parseAutomationsFilterFromReportToSearchParams } from '@modules/party/components/AutomationsFilter';
import SaveReportModal from './SaveReportModal.legacy';

const ReportsDropdown = ({ reportsType }) => {
  const [isVisibleSaveReportModal, setIsVisibleSaveReportModal] =
    useState(false);

  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { partyId } = useParams();

  const memberRole = useMemberRole();
  const hasMemberViewerRole = memberRole === ROLES.VIEWER;

  const dispatch = useDispatch();

  const reports = useSelector((state) => getReportsByType(state, reportsType));
  const currentProjectId = useSelector(getCurrentProjectId);

  const searchParams = qs.parse(search);
  const currentReportId = R.prop('report', searchParams);
  const getReportById = (id) => R.find(R.propEq('_id', id), reports);
  const currentReport = getReportById(currentReportId);

  const handleOnCloseSaveReportModal = () => {
    setIsVisibleSaveReportModal(false);
  };

  const handleClick = ({ key, keyPath }) => {
    const lastKeyPath = keyPath.slice(-1)[0];

    switch (lastKeyPath) {
      case DropdownMenuKey.Edit: {
        setIsVisibleSaveReportModal(true);
        break;
      }
      case DropdownMenuKey.Delete: {
        Modal.confirm({
          title: 'Are you sure you want to delete this report?',
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: () => {
            dispatch(deleteReport(currentReportId));
            navigate(
              {
                pathname,
                search: `${qs.stringify(R.omit(['report'], searchParams))}`,
              },
              { replace: true },
            );
          },
        });
        break;
      }
      default: {
        const params = R.compose(
          R.when(() => searchParams.size, R.merge({ size: searchParams.size })),
          R.when(
            () => currentProjectId && !partyId,
            R.merge({ project: currentProjectId }),
          ),
          R.merge({ report: key }),
          R.propOr({}, 'filters'),
        )(getReportById(key));

        const filters = {
          ...params,
          ...normalizeTagsFilter(params),
          ...parseAutomationsFilterFromReportToSearchParams(params),
        };

        // wait dropdown closing animation
        setTimeout(() => {
          navigate(
            {
              pathname,
              search: qs.stringify(filters),
            },
            {
              replace: true,
            },
          );
        }, 300);
      }
    }
  };

  if (!reports.length && !currentReportId) {
    return null;
  }

  return (
    <>
      <SaveReportModal
        report={currentReport}
        reportsType={reportsType}
        isVisible={isVisibleSaveReportModal}
        onClose={handleOnCloseSaveReportModal}
      />
      <Dropdown
        dropdownRender={() => (
          <ReportsDropdown.Menu
            data-cy="reportDropdownMenu"
            onClick={handleClick}
          >
            {!hasMemberViewerRole && currentReportId && (
              <Menu.ItemGroup title="this report">
                <Menu.Item key={DropdownMenuKey.Edit}>
                  {DropdownMenuKey.Edit}
                </Menu.Item>
                <Menu.Item
                  key={DropdownMenuKey.Delete}
                  disabled={currentReport?.readonly}
                >
                  {DropdownMenuKey.Delete}
                </Menu.Item>
              </Menu.ItemGroup>
            )}
            {Boolean(reports.length) && (
              <Menu.ItemGroup title="saved reports">
                {reports.map((report) => (
                  <Menu.Item key={report._id}>
                    <ReportsDropdown.ItemWrapper>
                      <Tooltip
                        title={
                          report.isPrivate
                            ? 'Visible only to you'
                            : 'Visible to your team'
                        }
                      >
                        <ReportsDropdown.ReportIcon>
                          {report.isPrivate ? (
                            <UserOutlined />
                          ) : (
                            <TeamOutlined />
                          )}
                        </ReportsDropdown.ReportIcon>
                      </Tooltip>
                      {report.name}
                      {Boolean(report.schedules.length) &&
                        !currentProjectId && (
                          <ReportsDropdown.Schedules>
                            <CalendarOutlined /> {report.schedules.length}
                          </ReportsDropdown.Schedules>
                        )}
                    </ReportsDropdown.ItemWrapper>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            )}
          </ReportsDropdown.Menu>
        )}
        trigger={['click']}
      >
        <ReportsDropdown.CaretDownOutlined data-cy="reportDropdown" />
      </Dropdown>
    </>
  );
};

ReportsDropdown.CaretDownOutlined = styled(CaretDownOutlined)`
  padding: 7px 10px;
  font-size: 18px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

ReportsDropdown.Menu = styled(Menu)`
  .ant-dropdown-menu-item-group-title {
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

ReportsDropdown.ReportIcon = styled.span`
  margin-right: 5px;

  span {
    font-size: 14px !important;
  }
`;

ReportsDropdown.ItemWrapper = styled.div`
  display: flex;
`;

ReportsDropdown.Schedules = styled.div`
  margin-left: 10px;
  width: 100%;
  text-align: right;
  color: ${({ theme }) => theme.colors.lightTextGray};
`;

export default ReportsDropdown;
