import { Button, Input } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

type ChecklistItemFormProps = {
  initialValue?: string;
  submitButtonLabel: string;
  onSubmit: (value: string) => void;
  onClose: () => void;
};

const ChecklistItemForm = ({
  initialValue,
  submitButtonLabel,
  onSubmit,
  onClose,
}: ChecklistItemFormProps) => {
  const [value, setValue] = useState(initialValue || '');

  const handleSubmit = () => {
    if (!value?.trim()) return;
    onSubmit(value);
    setValue('');
  };

  return (
    <StyledWrapper>
      <Input.TextArea
        data-cy="addChecklistItemInput"
        autoSize={{ minRows: 1 }}
        placeholder="Enter your checklist item..."
        value={value}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
      <Button
        data-cy="addChecklistItemButton"
        disabled={!value}
        type="primary"
        onClick={handleSubmit}
      >
        {submitButtonLabel}
      </Button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;
  margin: 5px 0;
  display: flex;
`;

const StyledCancelButton = styled(Button)`
  margin: 0 10px !important;
`;

export default ChecklistItemForm;
