import { Button } from 'antd';
import { useCallback, useRef } from 'react';
import styled from 'styled-components';

type TabButtonsProps = {
  tabs: Array<{ key: string; name: string }>;
  activeTab: string;
  onClick?: (tabKey: string) => void;
  indicator?: boolean;
};

export const TabButtons = ({
  tabs,
  activeTab,
  onClick,
  indicator,
}: TabButtonsProps) => {
  const indicatorRef = useRef<HTMLDivElement>(null);

  const activeRef = useCallback((el: HTMLDivElement) => {
    if (!el || !indicatorRef.current) return;
    const { width, x } = el.getBoundingClientRect();
    const parentX = el.parentElement?.getBoundingClientRect().x;
    indicatorRef.current.style.width = `${width}px`;
    indicatorRef.current.style.left = `${x - parentX!}px`;
    setTimeout(() => {
      indicatorRef.current?.classList.add('transition');
    }, 0);
  }, []);

  return (
    <>
      {indicator && <StyledIndicator ref={indicatorRef} />}
      {tabs.map((tab) => (
        <StyledTabButton
          key={tab.key}
          type="text"
          onClick={() => onClick?.(tab.key)}
          $active={tab.key === activeTab}
          ref={tab.key === activeTab ? activeRef : null}
        >
          {tab.name}
        </StyledTabButton>
      ))}
    </>
  );
};

const StyledTabButton = styled(Button)<{ $active: boolean }>`
  box-shadow: none !important;

  ${({ $active }) =>
    $active &&
    `
      background: #e6f4ff;
      color:#1677ff;
      pointer-events: none;
    `}
`;

const StyledIndicator = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color:#1677ff;

  &.transition {
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  }
`;
