import qs from 'query-string';
import * as R from 'ramda';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

const useSearchParams = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = useMemo(() => qs.parse(search), [search]);

  const getNewParams = useCallback(
    (params: Record<string, unknown>): Record<string, unknown> =>
      // @ts-expect-error
      R.compose<Record<string, unknown>, Record<string, unknown>>(
        R.reject((param) => R.isNil(param) || param === ''),
        // @ts-expect-error
        R.merge(R.__, params),
        // @ts-expect-error
      )(searchParams),
    [searchParams],
  );

  const replaceParams = useCallback(
    (params: Record<string, unknown>) => {
      navigate(`?${qs.stringify(getNewParams(params))}`, { replace: true });
    },
    [navigate, getNewParams],
  );

  const pushParams = useCallback(
    (params: Record<string, unknown>) => {
      navigate(`?${qs.stringify(getNewParams(params))}`);
    },
    [navigate, getNewParams],
  );

  const getFirstValue = (param: unknown) =>
    Array.isArray(param) ? param[0] : param;

  return {
    searchParams,
    replaceParams,
    pushParams,
    getFirstValue,
    search,
  };
};

export default useSearchParams;
