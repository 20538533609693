import { AppstoreOutlined } from '@ant-design/icons';
import { PageTitle } from '@common/components/PageTitleWrapper';
import SearchInput from '@common/components/SearchInput';
import {
  Table,
  type TablePaginationState,
  type TableSortingState,
  type TextFilter,
  useTableControl,
} from '@common/components/Table';

import OrganizationAvatar from '@common/components/OrganizationAvatar';
import { TableHeader } from '@common/components/TableHeader';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useWorkspacesQuery } from './hooks/useWorkspacesQuery';

import { ComplianceStats } from '@common/components/ComplianceStats';
import type { Workspace } from '@graphql/types/graphql';
import { humanizeDate } from '@trustlayer/common';
import { COLUMN_FIELDS } from './constants';

export type TableFiltersModel = {
  [COLUMN_FIELDS.name]?: TextFilter;
};

type WorkspaceTableState = {
  filters: TableFiltersModel;
  sorting: TableSortingState;
  pagination: TablePaginationState;
};

export const WorkspacesPage = () => {
  const tableControl = useTableControl<WorkspaceTableState>();
  const { setFilters, state: tableState } = tableControl;
  const { getWorkspaces } = useWorkspacesQuery();

  const inputSearchValue =
    tableState.filters?.[COLUMN_FIELDS.name]?.filter || '';

  /**
   * @note at the moment PrivateRouteGuardLayout has a flag "shouldUpdateUrl" which checks a mismatch between the URL slugifyName and the organization data in the Redux store
   * assign the priority to the organization data in the Redux store. To avoid unexpected behaviors the navigation should reset the Redux store data,
   * this is the reason I preferred to use "window.location.replace" instead of "Link" or "navigate" from react-router.
   *
   * We should move to react-router in the future to improve performance but first we need to tackle the "shouldUpdateUrl" flag in a different way.
   */
  const onWorkspaceClicked = useCallback((slugifyName: string) => {
    window.location.replace(`/${slugifyName}/dashboard`);
  }, []);

  const columns = useMemo(
    () => [
      {
        headerName: 'Name',
        field: COLUMN_FIELDS.name,
        width: 400,
        sortable: true,
        pinned: 'left' as const,
        lockPosition: 'left' as const,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
        },
        valueGetter: ({ data }: { data: Workspace }) => data,
        cellRenderer: ({ value: workspace }: { value: Workspace }) => (
          <StyledNameWrapper>
            <OrganizationAvatar
              size={24}
              name={workspace.name}
              iconUrl={workspace.icon}
            />
            <StyledLink
              onClick={() => onWorkspaceClicked(workspace.slugifyName)}
            >
              {workspace.name}
            </StyledLink>
          </StyledNameWrapper>
        ),
      },
      {
        headerName: 'Party Compliance',
        field: COLUMN_FIELDS.primaryRecordsCompliance,
        valueGetter: ({ data }: { data: Workspace }) =>
          data.stats.primaryRecords,
        cellRenderer: ({
          value,
        }: {
          value: { compliantCount: number; totalCount: number };
        }) => (
          <ComplianceStats
            compliantCount={value.compliantCount}
            totalCount={value.totalCount}
          />
        ),
      },
      {
        headerName: 'Project Compliance',
        field: COLUMN_FIELDS.contextRecordsCompliance,
        valueGetter: ({ data }: { data: Workspace }) =>
          data.stats.contextRecords,
        cellRenderer: ({
          value,
        }: {
          value: { compliantCount: number; totalCount: number };
        }) => (
          <ComplianceStats
            compliantCount={value.compliantCount}
            totalCount={value.totalCount}
          />
        ),
      },
      {
        headerName: 'Documents to review',
        field: COLUMN_FIELDS.documentsToReviewCount,
        valueGetter: ({ data }: { data: Workspace }) => data.stats.documents,
        cellRenderer: ({
          value,
        }: {
          value: { unreviewedCount: number };
        }) => <span>{value.unreviewedCount || '0'}</span>,
      },
      {
        headerName: 'Created',
        field: COLUMN_FIELDS.createdAt,
        valueGetter: ({ data }: { data: Workspace }) => data.createdAt,
        cellRenderer: ({ value }: { value: string | null | undefined }) => (
          <span>{value ? humanizeDate(value) : '—'}</span>
        ),
      },
    ],
    [onWorkspaceClicked],
  );

  return (
    <StyledWrapper>
      <TableHeader>
        <TableHeader.TopLeftSection>
          <PageTitle title="Workspaces" icon={<AppstoreOutlined />} />
        </TableHeader.TopLeftSection>
        <TableHeader.BottomLeftSection>
          <StyledSearchInput
            key={inputSearchValue}
            defaultValue={inputSearchValue}
            onSearch={(value) => {
              setFilters({
                name: {
                  filterType: 'text',
                  filter: value,
                  type: 'contains',
                },
              });
            }}
          />
        </TableHeader.BottomLeftSection>
      </TableHeader>
      <StyledTableWrapper>
        <StyledTable
          isSideBarDisabled
          control={tableControl}
          columnDefs={columns}
          getRowData={getWorkspaces}
        />
      </StyledTableWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledTableWrapper = styled.div`
  flex: 1;
  padding: 16px;
`;

const StyledTable = styled(Table)`
  border-left: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-right: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 8px;
  overflow: hidden;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 200px;
`;

const StyledNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledLink = styled.span`
  cursor: pointer;
  
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
