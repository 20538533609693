import styled from 'styled-components';

// TODO: Remove this component once we update to antd >= 5.10

interface FlexProps {
  justify?:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  align?: 'start' | 'end' | 'center' | 'baseline' | 'stretch';
  size?: 'xsmall' | 'small' | 'middle' | 'large';
  direction?: 'vertical' | 'horizontal';
  wrap?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const sizeMap = {
  xsmall: '4px',
  small: '8px',
  middle: '16px',
  large: '24px',
};

const StyledFlex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction === 'horizontal' ? 'row' : 'column')};
  justify-content: ${(props) => props.justify || 'start'};
  align-items: ${(props) => props.align || 'start'};
  gap: ${(props) => (props.size ? sizeMap[props.size] : '0px')};
  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
`;

function Flex({
  justify,
  align,
  size,
  direction,
  wrap,
  className,
  children,
}: FlexProps) {
  return (
    <StyledFlex
      justify={justify}
      align={align}
      size={size}
      direction={direction}
      wrap={wrap}
      className={className}
    >
      {children}
    </StyledFlex>
  );
}

export default Flex;
