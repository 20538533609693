import type {
  ComplianceProfile,
  PartyDataEntity,
} from '@graphql/types/graphql';

import ProjectPartiesTabView from '../components/ProjectPartiesTabView';

interface ProjectPartyTabRowContainerProps {
  partyProfileAssociation: Record<string, string | null>;
  party: Pick<
    PartyDataEntity,
    | '_id'
    | 'name'
    | 'type'
    | 'isActive'
    | 'isTrackingCompliance'
    | 'partyComplianceProfile'
  >;
  deleteParty: (id: string) => void;
  onChangeProfileAssociation: (mapping: Record<string, string | null>) => void;
  complianceProfiles: Pick<ComplianceProfile, '_id' | 'name'>[];
}

const ProjectPartyTabRowContainer = ({
  partyProfileAssociation,
  party,
  deleteParty,
  onChangeProfileAssociation,
  complianceProfiles,
}: ProjectPartyTabRowContainerProps) => {
  const partyId = party?._id;
  const selectedProfile =
    complianceProfiles.find(
      (profile) => profile._id === partyProfileAssociation[partyId],
    )?._id ?? partyProfileAssociation[partyId];

  const onAssignProfile = (id: string | null) => {
    id
      ? onChangeProfileAssociation({ [party._id]: id })
      : onChangeProfileAssociation({ [party._id]: null });
  };

  const onDeleteParty = (id: string) => {
    onChangeProfileAssociation({ [party._id]: null });
    deleteParty(id);
  };

  return (
    <ProjectPartiesTabView
      party={party}
      complianceProfiles={complianceProfiles}
      selectedProfile={selectedProfile}
      onAssignProfile={onAssignProfile}
      onDeleteParty={onDeleteParty}
    />
  );
};

export default ProjectPartyTabRowContainer;
