import { CaretDownFilled } from '@ant-design/icons';
import CheckIcon from '@common/icons/CheckIcon';
import { Button, Dropdown, Menu, Modal, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import type { DocumentChecklist } from '../types';
import ChecklistItemForm from './ChecklistItemForm';

type DocumentChecklistItemProps = {
  value?: DocumentChecklist;
  onChange?(newValue: DocumentChecklist): void;
};

const DocumentChecklistItem = ({
  value: item,
  onChange,
}: DocumentChecklistItemProps) => {
  const [isShowForm, setIsShowForm] = useState(false);

  const handleUpdate = (value: string) => {
    if (!item || !onChange) return;

    onChange({
      ...item,
      attributeLabel: value,
    });
    setIsShowForm(false);
  };

  const handleRemove = () => {
    if (!item || !onChange) return;

    Modal.confirm({
      title: 'Are you sure you want to delete this checklist item?',
      okText: 'Delete item',
      okType: 'danger',
      onOk: () => {
        //? Remove attributeId and attributeLabel from the item to delete it
        //? the BE handle the remove of the item by checking if the attributeId is empty
        onChange({
          ...item,
          attributeLabel: '',
          attributeId: '',
        });
      },
      onCancel: () => {},
      zIndex: 1200,
    });
  };

  //? If the item doesn't have an attributeId, it means it's was deleted by the user and we don't render it
  if (!item?.attributeId) return;

  if (isShowForm)
    return (
      <ChecklistItemForm
        initialValue={item.attributeLabel!}
        submitButtonLabel="Update"
        onSubmit={handleUpdate}
        onClose={() => {
          setIsShowForm(false);
        }}
      />
    );

  return (
    <StyledAttribute data-cy="documentChecklistItem">
      <StyledIconWrapper>
        <CheckIcon muted />
      </StyledIconWrapper>
      <Typography.Paragraph
        ellipsis={{
          rows: 2,
          expandable: item.attributeLabel?.length > 20,
        }}
      >
        {item.attributeLabel}
      </Typography.Paragraph>
      <StyledDropdownContainer data-cy="checklistItemDropdown">
        <Dropdown
          trigger={['click']}
          dropdownRender={() => (
            <Menu
              items={
                [
                  {
                    key: 'editDocumentChecklistItemItem',
                    'data-cy': 'editDocumentChecklistItemItem',
                    label: 'Edit item',
                    onClick: () => {
                      setIsShowForm(true);
                    },
                  },
                  {
                    key: 'deleteDocumentChecklistItemItem',
                    'data-cy': 'deleteDocumentChecklistItemItem',
                    label: 'Delete item',
                    onClick: handleRemove,
                  },
                ] as any
              }
            />
          )}
        >
          <Button shape="circle" type="link">
            <CaretDownFilled />
          </Button>
        </Dropdown>
      </StyledDropdownContainer>
    </StyledAttribute>
  );
};

const StyledAttribute = styled.div`
  position: relative;
  margin: 5px 0;
  display: flex;
  align-items: baseline;

  .ant-typography {
    margin-bottom: 0 !important;
  }
`;

const StyledIconWrapper = styled.div`
  margin-right: 14px;
  height: 100%;
`;

const StyledDropdownContainer = styled.div`
  margin-left: 20px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  button {
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

export default DocumentChecklistItem;
