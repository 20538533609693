import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { DocumentChecklistModule_DocumentChecklistQuery } from '@graphql/types/graphql';
import type { DocumentChecklist } from '@modules/document-checklist/types';

export const FETCH_COMPLIANCE_PROFILES_QUERY = graphql(`
  query DocumentChecklistModule_DocumentChecklist($id: ObjectId!) {
    getComplianceProfile(id: $id) {
      _id
      name
      baseComplianceProfile
      documentChecklists {
        _id
        attributeId
        attributeLabel
        isActive
        type {
          _id
          name
        }
      }
    }
  }
`);

export const useDocumentChecklistsQuery = ({
  checklistId,
  onCompleted,
}: {
  checklistId?: string;
  onCompleted?: (
    data: DocumentChecklistModule_DocumentChecklistQuery['getComplianceProfile'],
  ) => void;
}) => {
  const { data, loading } = useQuery(FETCH_COMPLIANCE_PROFILES_QUERY, {
    variables: { id: checklistId },
    skip: !checklistId,
    includeTypename: true,
    onCompleted: (data) => {
      onCompleted?.(data.getComplianceProfile);
    },
  });

  const complianceProfile = data?.getComplianceProfile;

  return {
    complianceProfile,
    documentChecklists: (complianceProfile?.documentChecklists ||
      []) as DocumentChecklist[],
    isComplianceProfileLoading: loading,
  };
};
