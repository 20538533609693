import { Alert, Button, Col, Modal, Row, message } from 'antd';
import moment from 'moment';
import pluralize from 'pluralize';
import qs from 'query-string';
import * as R from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import useFetchListPageData from '@common/hooks/useFetchListPageData';
import useFilters from '@common/hooks/useFilters';
import useListPagination from '@common/hooks/useListPagination';
import useOrganizationSlugify from '@common/hooks/useOrganizationSlugify';
import {
  buildCustomFieldsOps,
  getCustomFieldKeys,
} from '@modules/custom-field/utils/custom-field-helpers';
import AddToProjectModal from '@modules/party/modals/AddToProjectModal';
import { getParty } from '@modules/party/selectors';
import { setProjectAsCurrent as setProjectAsCurrentAction } from '@modules/system-settings/actions';
import { ListView } from '@modules/system-settings/constants';
import usePreferredSearchParams from '@modules/system-settings/hooks/usePreferredSearchParams';
import { getCurrentProjectId } from '@modules/system-settings/selectors';

import {
  bulkDeleteProjects,
  bulkUpdateProjectsActiveStatus,
  deleteProjects as deleteProjectsAction,
  fetchPartyProjects,
  fetchProjects,
  removePartyFromProjects as removePartyFromProjectsAction,
  updateProjectsActiveStatus as updateProjectsActiveStatusAction,
} from '../actions';
import ProjectsCardsView from '../components/ProjectsCardsView';
import ProjectsTableView from '../components/ProjectsTableView';
import { ProjectFilterFields } from '../constants';
import ProjectFilterTagsContainer from '../containers/ProjectFilterTagsContainer';
import ProjectsFilterContainer from '../containers/ProjectsFilterContainer';
import ProjectsHeaderContainer from '../containers/ProjectsHeaderContainer';
import ProjectDuplicateModal from '../modals/ProjectDuplicateModal';
import SaveProjectModal from '../modals/SaveProjectModal';
import { getProjects, getTotalProjectsCount } from '../selectors';

const containerConfig = {
  xs: { span: 24 },
};

const ProjectsPage = ({ embeddedInPartyProfile = false }) => {
  const { partyId } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [loading, setLoading] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedProjectsCount, setSelectedProjectsCount] = useState(0);
  const [areAllProjectsSelected, setAreAllProjectsSelected] = useState(false);
  const [isVisibleProjectModal, setIsVisibleProjectModal] = useState(false);
  const [visibleAddToProjectModal, setVisibleAddToProjectModal] =
    useState(false);
  const [isVisibleDuplicateModal, setIsVisibleDuplicateModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');

  const party = useSelector((state) => getParty(state, partyId));
  const isTrackingCompliance = R.prop('isTrackingCompliance', party);
  const projects = useSelector(getProjects);
  const projectsVisibleCount = projects.length;
  const currentProjectId = useSelector(getCurrentProjectId);

  const dispatch = useDispatch();

  const searchParams = useMemo(() => qs.parse(search), [search]);

  const {
    sortField,
    sortDirection,
    view: projectsView,
    size: pageSize,
  } = usePreferredSearchParams();

  const customFieldKeys = getCustomFieldKeys(searchParams);

  const filtersCount = R.compose(
    R.length,
    R.keys,
    R.pick([...R.values(ProjectFilterFields), ...customFieldKeys]),
  )(searchParams);
  const totalCount = useSelector(getTotalProjectsCount);

  const projectsToDisplay =
    !R.isNil(partyId) && !R.isNil(projects)
      ? R.filter(
          (project) =>
            R.compose(
              R.includes(partyId),
              R.pluck('_id'),
              R.propOr([], 'parties'),
            )(project),
          projects,
        )
      : projects;

  useOrganizationSlugify();

  /**
   * Custom hook to load projects data.
   * Using previous data to avoid extra request
   * when changing on of the filter parameter.
   */
  const { shouldRefreshData, refreshData } = useFetchListPageData(
    searchParams,
    () => buildFetchParams(searchParams),
    (...args) => fetchProjectsData(...args),
    'projects',
  );

  /**
   * useEffect to set default query string params
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    navigate(
      `${pathname}?${qs.stringify(
        R.compose(R.filter((param) => Boolean(param)))(searchParams),
      )}`,
      { replace: true },
    );
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    resetSelections();
  }, [search, sortField, sortDirection]);

  const fetchProjectsData = async (fetchParams) => {
    setLoading(true);

    if (R.isNil(partyId) || !isTrackingCompliance) {
      await dispatch(fetchProjects(fetchParams));
    } else {
      await dispatch(fetchPartyProjects(fetchParams));
    }

    setLoading(false);
  };

  const { updateFilters, resetFilters, updateNameFilter, updateSort } =
    useFilters([]);

  const onResetProjectFilters = () => {
    resetFilters();
  };

  /**
   * Prepares possible filter data to load.
   */
  const buildFetchParams = (searchParams) => {
    const filters = {
      skip: (R.propOr(1, 'page', searchParams) - 1) * pageSize,
      limit: pageSize,
    };

    const sort = {
      field: sortField,
      order: sortDirection,
    };

    filters.isActive = searchParams.status !== 'inactive';

    if (partyId) {
      filters.party = partyId;
    }

    // Filtering by start date.
    if (R.has(ProjectFilterFields.StartDate, searchParams)) {
      const [startDate, endDate] = R.compose(
        R.split('~'),
        R.propOr('', ProjectFilterFields.StartDate),
      )(searchParams);

      filters.startDate = endDate
        ? [parseInt(startDate), parseInt(endDate)]
        : handleSingleDate(startDate);
    }

    // Filtering by end date.
    if (R.has(ProjectFilterFields.EndDate, searchParams)) {
      const [startDate, endDate] = R.compose(
        R.split('~'),
        R.propOr('', ProjectFilterFields.EndDate),
      )(searchParams);

      filters.endDate = endDate
        ? [parseInt(startDate), parseInt(endDate)]
        : handleSingleDate(startDate);
    }

    // Filtering by name.
    if (R.has(ProjectFilterFields.Name, searchParams)) {
      filters.name = R.prop(ProjectFilterFields.Name, searchParams);
    }

    // Filtering by compliance status.
    if (R.has(ProjectFilterFields.ComplianceStatus, searchParams)) {
      const status = R.propOr(
        [],
        ProjectFilterFields.ComplianceStatus,
        searchParams,
      );

      if (typeof status === 'string') {
        filters.status = status;
      }
    }

    if (customFieldKeys.length > 0) {
      const customFieldsFilter = R.compose(
        (keys) => buildCustomFieldsOps(keys, searchParams),
        R.keys,
        R.pick(customFieldKeys),
      )(searchParams);

      filters.customFields = customFieldsFilter;
    }

    return { filters, sort };
  };

  const handleSingleDate = (date) => {
    const momentValue = moment(parseInt(date));
    const startDate = momentValue.startOf('days').toDate().getTime();
    const endDate = momentValue.endOf('days').toDate().getTime();

    return [startDate, endDate];
  };

  const selectAllVisibleProjects = () => {
    setAreAllProjectsSelected(false);
    setSelectedProjects(projectsToDisplay.map(R.prop('_id')));
    setSelectedProject('');
    setSelectedProjectsCount(projects.length);
  };

  const selectAllProjects = () => {
    setSelectedProjects([]);
    setAreAllProjectsSelected(true);
    setSelectedProject('');
    setSelectedProjectsCount(totalCount);
  };

  const bulkOperationProjectsFilters = R.omit(
    ['skip', 'limit'],
    buildFetchParams(searchParams)?.filters,
  );

  const updateProjectsActiveStatus = (projectIds) => {
    const willBeActive = searchParams.status === 'inactive';

    if (projectIds.length === 1) {
      const project = R.find(
        R.propEq('_id', R.head(projectIds)),
        projectsToDisplay,
      );

      Modal.confirm({
        title: `Mark ${project.name} project as ${
          willBeActive ? 'active' : 'inactive'
        }?`,
        content: `The project ${project.name} will be available in the "${
          willBeActive ? 'active' : 'inactive'
        } projects" table.`,
        okText: `Mark as ${willBeActive ? 'active' : 'inactive'}`,
        onOk: async () => {
          await dispatch(
            updateProjectsActiveStatusAction({
              projectIds,
              isActive: willBeActive,
            }),
          );
          message.success(
            `Project ${project.name} has been marked as ${
              willBeActive ? 'active' : 'inactive'
            }`,
          );

          resetSelections();
        },
      });
    } else {
      const filterQuery = !areAllProjectsSelected
        ? {
            _id: selectedProjects,
          }
        : bulkOperationProjectsFilters;

      Modal.confirm({
        title: `Mark ${pluralize('project', selectedProjectsCount, true)} as ${
          willBeActive ? 'active' : 'inactive'
        }?`,
        onOk: async () => {
          await dispatch(
            bulkUpdateProjectsActiveStatus({
              filterQuery,
              isActive: willBeActive,
            }),
          );

          message.success(
            `${pluralize(
              'project',
              selectedProjectsCount,
              true,
            )} scheduled to be made ${willBeActive ? 'active' : 'inactive'}`,
          );

          resetSelections();
        },
      });
    }
  };

  const deleteProjects = (projectIds) => {
    if (projectIds.length === 1) {
      const project = R.find(
        R.propEq('_id', R.head(projectIds)),
        projectsToDisplay,
      );

      Modal.confirm({
        title: `Are you sure you want to delete ${project.name}? This action cannot be undone`,
        okText: 'Delete project',
        okType: 'danger',
        onOk: async () => {
          await dispatch(deleteProjectsAction(projectIds));
          message.success(`Project ${project.name} successfully deleted`);
          resetSelections();
        },
      });
    } else {
      const filterQuery = !areAllProjectsSelected
        ? {
            _id: selectedProjects,
          }
        : bulkOperationProjectsFilters;

      Modal.confirm({
        title: `Are you sure you want to delete ${pluralize(
          'project',
          selectedProjectsCount,
          true,
        )}? This action cannot be undone`,
        okText: 'Delete projects',
        okType: 'danger',
        onOk: async () => {
          await dispatch(
            bulkDeleteProjects({
              filterQuery,
            }),
          );
          message.success(
            `${pluralize(
              'project',
              selectedProjectsCount,
              true,
            )} scheduled for deletion`,
          );
          resetSelections();
        },
      });
    }
  };

  const setProjectAsCurrent = (projectId, redirectTo) =>
    dispatch(setProjectAsCurrentAction(projectId, redirectTo));

  const { onChangePagination } = useListPagination();

  const paginationOptions = {
    showSizeChanger: true,
    showQuickJumper: true,
    onChange: onChangePagination,
    size: 'small',
    current: parseInt(R.propOr('1', 'page', searchParams)),
    pageSize,
    pageSizeOptions: ['12', '24', '36', '48', '60'],
    total: totalCount,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  };

  const initParties = party
    ? [
        {
          ...R.pick(['_id', 'name', 'isTrackingCompliance'], party),
          type: { name: R.path(['type', 'name'], party) },
          partyComplianceProfile: {
            complianceProfile: R.path(
              ['partyComplianceProfile', 'complianceProfile'],
              party,
            ),
          },
        },
      ]
    : [];

  const removePartyFromProjects = (projectId) => {
    const projectsIds = projectId ? [projectId] : selectedProjects;

    Modal.confirm({
      title: `Are you sure you want to remove the current party from ${pluralize(
        'project',
        projectsIds.length,
        true,
      )}?`,
      okType: 'danger',
      okText: `Remove from ${pluralize('project', projectsIds.length, true)}`,
      onOk: async () => {
        await dispatch(
          removePartyFromProjectsAction({
            partyId,
            projectsIds,
          }),
        );
        resetSelections();
      },
      okButtonProps: {
        'data-cy': 'removePartyFromProjects',
      },
    });
  };

  const resetSelections = () => {
    setSelectedProject('');
    setAreAllProjectsSelected(false);
    setSelectedProjects([]);
    setSelectedProjectsCount(0);
  };

  return (
    <Row style={{ padding: '0 20px 50px' }}>
      <SaveProjectModal
        projectId={selectedProject}
        initParties={initParties}
        visible={isVisibleProjectModal}
        onClose={() => {
          setIsVisibleProjectModal(false);
          setSelectedProject('');
        }}
        onSuccess={() => {
          setIsVisibleProjectModal(false);
        }}
      />
      {partyId && (
        <AddToProjectModal
          visible={visibleAddToProjectModal}
          partiesIds={partyId ? [partyId] : []}
          onClose={() => {
            setVisibleAddToProjectModal(false);
          }}
          onSuccess={() => {
            setVisibleAddToProjectModal(false);
          }}
          openCreateProjectModal={() => {
            setVisibleAddToProjectModal(false);
            setIsVisibleProjectModal(true);
          }}
        />
      )}
      <ProjectDuplicateModal
        projectId={selectedProject}
        visible={isVisibleDuplicateModal}
        onCancel={() => {
          setIsVisibleDuplicateModal(false);
          setSelectedProject('');
        }}
        onSuccess={() => {
          setIsVisibleDuplicateModal(false);
          resetSelections();
        }}
      />
      <Col {...containerConfig}>
        <ProjectsHeaderContainer
          totalCount={totalCount}
          onClickToAction={() =>
            partyId
              ? setVisibleAddToProjectModal(true)
              : setIsVisibleProjectModal(true)
          }
          onSuccess={() => setSelectedProjects([])}
        />
        <ProjectFilterTagsContainer onResetFilters={onResetProjectFilters} />
        <ProjectsFilterContainer
          areAllProjectsSelected={areAllProjectsSelected}
          selectedProjectsCount={selectedProjectsCount}
          updateFilters={updateFilters}
          updateNameFilter={updateNameFilter}
          resetFilters={onResetProjectFilters}
          filtersCount={filtersCount}
          setAreAllProjectsSelected={setAreAllProjectsSelected}
          setSelectedProjectsCount={setSelectedProjectsCount}
          totalCount={totalCount}
          projectsVisibleCount={projectsVisibleCount}
          sortField={sortField}
          sortDirection={sortDirection}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          selectAllProjects={selectAllProjects}
          selectAllVisibleProjects={selectAllVisibleProjects}
          updateProjectsActiveStatus={updateProjectsActiveStatus}
          deleteProjects={deleteProjects}
          embeddedInPartyProfile={embeddedInPartyProfile}
          removePartyFromProjects={removePartyFromProjects}
          loading={loading}
        />
        {shouldRefreshData && (
          <Alert
            message={
              <>
                New data is available.
                <Button
                  type="link"
                  onClick={() => {
                    refreshData();
                  }}
                >
                  Refresh now
                </Button>
              </>
            }
            banner
          />
        )}
        {projectsView === ListView.Card ? (
          <ProjectsCardsView
            loading={loading}
            projects={projectsToDisplay}
            currentProjectId={currentProjectId}
            areAllProjectsSelected={areAllProjectsSelected}
            setSelectedProjectsCount={setSelectedProjectsCount}
            setAreAllProjectsSelected={setAreAllProjectsSelected}
            setProjectAsCurrent={setProjectAsCurrent}
            selectedProjects={selectedProjects}
            setSelectedProjects={setSelectedProjects}
            paginationOptions={paginationOptions}
            openEditProjectModal={(project) => {
              setSelectedProject(project?._id);
              setIsVisibleProjectModal(true);
            }}
          />
        ) : (
          <ProjectsTableView
            loading={loading}
            projects={projectsToDisplay}
            partyId={partyId}
            isTrackingCompliance={isTrackingCompliance}
            currentProjectId={currentProjectId}
            setProjectAsCurrent={setProjectAsCurrent}
            selectedProjects={selectedProjects}
            areAllProjectsSelected={areAllProjectsSelected}
            setSelectedProjects={setSelectedProjects}
            updateSort={updateSort}
            sortField={sortField}
            sortDirection={sortDirection}
            updateProjectsActiveStatus={updateProjectsActiveStatus}
            deleteProjects={deleteProjects}
            paginationOptions={paginationOptions}
            onRowSelection={(selectedProjects) => {
              setAreAllProjectsSelected(false);
              setSelectedProjects(selectedProjects);
              setSelectedProjectsCount(selectedProjects.length);
            }}
            openEditProjectModal={(project) => {
              setSelectedProject(project?._id);
              setIsVisibleProjectModal(true);
            }}
            onOpenProjectDuplicateModal={(project) => {
              setSelectedProject(project?._id);
              setIsVisibleDuplicateModal(true);
            }}
            embeddedInPartyProfile={embeddedInPartyProfile}
            removePartyFromProjects={removePartyFromProjects}
          />
        )}
      </Col>
    </Row>
  );
};

export default ProjectsPage;
