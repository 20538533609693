import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { Typography, message } from 'antd';
import { useArchiveRequestRecordsMutation } from '../../hooks/useArchiveRequestRecordsMutation';

type ArchiveRequestRecordsModalProps = {
  open: boolean;
  count: number;
  filters?: ServerFilterInput;
  onCancel?: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
  onFailed?: () => void;
};

export const ArchiveRequestRecordsModal = ({
  open,
  count = 1,
  filters,
  onCancel,
  onCompleted,
  onScheduled,
  onFailed,
}: ArchiveRequestRecordsModalProps) => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const { archiveRequestRecords, isArchivingRequestRecords } =
    useArchiveRequestRecordsMutation();

  const handleArchive = async () => {
    const res = await archiveRequestRecords(filters);

    const status = res.data?.archiveRequestRecords.operation.status;

    console.log('res', status);

    if (status === BulkOperationStatus.Completed) {
      message.success(
        `${count} ${count > 1 ? 'requests have been' : 'request has been'} archived successfully.`,
      );
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Scheduled) {
      message.success(
        `${count} ${count > 1 ? 'requests have been' : 'request has been'} scheduled for archiving.`,
      );
      onScheduled?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        `The archiving of your ${count > 1 ? 'requests have' : 'request has'} failed. Please try again or contact support.`,
      );
      onFailed?.();
    }
  };

  const name = count > 1 ? 'requests' : 'request';

  return (
    <ModalConfirm
      data-cy="archive-request-records-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isArchivingRequestRecords,
      }}
      okText="Archive"
      title={`Are you sure you want to archive ${count} ${name}?`}
      onCancel={onCancel}
      onOk={handleArchive}
    >
      <Typography.Paragraph>
        Please confirm that you want to archive {count} {name}
      </Typography.Paragraph>
      <ul>
        <li>
          {count > 1 ? 'They' : 'It'} will not show up in your reports
          {isComplianceRequirementsAddOnEnabled ? ' and dashboard' : ''}
        </li>
        <li>No automatic requests for expired documents will be sent</li>
      </ul>
    </ModalConfirm>
  );
};
