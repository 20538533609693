import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const DOCUMENT_TYPES_QUERY = graphql(`
  query DocumentChecklistModule_DocumentTypes {
    listDocumentTypes {
      _id
      name
    }
  }
`);

export const useDocumentTypesQuery = ({
  skip,
}: {
  skip?: boolean;
}) => {
  const { data, loading } = useQuery(DOCUMENT_TYPES_QUERY, {
    skip,
    fetchPolicy: 'cache-and-network',
  });

  return {
    documentTypes: data?.listDocumentTypes || [],
    isDocumentTypesLoading: loading,
  };
};
