import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Empty, Input, type InputRef, Table } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import TagLabel from '@common/components/TagLabel';
import { useSearch } from '@common/hooks/useSearch';
import {
  type ComplianceProfile,
  type PartyDataEntity,
  SearchType,
} from '@graphql/types/graphql';
import ProjectPartyTabRowContainer from './ProjectPartyTabRowContainer';

interface ProjectPartiesTabContainerProps {
  parties: Pick<
    PartyDataEntity,
    | '_id'
    | 'name'
    | 'type'
    | 'isActive'
    | 'isTrackingCompliance'
    | 'partyComplianceProfile'
  >[];
  onSelectParty: (partyId: PartyDataEntity['_id']) => void;
  // mapping: { partyId: complianceProfileId | null }
  partyProfileAssociation: Record<string, string | null>;
  onDeleteParty: (partyId: PartyDataEntity['_id']) => void;
  onChangeProfileAssociation: (mapping: Record<string, string | null>) => void;
  complianceProfiles: Pick<ComplianceProfile, '_id' | 'name'>[];
}

const ProjectPartiesTabContainer = ({
  parties,
  onSelectParty,
  partyProfileAssociation,
  onDeleteParty,
  onChangeProfileAssociation,
  complianceProfiles,
}: ProjectPartiesTabContainerProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { data } = useSearch(
    {
      type: SearchType.Parties,
      query: searchValue,
    },
    {
      skip: !searchValue,
    },
  );
  const filterByNameInput = useRef<InputRef>(null);
  const options = data?.map(
    (party: Pick<PartyDataEntity, '_id' | 'name' | 'isActive'>) => ({
      value: party._id,
      label: (
        <span>
          {party.name}
          {!party.isActive && (
            <StyledInactiveParty>
              <TagLabel>inactive</TagLabel>
            </StyledInactiveParty>
          )}
        </span>
      ),
    }),
  );

  return (
    <>
      <AutoComplete
        data-cy="saveProjectModalSearchPartyInput"
        options={options}
        filterOption={(_, option) =>
          !parties.find((party) => party._id === option?.value)
        }
        allowClear
        onSearch={setSearchValue}
        style={{ width: '100%' }}
        value={searchValue}
        onSelect={(item) => {
          setSearchValue('');
          onSelectParty(item);
        }}
      >
        <Input prefix={<SearchOutlined />} placeholder={'Search parties...'} />
      </AutoComplete>
      <StyledTable<ProjectPartiesTabContainerProps['parties'][number]>
        size="small"
        data-cy="saveProjectPartiesList"
        dataSource={parties}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No parties to show"
            />
          ),
        }}
        columns={[
          {
            title: 'Parties in this project',
            key: '_id',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
              return (
                <div style={{ padding: '10px' }}>
                  <Input
                    ref={filterByNameInput}
                    placeholder="Filter by name..."
                    value={selectedKeys[0] as string}
                    allowClear
                    onChange={(e) => {
                      setSelectedKeys(e.target.value ? [e.target.value] : []);

                      if (e.target.value === '') {
                        confirm({ closeDropdown: false });
                      }
                    }}
                    onPressEnter={() => confirm({ closeDropdown: false })}
                  />
                </div>
              );
            },
            onFilter: (searchInput, party) => {
              return party?.name
                ?.toLowerCase()
                ?.includes(searchInput?.toString().toLowerCase());
            },
            onFilterDropdownOpenChange: (visible) => {
              if (visible) {
                setTimeout(() => filterByNameInput?.current?.select(), 100);
              }
            },

            render: (_, party) => (
              <ProjectPartyTabRowContainer
                complianceProfiles={complianceProfiles}
                party={party}
                partyProfileAssociation={partyProfileAssociation}
                deleteParty={onDeleteParty}
                onChangeProfileAssociation={onChangeProfileAssociation}
                key={party._id}
              />
            ),
          },
        ]}
        pagination={{
          pageSize: 12,
          size: 'small',
          showSizeChanger: false,
          showQuickJumper: true,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
};

const StyledTable = styled(Table)`
  margin-top: 20px;
  padding-bottom: 20px;
`;

const StyledInactiveParty = styled.span`
  font-size: 12px;
  margin-left: 15px;
  color: #1890ff;
`;

export default ProjectPartiesTabContainer;
