import { Modal as AntdModal, type ModalProps } from 'antd';
import styled from 'styled-components';

const StyledModal = styled(AntdModal)`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header,
  .ant-modal-footer {
    padding: 16px 24px;
    margin: 0;
  }

  .ant-modal-body {
    padding: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayTone};
  }
`;

export function Modal(props: ModalProps) {
  return <StyledModal width={800} {...props} />;
}
