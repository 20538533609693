import { Button, message } from 'antd';

import { NoteCard } from '@common/components/NoteCard';
import type { EditorState } from 'draft-js';
import { SidebarLoadingError } from 'layouts';

import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  isEditorStateBlank,
  setInitialEditorStateFromHTML,
} from '@common/components/RichTextEditor/helpers';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  type PrimaryRecordNote,
  usePrimaryRecordNote,
} from './hooks/usePrimaryRecordNote';
import { useUpdatePrimaryRecordNote } from './hooks/useUpdatePrimaryRecordNote';

const getEditorStateContent = (editorState: EditorState) =>
  isEditorStateBlank(editorState) ? null : getEditorHTMLContent(editorState);

export const SidebarNotes = ({ recordId }: { recordId: string }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editorState, setEditorState] = useState(getEmptyEditorState());
  const { updateNote } = useUpdatePrimaryRecordNote(recordId);

  const updateEditorStateNotes = useCallback((notes: PrimaryRecordNote) => {
    setEditorState(setInitialEditorStateFromHTML(notes?.[0]?.message ?? ''));
  }, []);

  const {
    notes,
    loading,
    error,
    refetch: refetchNotes,
  } = usePrimaryRecordNote({
    primaryRecordId: recordId,
    onCompleted: updateEditorStateNotes,
  });

  const onEditNote = () => {
    setIsEditing(true);
  };

  const updateNoteMessage = async (content: string | null) => {
    try {
      await updateNote(content);
      await refetchNotes();
      setIsEditing(false);
      message.success('Additional note saved successfully');
    } catch (error) {
      message.error('Error while saving additional note, please try again');
    }
  };

  const handleDeleteNote = () => {
    updateNoteMessage(null);
  };

  if (error) {
    return (
      <SidebarLoadingError
        loadingActionLabel="Additional Notes"
        onActionClick={() => refetchNotes()}
      />
    );
  }

  if (loading) {
    return <NoteCard.Skeleton />;
  }

  if (isEditing) {
    return (
      <StyledEditor>
        <RichTextEditor
          minHeight={32}
          maxHeight={150}
          editorState={editorState}
          disableInlineStyleButtons={false}
          focusDependence={false}
          isOneLineEditor={false}
          isValid
          placeholder="Add note..."
          onChange={(e: EditorState) => setEditorState(e)}
        />
        <section className="buttons">
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button
            loading={loading}
            className="saveButton"
            type="primary"
            onClick={() =>
              updateNoteMessage(getEditorStateContent(editorState))
            }
          >
            Save
          </Button>
        </section>
      </StyledEditor>
    );
  }

  return (
    <>
      {notes?.[0] ? (
        <NoteCard
          note={notes[0]?.message ?? ''}
          onDelete={handleDeleteNote}
          onEdit={onEditNote}
        />
      ) : (
        <Button block onClick={onEditNote}>
          Add note
        </Button>
      )}
    </>
  );
};

const StyledEditor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }

  .saveButton {
    width: 85px;
  }
`;
