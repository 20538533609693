import * as R from 'ramda';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getIsAppAuthenticated } from '@modules/app/selectors';
import { initCommandBar } from '@modules/commandbar/actions';
import { getIsCommandBarReady } from '@modules/commandbar/selectors';
import {
  addCallback,
  addContext,
  addRouter,
  complianceProfileFormatter,
  documentFormatter,
  documentTypeFormatter,
  partyFormatter,
  partyTypeFormatter,
  projectFormatter,
} from '@modules/commandbar/utils/commandbar-helpers';
import {
  ContextRecordKeys,
  GlobalCallbackKeys,
} from '@modules/commandbar/utils/commandbar-keys';
import {
  archiveDocument,
  assignDocumentsToParty,
  deleteDocument,
  detachDocumentsFromParty,
  reviewDocument,
} from '@modules/document/actions';
import { assignComplianceProfile } from '@modules/party-compliance-profile/actions';
import { deleteParty, updatePartyActiveStatus } from '@modules/party/actions';
import {
  addPartiesToProject,
  getViewedProjects,
} from '@modules/project/actions';
import { search } from '@modules/search/actions';
import { setProjectAsCurrent } from '@modules/system-settings/actions';
import { getGraphqlPayload, getGraphqlResponse } from '@store/helpers';

export const formatters = {
  documents: documentFormatter,
  documentTypes: documentTypeFormatter,
  parties: partyFormatter,
  partyTypes: partyTypeFormatter,
  projects: projectFormatter,
  complianceProfiles: complianceProfileFormatter,
};

const CommandBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAppAuthenticated = useSelector(getIsAppAuthenticated);
  const isCommandBarReady = useSelector(getIsCommandBarReady);

  const searchRecords = useCallback(
    (type) => (query) =>
      dispatch(
        search({
          type,
          query,
          options: { limit: 15 },
        }),
      ).then((res) => R.map(formatters[type], getGraphqlPayload(res))),
    [dispatch],
  );

  const searchProjects = useCallback(
    (query) =>
      dispatch(getViewedProjects({ first: 12, filter: { name: query } })).then(
        (res) => R.map(formatters.projects, getGraphqlResponse(res)?.nodes),
      ),
    [dispatch],
  );

  useEffect(() => {
    if (isAppAuthenticated) {
      dispatch(initCommandBar());
    }

    return () => {
      window.CommmandBar?.shutdown();
    };
  }, [dispatch, isAppAuthenticated]);

  useEffect(() => {
    if (isCommandBarReady) {
      addRouter(navigate);

      addContext(ContextRecordKeys.documents, [], {
        searchOptions: {
          searchFunction: searchRecords(ContextRecordKeys.documents),
        },
      });

      addContext(ContextRecordKeys.parties, [], {
        searchOptions: {
          searchFunction: searchRecords(ContextRecordKeys.parties),
        },
      });

      addContext(ContextRecordKeys.projects, () => searchProjects(''), {
        searchOptions: {
          searchFunction: searchProjects,
        },
      });

      addCallback(GlobalCallbackKeys.switchProjects, ({ project }) => {
        dispatch(setProjectAsCurrent(project.id));
      });

      addCallback(GlobalCallbackKeys.switchToOrg, () => {
        dispatch(setProjectAsCurrent(null));
      });

      addCallback(GlobalCallbackKeys.makePartyInactive, ({ party }) => {
        dispatch(
          updatePartyActiveStatus({ partyId: party.id, isActive: false }),
        );
      });

      addCallback(
        GlobalCallbackKeys.assignDocumentToParty,
        ({ document, party }) => {
          dispatch(
            assignDocumentsToParty({
              documentIds: [document.id],
              partyId: party.id,
            }),
          );
        },
      );

      addCallback(
        GlobalCallbackKeys.addPartyToProject,
        ({ party, project }) => {
          dispatch(
            addPartiesToProject({
              partyIds: [party.id],
              projectId: project.id,
            }),
          );
        },
      );

      addCallback(
        GlobalCallbackKeys.assignComplianceProfile,
        ({ complianceProfile, party }) => {
          dispatch(
            assignComplianceProfile({
              partyId: party.id,
              complianceProfileId: complianceProfile.id,
            }),
          );
        },
      );

      addCallback(GlobalCallbackKeys.deleteParty, ({ party }) => {
        dispatch(deleteParty(party.id));
      });

      addCallback(
        GlobalCallbackKeys.detachDocumentFromParty,
        ({ document }) => {
          dispatch(detachDocumentsFromParty({ documentIds: [document.id] }));
        },
      );

      addCallback(GlobalCallbackKeys.markToReview, ({ document }) => {
        dispatch(reviewDocument({ id: document.id, isReviewed: false }));
      });

      addCallback(GlobalCallbackKeys.markReviewed, ({ document }) => {
        dispatch(reviewDocument({ id: document.id, isReviewed: true }));
      });

      addCallback(GlobalCallbackKeys.archiveDocument, ({ document }) => {
        dispatch(archiveDocument({ id: document.id, isArchived: true }));
      });

      addCallback(GlobalCallbackKeys.deleteDocument, ({ document }) => {
        dispatch(deleteDocument(document.id));
      });
    }
  }, [dispatch, navigate, isCommandBarReady, searchProjects, searchRecords]);

  return null;
};

export default CommandBar;
