import type { PrimaryRecordContact } from '@graphql/types/graphql';
import {
  type EmailTemplate,
  EmailTemplateSelect,
} from '@modules/email-template';
import { Form, Select } from 'antd';

export const FORM_ITEM_CONTACTS_KEY = 'contacts';

export const RequestForm = ({
  contacts,
  emailTemplates,
}: {
  contacts: Pick<
    PrimaryRecordContact,
    | '_id'
    | 'contactPersonName'
    | 'email'
    | 'defaultRequestRecipient'
    | 'primary'
  >[];
  emailTemplates: Pick<EmailTemplate, '_id' | 'name' | 'type'>[];
}) => {
  return (
    <section>
      <div data-cy="sendRequestContentWrapper">
        <Form.Item
          name="emailTemplateId"
          label="Email template"
          valuePropName="selectedTemplateId"
          trigger="onTemplateChange"
          rules={[
            { required: true, message: 'Please select an email template' },
          ]}
        >
          {/* @ts-expect-error Form.Item provides the missing props */}
          <EmailTemplateSelect templates={emailTemplates} />
        </Form.Item>

        <Form.Item
          name={FORM_ITEM_CONTACTS_KEY}
          label="Recipients"
          rules={[{ required: true, message: 'Please select recipients' }]}
        >
          <Select
            data-cy="contactsSelect"
            mode="multiple"
            placeholder="Select recipients..."
          >
            {contacts.map((contact) => (
              <Select.Option key={contact._id} value={contact._id}>
                {contact.contactPersonName
                  ? `${contact.contactPersonName} <${contact.email}>`
                  : contact.email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* <StyledSection>
          <Form.Item
            name="message"
            label="Message to display on the document request page:"
            getValueFromEvent={(editorState) =>
              getEditorHTMLContent(editorState)
            }
          >
            <RichTextEditor
              isValid
              minHeight={50}
              placeholder="Specify any additional instructions here..."
              placeholders={REQUEST_PLACEHOLDERS}
            />
          </Form.Item>
        </StyledSection> */}
      </div>
    </section>
  );
};
