export const getRatingLevel = (rating?: string | null) => {
  switch (rating) {
    case 'A+':
    case 'A++':
    case 'A':
    case 'A-': {
      return 'level_1';
    }
    case 'B+':
    case 'B++':
    case 'B':
    case 'B-': {
      return 'level_2';
    }
    case 'C+':
    case 'C++':
    case 'C':
    case 'C-': {
      return 'level_3';
    }
    case 'D': {
      return 'level_4';
    }
    default: {
      return 'level_5';
    }
  }
};
