import { SelectOutlined, SettingOutlined } from '@ant-design/icons';
import { Checkbox, Col, Progress, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import Box from '@common/components/Box';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';

import { ComplianceRequirementsAddOnFeature } from '@modules/add-on';
import { ProjectComplianceStatus } from '../constants';

const ProjectCard = ({
  project,
  currentProjectId,
  setProjectAsCurrent,
  selectedProjects,
  setSelectedProjects,
  openEditProjectModal,
  setAreAllProjectsSelected,
  setSelectedProjectsCount,
}) => {
  const isSelected = selectedProjects.includes(project._id);
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const navigate = useNavigate();

  return (
    <ProjectCard.Wrapper>
      <ProjectCard.ProjectInfoWrapper>
        <ProjectCard.GeneralInfoWrapper>
          <Col>
            <HideForViewerRole>
              <Checkbox
                checked={isSelected}
                onChange={() => {
                  const projects = isSelected
                    ? (selectedProjects || []).filter(
                        (id) => id !== project._id,
                      )
                    : [...selectedProjects, project._id];

                  setAreAllProjectsSelected(false);
                  setSelectedProjects(projects);
                  setSelectedProjectsCount(projects.length);
                }}
              />
            </HideForViewerRole>
          </Col>
          <ProjectCard.ProjectNameColumn>
            <ProjectCard.ProjectName
              onClick={() => {
                setProjectAsCurrent(project._id);
                navigate(`${organizationNamespace}/parties`);
              }}
            >
              <Typography.Text ellipsis>{project.name}</Typography.Text>
            </ProjectCard.ProjectName>
            <ProjectCard.Actions>
              <HideForViewerRole>
                <SettingOutlined
                  onClick={() => openEditProjectModal(project)}
                  data-cy="projectSettings"
                />
              </HideForViewerRole>
              {currentProjectId === project._id ? (
                <Tooltip title="Current project">
                  <SelectOutlined style={{ color: '#1890ff' }} />
                </Tooltip>
              ) : (
                <Tooltip title="Switch to this project">
                  <SelectOutlined
                    onClick={() => setProjectAsCurrent(project._id)}
                  />
                </Tooltip>
              )}
            </ProjectCard.Actions>
          </ProjectCard.ProjectNameColumn>
        </ProjectCard.GeneralInfoWrapper>
        <ComplianceRequirementsAddOnFeature>
          <ProjectCard.Progress
            showInfo={false}
            data-cy="complianceProgressBar"
            format={() => (
              <>
                {project.complianceStatus?.compliantPartiesCount}/
                {project.complianceStatus?.totalPartiesCount}
              </>
            )}
            percent={
              (project.complianceStatus?.compliantPartiesCount /
                project.complianceStatus?.totalPartiesCount) *
              100
            }
            size="small"
            status={
              project.complianceStatus?.status ===
              ProjectComplianceStatus.Compliant
                ? 'success'
                : ''
            }
          />
        </ComplianceRequirementsAddOnFeature>
        <ProjectCard.Status>
          <ProjectCard.StatusHeader>
            <ComplianceRequirementsAddOnFeature>
              <Col span={8}>Compliant parties</Col>
            </ComplianceRequirementsAddOnFeature>
            <Col span={8}>Start date</Col>
            <Col span={8}>End date</Col>
          </ProjectCard.StatusHeader>
          <Row>
            <ComplianceRequirementsAddOnFeature>
              <Col span={8}>
                {project.complianceStatus?.compliantPartiesCount}/
                {project.complianceStatus?.totalPartiesCount}
              </Col>
            </ComplianceRequirementsAddOnFeature>
            <Col span={8}>
              {project.startDate ? (
                <Tooltip title={moment(project.startDate).format('lll')}>
                  {moment(project.startDate).format('ll')}
                </Tooltip>
              ) : (
                '—'
              )}
            </Col>
            <Col span={8}>
              {project.endDate ? (
                <Tooltip title={moment(project.endDate).format('lll')}>
                  {moment(project.endDate).format('ll')}
                </Tooltip>
              ) : (
                '—'
              )}
            </Col>
          </Row>
        </ProjectCard.Status>
      </ProjectCard.ProjectInfoWrapper>
    </ProjectCard.Wrapper>
  );
};

ProjectCard.Wrapper = styled(Box)`
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black};
  height: 100%;
`;

ProjectCard.ProjectInfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  border-radius: 4px;

  .ant-badge-status-text {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-badge-status-processing + .ant-badge-status-text {
    color: rgba(24, 144, 255, 1);
  }
`;

ProjectCard.GeneralInfoWrapper = styled(Row)`
  padding: 15px;
`;

ProjectCard.ProjectNameColumn = styled(Col)`
  width: calc(100% - 16px);
  padding-left: 13px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

ProjectCard.ProjectName = styled.span`
  max-width: 73%;
  color: rgba(0, 0, 0, 0.85);
  line-height: 23px;
  display: flex;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s;
  margin-right: 10px;

  .ant-typography {
    color: inherit;
  }

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`;

ProjectCard.Actions = styled.div`
  white-space: nowrap;

  > .anticon {
    font-size: 20px;
    padding: 0 8px;
    color: #595959;
  }
`;

ProjectCard.Progress = styled(Progress)`
  padding: 0 55px 20px 45px;

  .ant-progress-outer {
    margin-right: calc(-3em - 8px);
    padding-right: calc(3em + 8px);
  }

  .ant-progress-text {
    width: 3em;
    font-size: 11px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 19px;
  }

  &.ant-progress-status-success {
    .ant-progress-text {
      color: #52c41a;
    }
  }
`;

ProjectCard.Status = styled.div`
  padding: 10px 10px 15px 45px;
  font-size: 12px;
  border-top: 1px solid hsla(213deg, 69%, 95%, 1);
  background: rgb(249, 251, 254) none repeat scroll 0% 0%;
`;

ProjectCard.StatusHeader = styled(Row)`
  color: rgba(0, 0, 0, 0.65);
  line-height: 21px;
`;

export default ProjectCard;
