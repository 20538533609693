import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { message } from 'antd';

const UPDATE_DOCUMENT_CHECKLIST_MUTATION = graphql(`
  mutation DocumentChecklistModule_UpdateDocumentChecklist($payload: UpdateComplianceProfileInput!) {
    updateComplianceProfileV2(data: $payload) {
      _id
      documentChecklists {
        _id
        attributeId
        attributeLabel
        isActive
        type {
          _id
          name
        }
      }
    }
  }
`);

export const useUpdateDocumentChecklistMutation = () => {
  const [mutate, { loading }] = useMutation(
    UPDATE_DOCUMENT_CHECKLIST_MUTATION,
    {
      onCompleted: () => {
        message.success('Checklist updated successfully');
      },
    },
  );

  return {
    updateDocumentChecklist: mutate,
    isUpdateDocumentChecklistLoading: loading,
  };
};
