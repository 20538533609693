import { Typography } from 'antd';
import { TableEmptyState } from './TableEmptyState';

export const TableNoRowsOverlay = ({
  hasActiveFilters,
  emptyStateComponent,
}: {
  hasActiveFilters: boolean;
  emptyStateComponent: React.ReactElement;
}) => {
  if (hasActiveFilters) {
    return (
      <TableEmptyState className="table-filter-empty-state">
        <TableEmptyState.Image />
        <Typography.Text color="secondary">
          There are no results for that criteria.
        </Typography.Text>
      </TableEmptyState>
    );
  }

  return emptyStateComponent;
};
