import { forwardRef, useMemo } from 'react';
import { useParams } from 'react-router';

import {
  type CellEditorParams,
  type CustomSelectionChangedEvent,
  DateCustomFilter,
  type FilterChangedParams,
  LinkCell,
  ListCell,
  ListCellEditor,
  Table,
  type TableRef,
  type TableSortingState,
  type TableState,
} from '@common/components/Table';
import { COMMON_CELL_CLASSES } from '@common/components/Table/constants';
import type { Document, Subject } from '@graphql/types/graphql';
import DocumentReviewStatusTag from '@modules/document/components/DocumentReviewStatusTag';

import {
  APPLIES_TO_ALL_PROJECTS_STATUS,
  ARCHIVE_STATUS,
  type ActionType,
  DOCUMENT_ACTION_TYPES,
  REVIEW_STATUS,
  SEVERITY_STATUS,
  VERIFICATION_STATUS,
} from '../../constants';

import {
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';
import {
  type RecordRequirement,
  getDocumentFirstExpirationDate,
} from '@modules/document/utils/getDocumentFirstExpirationDate';
import { DocumentActions } from './components/DocumentActions';
import { DocumentBadges } from './components/DocumentBadges';
import { DocumentExpirationDate } from './components/DocumentExpirationDate';
import { DocumentProjects } from './components/DocumentProjects';
import { DocumentRecap } from './components/DocumentRecap';
import { DocumentSeverity } from './components/DocumentSeverity';
import { getDocumentSubjectsList } from './components/DocumentSubjects';
import { DocumentVerification } from './components/DocumentVerification';
import { ProjectsCustomFilter } from './components/ProjectsCustomFilter';
import { SubjectCustomFilter } from './components/SubjectCustomFilter';
import { TypeCustomFilter } from './components/TypeCustomFilter';
import { COLUMN_FIELDS } from './constants';
import { useDocumentsList } from './hooks';

type DocumentsListProps = {
  contextRecordId?: string;
  primaryRecordId?: string;
  excludedActions?: ActionType[];
  excludedFilters?: (keyof typeof COLUMN_FIELDS)[];
  onActionClick: (params: {
    actionType: ActionType;
    document: Document;
  }) => void;
  onSelectionChanged: (params: CustomSelectionChangedEvent) => void;
  onFilterChanged: (params: FilterChangedParams) => void;
  onSortChanged: (params: TableSortingState) => void;
  onPaginationChanged: (params: any) => void;
  tableState?: TableState;
};

export type DocumentListRef = TableRef;

type SetFilterValue = {
  code: string;
  name: string;
};

const commonSetFilterParams = {
  keyCreator: ({ value }: { value: SetFilterValue }) => value.code,
  valueFormatter: ({ value }: { value: SetFilterValue }) => value.name,
};

export const DocumentsList = forwardRef<DocumentListRef, DocumentsListProps>(
  (
    {
      primaryRecordId,
      contextRecordId,
      excludedActions,
      excludedFilters,
      onSelectionChanged,
      onActionClick,
      onFilterChanged,
      onSortChanged,
      onPaginationChanged,
      tableState = { filters: {}, sorting: {}, pagination: {} },
    },
    documentListRef,
  ) => {
    const { organizationSlugifyName } = useParams();

    const { isComplianceRequirementsAddOnEnabled } =
      useIsComplianceRequirementsAddOnEnabled();
    const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();

    const { getDocumentsList } = useDocumentsList({
      primaryRecordId,
      contextRecordId,
    });

    // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
    const gridColumns = useMemo(
      () =>
        [
          {
            headerName: 'Document',
            field: COLUMN_FIELDS.recap,
            lockPosition: 'left' as const,
            pinned: 'left' as const,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            autoHeight: true,
            minWidth: 400,
            sortable: false,
            valueGetter: ({ data }: { data: Document }) => data,
            cellRenderer: ({ value: document }: { value: Document }) => (
              <DocumentRecap
                documentId={document._id}
                friendlyName={document.friendlyName || ''}
                isReviewed={document.reviewedAt}
                notes={document.notes}
                types={document.types}
                onNameClick={() => {
                  onActionClick({
                    actionType: DOCUMENT_ACTION_TYPES.preview,
                    document,
                  });
                }}
              />
            ),
          },
          {
            headerName: 'Name',
            field: COLUMN_FIELDS.friendlyName,
            cellDataType: 'text',
            sortable: true,
            hide: true,
            filter: 'agTextColumnFilter',
            filterParams: {
              filterOptions: ['contains'],
            },
            valueGetter: ({ data }: { data: Document }) => data.friendlyName,
          },
          {
            headerName: 'Types',
            field: COLUMN_FIELDS.type,
            filter: TypeCustomFilter,
            sortable: true,
            hide: true,
            valueGetter: ({ data }: { data: Document }) => data.types,
            cellRenderer: ({ value }: { value: Document['types'] }) => (
              <ListCell>
                {
                  value?.map((type, index) => (
                    <span key={`${type._id}-${index}`}>{type.name}</span>
                  )) as JSX.Element[]
                }
              </ListCell>
            ),
          },
          {
            headerName: 'Review Status',
            field: COLUMN_FIELDS.reviewStatus,
            filter: 'agSetColumnFilter',
            sortable: false,
            hide: true,
            filterParams: {
              ...commonSetFilterParams,
              values: [
                { code: REVIEW_STATUS.Reviewed, name: 'Reviewed' },
                { code: REVIEW_STATUS.ToReview, name: 'To Review' },
              ],
            },
            valueGetter: ({ data }: { data: Document }) => data.reviewedAt,
            cellRenderer: ({ value }: { value: string }) => (
              <DocumentReviewStatusTag isReviewed={value} />
            ),
          },
          {
            headerName: 'Stats',
            field: COLUMN_FIELDS.badges,
            sortable: false,
            width: 120,
            valueGetter: ({ data }: { data: Document }) => data,
            cellRenderer: ({ value }: { value: Document }) => (
              <DocumentBadges projectId={contextRecordId} document={value} />
            ),
          },
          {
            headerName: 'Severity',
            field: COLUMN_FIELDS.severity,
            hide: true,
            flex: 1,
            sortable: false,
            filter: 'agSetColumnFilter',
            filterParams: {
              ...commonSetFilterParams,
              values: [
                { name: 'High severity', code: SEVERITY_STATUS.High },
                { name: 'Medium severity', code: SEVERITY_STATUS.Medium },
                { name: 'Low severity', code: SEVERITY_STATUS.Low },
              ],
            },
            valueGetter: ({ data }: { data: Document }) => data.flag,
            cellRenderer: ({ value }: { value: Document['flag'] }) => (
              <DocumentSeverity flag={value} />
            ),
          },
          {
            headerName: 'Verification',
            field: COLUMN_FIELDS.verification,
            hide: true,
            flex: 1,
            sortable: false,
            filter: 'agSetColumnFilter',
            filterParams: {
              ...commonSetFilterParams,
              values: [
                { name: 'Verified', code: VERIFICATION_STATUS.Verified },
                { name: 'To Verify', code: VERIFICATION_STATUS.NotVerified },
              ],
            },
            valueGetter: ({ data }: { data: Document }) => data.verification,
            cellRenderer: ({ value }: { value: Document['verification'] }) => (
              <DocumentVerification verification={value} />
            ),
          },
          {
            headerName: 'Document Status',
            field: COLUMN_FIELDS.archiveStatus,
            hide: true,
            flex: 1,
            filter: 'agSetColumnFilter',
            filterParams: {
              ...commonSetFilterParams,
              values: [
                { name: 'Archived', code: ARCHIVE_STATUS.Archived },
                { name: 'Active', code: ARCHIVE_STATUS.NonArchived },
              ],
            },
            valueGetter: ({ data }: { data: Document }) => data.archivedAt,
            cellRenderer: ({ value }: { value: Document['archivedAt'] }) => (
              <span>{value ? 'Archived' : 'Active'}</span>
            ),
          },
          {
            headerName: 'Applies To All Projects',
            field: COLUMN_FIELDS.appliesToAllProjects,
            hide: true,
            flex: 1,
            filter: isContextsAddOnEnabled ? 'agSetColumnFilter' : false,
            filterParams: {
              ...commonSetFilterParams,
              values: [
                {
                  name: 'Applied',
                  code: APPLIES_TO_ALL_PROJECTS_STATUS.Applied,
                },
              ],
            },
            valueGetter: ({ data }: { data: Document }) =>
              data.appliesToAllProjects,
            cellRenderer: ({
              value,
            }: {
              value: Document['appliesToAllProjects'];
            }) => <span>{value ? 'Applied' : 'Nonapplied'}</span>,
          },
          {
            headerName: 'Projects',
            field: COLUMN_FIELDS.projects,
            hide: true,
            sortable: false,
            flex: 1,
            filter: isContextsAddOnEnabled ? ProjectsCustomFilter : false,
            valueGetter: ({ data }: { data: Document }) => ({
              appliesToAllProjects: data.appliesToAllProjects,
              projects: data.projects,
            }),
            cellRenderer: ({
              value,
            }: {
              value: {
                projects: Document['projects'];
                appliesToAllProjects: Document['appliesToAllProjects'];
              };
            }) => <DocumentProjects {...value} />,
          },
          {
            headerName: 'Party',
            field: COLUMN_FIELDS.party,
            sortable: true,
            flex: 1,
            valueGetter: ({ data }: { data: Document }) => data.party || {},
            cellRenderer: ({ value }: { value: Document['party'] }) => {
              return (
                value?._id && (
                  <LinkCell
                    to={`/${organizationSlugifyName}/parties/${value._id}/overview`}
                  >
                    {value.name}
                  </LinkCell>
                )
              );
            },
          },
          {
            headerName: 'Subjects',
            hide: !isComplianceRequirementsAddOnEnabled,
            field: COLUMN_FIELDS.subjects,
            sortable: false,
            flex: 1,
            cellClass: COMMON_CELL_CLASSES.noOverflow,
            filter: isComplianceRequirementsAddOnEnabled
              ? SubjectCustomFilter
              : false,
            valueGetter: ({ data }: { data: Document }) => ({
              metadata: data?.metadata,
              primaryRecord: data?.party,
            }),
            cellRenderer: ({
              value,
            }: {
              value: {
                metadata: Document['metadata'];
                primaryRecord: Document['party'];
              };
            }) => (
              <ListCell>
                {getDocumentSubjectsList(value?.metadata, value?.primaryRecord)}
              </ListCell>
            ),
            cellEditor: (props: CellEditorParams) => (
              <ListCellEditor {...props}>
                {getDocumentSubjectsList(props.data.subject, props.data.party)}
              </ListCellEditor>
            ),
            cellEditorPopup: true,
            editable: ({ data }: { data: Record<string, Subject> }) =>
              Boolean(data?.subject),
          },
          {
            headerName: 'Requirement ID',
            field: COLUMN_FIELDS.requirementId,
            hide: true,
            sortable: false,
            flex: 1,
            filter: isComplianceRequirementsAddOnEnabled
              ? 'agTextColumnFilter'
              : false,
            filterParams: {
              filterOptions: ['contains'],
            },
          },
          {
            headerName: 'Expiration',
            field: COLUMN_FIELDS.expirationDate,
            sortable: true,
            flex: 1,
            filter: DateCustomFilter,
            valueGetter: ({ data }: { data: Document }) => data,
            cellRenderer: ({ data }: { data: Document }) => {
              const expirationDate = isComplianceRequirementsAddOnEnabled
                ? getDocumentFirstExpirationDate({
                    metadata: data.metadata,
                    recordRequirements: data.party
                      ?.requirements as RecordRequirement[],
                  })
                : data.expirationDate;

              return <DocumentExpirationDate expirationDate={expirationDate} />;
            },
          },
          {
            headerName: 'Upload Date',
            field: COLUMN_FIELDS.createdAt,
            cellDataType: 'date',
            sortable: true,
            cellClass: COMMON_CELL_CLASSES.alignCenter,
            flex: 1,
            filter: DateCustomFilter,
            valueGetter: ({ data }: { data: Document }) =>
              new Date(data.createdAt),
          },
          {
            headerName: '',
            field: COLUMN_FIELDS.actions,
            lockPosition: 'right' as const,
            pinned: 'right' as const,
            width: 90,
            maxWidth: 90,
            sortable: false,
            valueGetter: ({ data }: { data: Document }) => data,
            cellRenderer: ({ value: document }: { value: Document }) => (
              <DocumentActions
                document={document}
                projectId={contextRecordId}
                excludedActions={excludedActions}
                onClick={onActionClick}
              />
            ),
          },
        ].map((column) =>
          excludedFilters?.includes(column.field)
            ? {
                ...column,
                filter: false,
              }
            : column,
        ),

      [contextRecordId],
    );

    return (
      <Table
        ref={documentListRef}
        columnDefs={gridColumns}
        getRowData={getDocumentsList}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onPaginationChanged={onPaginationChanged}
        tableState={tableState}
      />
    );
  },
);
