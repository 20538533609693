import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';
import type { Contact as FormContact } from '../containers/ContactForm/ContactForm';

import { DATA_MODEL_REFERENCES } from '@common/constants';

const UPDATE_MUTATION = graphql(`
  mutation UpdateContact($data: UpdateContactsInput!) {
    updateContacts(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useUpdatePrimaryRecordContact = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [updateContactMutation, { loading }] = useMutation(UPDATE_MUTATION);

  const updatePrimaryRecordContact = useCallback(
    ({
      contact,
      isPrimary,
      isDefaultRequestRecipient,
    }: {
      contact: FormContact;
      isPrimary: boolean;
      isDefaultRequestRecipient: boolean;
    }) =>
      updateContactMutation({
        variables: {
          data: {
            contacts: [
              {
                ...contact,
                avatar: contact.avatar ?? null,
                _id: contact._id!,
                records: [
                  {
                    id: primaryRecordId,
                    reference: DATA_MODEL_REFERENCES.primaryRecord,
                    primary: isPrimary,
                    defaultRequestRecipient: isDefaultRequestRecipient,
                  },
                ],
              },
            ],
          },
        },
      }),
    [updateContactMutation, primaryRecordId],
  );

  return {
    loading,
    updatePrimaryRecordContact,
  };
};
