import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { mapTableFiltersToServerFilters } from '@common/utils/filters';
import { useCustomLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { DYNAMIC_FIELDS_BY_RECORD_TYPE } from '../constants';
import { SERVER_FILTERS_CONFIG } from '../constants';

export const REQUESTS_QUERY = graphql(`
  query RequestsRecords(
    $input: RequestRecordsInput
    $withPrimaryRecord: Boolean!
    $withContextRecord: Boolean!
  ) {
    requestRecords(input: $input) {
      nodes {
        _id
        name
        complianceStats {
          compliantSubjects
          totalSubjects
        }
        complianceProfile {
          _id
          name
          baseComplianceProfile
        }
        contextRecord @include(if: $withContextRecord) {
          _id
          name
        }
        primaryRecord @include(if: $withPrimaryRecord) {
          _id
          name
        }
      }
      totalCount
    }
  }
`);

export const useRequests = ({
  recordId,
  recordType,
}: {
  recordId: string;
  recordType: 'context' | 'primary';
}) => {
  const getRequestsQuery = useCustomLazyQuery(REQUESTS_QUERY);
  const filterId = DYNAMIC_FIELDS_BY_RECORD_TYPE[recordType].idField;

  const getRequestsList: GetRowData = useCallback(
    async ({ startRow, endRow, filters }) => {
      const serverFilters = mapTableFiltersToServerFilters({
        tableFilters: filters,
        serverFiltersConfig: SERVER_FILTERS_CONFIG,
      });

      const { data } = await getRequestsQuery({
        variables: {
          withPrimaryRecord: recordType === 'context',
          withContextRecord: recordType === 'primary',
          input: {
            offset: startRow,
            first: endRow - startRow,
            filter: {
              and: [
                {
                  name: filterId,
                  operator: FILTER_OPERATORS.equal,
                  value: recordId,
                },
                ...(serverFilters?.and || []),
              ],
            },
          },
        },
      });

      return {
        rowData: data?.requestRecords.nodes || [],
        rowCount: data?.requestRecords.totalCount || 0,
      };
    },
    [getRequestsQuery, recordType, filterId, recordId],
  );

  return {
    getRequestsList,
  };
};
