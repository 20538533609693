import { SURVEY_MODULE_ID } from '@modules/surveys/constants';
import {
  RequirementAttributeType,
  type RequirementOperator,
} from '@trustlayer/common';

export const getOperatorDisplayValue = (requirement: {
  moduleCode: string;
  attributeType: string;
  operator: RequirementOperator;
}) => {
  if (requirement.moduleCode === SURVEY_MODULE_ID) return 'must be completed';
  if (requirement.attributeType === RequirementAttributeType.ai) return '';

  return requirement.operator;
};
