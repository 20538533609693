import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { type ReactNode, useState } from 'react';
import styled from 'styled-components';
import ChecklistItemForm from './ChecklistItemForm';

type DocumentChecklistsProps = {
  title: string;
  onNewItem(attributeLabel: string): void;
  children?: ReactNode;
  itemsCount: number;
};

const DocumentChecklists = ({
  title,
  onNewItem,
  children,
  itemsCount,
}: DocumentChecklistsProps) => {
  const [isShowForm, setIsShowForm] = useState(false);

  const handleAddDocumentChecklist = (value: string) => {
    onNewItem(value);
    setIsShowForm(false);
  };

  return (
    <StyledWrapper $hasItems={!!itemsCount} data-cy="documentChecklists">
      <StyledTitle>{title}</StyledTitle>
      <div>{children}</div>
      {isShowForm ? (
        <ChecklistItemForm
          submitButtonLabel="Add"
          onSubmit={handleAddDocumentChecklist}
          onClose={() => setIsShowForm(false)}
        />
      ) : (
        <StyledAddNewItem>
          <Button
            data-cy="newChecklistItemButton"
            type="link"
            icon={<PlusOutlined />}
            onClick={() => setIsShowForm(true)}
          >
            New item
          </Button>
        </StyledAddNewItem>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{ $hasItems: boolean }>`
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background-color: ${({ theme, $hasItems }) =>
    !$hasItems ? theme.colors.white : theme.colors.blueGray};
`;

const StyledTitle = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.grayishBlue};
`;

const StyledAddNewItem = styled.div`
  margin: 5px 0;

  button {
    padding: 0;
  }
`;

export default DocumentChecklists;
