import type { ProjectComplianceProfileResult } from '@graphql/types/graphql';

export function preserveActiveStatus(
  prev: ProjectComplianceProfileResult[],
  current: ProjectComplianceProfileResult[],
) {
  return current.map((profile) => {
    const prevProfile = prev.find((el) => el._id === profile._id);

    if (!prevProfile) return profile;

    const prevProfileRules = new Map(
      prevProfile.rules.map((rule) => [rule.attributeId, rule]),
    );
    const prevProfileDocumentChecklists = new Map(
      prevProfile.documentChecklists.map((checklist) => [
        checklist.attributeId,
        checklist,
      ]),
    );
    const clonedProfile = {
      ...profile,
    };
    // Preserve isActive status for rules that exist in both profiles
    clonedProfile.rules = clonedProfile.rules.map((newRule) => {
      const existingRule = prevProfileRules.get(newRule.attributeId);

      return existingRule
        ? { ...newRule, isActive: existingRule.isActive }
        : newRule;
    });
    // Preserve isActive status for documentChecklists that exist in both profiles
    clonedProfile.documentChecklists = clonedProfile.documentChecklists.map(
      (newChecklist) => {
        const existingChecklist = prevProfileDocumentChecklists.get(
          newChecklist.attributeId,
        );
        return existingChecklist
          ? {
              ...newChecklist,
              isActive: existingChecklist.isActive,
            }
          : newChecklist;
      },
    );

    return clonedProfile;
  });
}
