import { MinusCircleOutlined } from '@ant-design/icons';
import { Col, InputNumber, Row, Select } from 'antd';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import styled from 'styled-components';

import type { CustomField } from '@graphql/types/graphql';
import CustomFieldTypeName from '@modules/custom-field/components/CustomFieldTypeName';
import { CustomFieldType } from '@modules/custom-field/constants';
import { getOrganizationNamespaceUrl } from '@modules/organization/selectors';
import ProjectTextField from './ProjectTextField';

type SelectedCustomField = {
  customField: CustomField;
  value: string | number | null;
};

interface CustomFieldsTabProps {
  customFields: CustomField[];
  selectedCustomFields: SelectedCustomField[];
  onSetSelectedCustomFields: (
    selectedCustomFields: SelectedCustomField[],
  ) => void;
}

const CustomFieldsTab = ({
  customFields,
  selectedCustomFields,
  onSetSelectedCustomFields,
}: CustomFieldsTabProps) => {
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const debouncedSetSelectedCustomFields = useMemo(
    () => debounce(onSetSelectedCustomFields, 100),
    [onSetSelectedCustomFields],
  );

  const addCustomField = (customFieldId: string) => {
    const customField = customFields.find((c) => c._id === customFieldId);

    if (!customField) return;

    onSetSelectedCustomFields([
      ...selectedCustomFields,
      {
        customField,
        value: '',
      },
    ]);
  };
  const updateCustomField = (customFieldId: string, index: number) => {
    const customField = customFields.find((c) => c._id === customFieldId);

    if (!customField) return;

    onSetSelectedCustomFields(
      selectedCustomFields.map((x, currentIndex) =>
        currentIndex === index
          ? {
              customField,
              value: customField.options?.[0]?.key ?? '',
            }
          : x,
      ),
    );
  };
  const removeCustomField = (index: number) => {
    onSetSelectedCustomFields(
      selectedCustomFields.filter((_, currentIndex) => currentIndex !== index),
    );
  };

  const updateValue = (value: string | number | null, index: number) => {
    debouncedSetSelectedCustomFields(
      selectedCustomFields.map((x, currentIndex) =>
        currentIndex === index
          ? {
              customField: x.customField,
              value,
            }
          : x,
      ),
    );
  };

  const availableToSelectCustomField = (availableCustomFieldId?: string) => {
    if (!customFields || !Array.isArray(customFields)) return [];

    return customFields.filter(
      (customField) =>
        availableCustomFieldId === customField._id ||
        !selectedCustomFields.some(
          (selectedCustomField) =>
            selectedCustomField.customField._id === customField._id,
        ),
    );
  };

  return (
    <StyledWrapper data-cy="customFieldsTab">
      <StyledHelpText>
        <p>
          Use{' '}
          <Link to={`${organizationNamespace}/settings/projects`}>
            custom fields
          </Link>{' '}
          to specify additional information on this project. Requirements
          associated to custom fields will be combined with the ones from the
          compliance profiles in use in this project.
        </p>
      </StyledHelpText>
      {selectedCustomFields.map(({ customField, value }, index) => (
        <Row key={customField._id} gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={8}>
            <StyledSelect
              data-cy="changeCustomField"
              value={customField._id}
              onSelect={(value: string) => updateCustomField(value, index)}
            >
              {availableToSelectCustomField(customField._id).map(
                (customField) => (
                  <Select.Option key={customField._id} value={customField._id}>
                    <CustomFieldTypeName
                      type={customField.type}
                      title={customField.name}
                    />
                  </Select.Option>
                ),
              )}
            </StyledSelect>
          </Col>
          <Col span={14}>
            {customField.type === CustomFieldType.Dropdown && (
              <StyledSelect
                placeholder="Select..."
                optionFilterProp="children"
                value={value || []}
                onSelect={(value: string | number) => updateValue(value, index)}
              >
                {customField.options?.map((option) => (
                  <Select.Option key={option.key} value={option.key}>
                    {option.value}
                  </Select.Option>
                ))}
              </StyledSelect>
            )}
            {customField.type === CustomFieldType.Text && (
              <ProjectTextField
                customFieldName={customField.name}
                value={value as string}
                updateValue={(value: string) => updateValue(value, index)}
              />
            )}
            {customField.type === CustomFieldType.Number && (
              <InputNumber
                placeholder={`Enter ${customField.name}...`}
                style={{ width: '100%' }}
                onChange={(value: number | null) => updateValue(value, index)}
                value={value as number}
                controls={false}
              />
            )}
          </Col>
          <Col span={1}>
            <StyledRemoveIcon
              data-cy="removeCustomFieldButton"
              onClick={() => removeCustomField(index)}
            />
          </Col>
        </Row>
      ))}{' '}
      {availableToSelectCustomField().length > 0 && (
        <Row gutter={16}>
          <Col span={8}>
            <StyledSelect
              data-cy="selectCustomField"
              showSearch
              placeholder="Select a custom field..."
              onSelect={(value: string) => addCustomField(value)}
              value={[]}
              filterOption={(search, option) => {
                return (
                  // @ts-expect-error - Directly accessing the React node
                  (option?.children?.props.title ?? '')
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) >= 0
                );
              }}
            >
              {availableToSelectCustomField().map((customField) => (
                <Select.Option key={customField._id} value={customField._id}>
                  <CustomFieldTypeName
                    type={customField.type}
                    title={customField.name}
                  />
                </Select.Option>
              ))}
            </StyledSelect>{' '}
          </Col>
        </Row>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;

  > .ant-row {
    align-items: baseline;
  }
`;

const StyledHelpText = styled.div`
  font-size: 13px;
  margin-bottom: 30px;
`;

const StyledRemoveIcon = styled(MinusCircleOutlined)`
  font-size: 20px;

  :hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin: 0 0 10px;
`;

export default CustomFieldsTab;
