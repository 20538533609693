import * as R from 'ramda';

import { AttributeType, Operator } from '@modules/compliance-profile/constants';
import { FILLABLE_FORMS_MODULE_ID } from '@modules/fillable-form/constants';
import { SURVEY_MODULE_ID } from '@modules/surveys/constants';

/**
 * Get active modules from any entity.
 */
export const getUniqModules = (data = []) =>
  R.compose(
    R.uniq,
    R.map(R.pick(['moduleId', 'moduleLabel', 'isActive'])),
  )(data);

/**
 * Return uniq subjects.
 */
export const getUniqSubjects = (data = []) =>
  R.compose(R.uniq, R.map(R.pick(['subjectId', 'subjectLabel'])))(data);

/**
 * Get subjects by moduleId.
 */
export const getSubjectsByModule = (
  moduleId,
  requirements = [],
  templates = [],
) => {
  const subjectsByProfile = getSubjects(moduleId, templates);
  const subjectsByRequirements = getSubjects(moduleId, requirements);

  const uniqueSubjects = R.compose(
    R.uniq,
    R.map(R.pick(['subjectId', 'subjectLabel'])),
    R.concat(subjectsByRequirements),
  )(subjectsByProfile);

  return R.values(uniqueSubjects);
};

export const getSubjects = (moduleId, list = []) => {
  return R.compose(
    getUniqSubjects,
    R.filter(R.propEq('moduleId', moduleId)),
  )(list);
};

export const getSubjectsByModuleDividedByStatus = (
  moduleId,
  requirements = [],
  templates = [],
) => {
  const subjectsByTemplates = getSubjects(moduleId, templates);
  const subjectsByRequirements = getSubjects(moduleId, requirements);

  const inactiveSubjects = R.compose(
    R.filter(
      (subject) =>
        !R.find(
          R.propEq('subjectId', subject.subjectId),
          subjectsByRequirements,
        ),
    ),
  )(subjectsByTemplates);

  return {
    inactiveSubjects,
    activeSubjects: subjectsByRequirements,
  };
};

/**
 * Get active modules.
 */
export const SYSTEM_MODULES = [FILLABLE_FORMS_MODULE_ID, SURVEY_MODULE_ID];

export const getModulesData = (requirements, templates = []) => {
  const modulesByTemplates = getUniqModules(templates);
  const modulesByRequirements = getUniqModules(requirements);
  const uniqueModules = R.mergeRight(
    R.indexBy(R.prop('moduleId'), modulesByTemplates),
    R.indexBy(R.prop('moduleId'), modulesByRequirements),
  );

  const data = R.compose(
    R.sort((a, b) => (a.moduleLabel < b.moduleLabel ? -1 : 1)),
    R.map((x) => {
      x.isActive = modulesByRequirements.some(
        (moduleByRequirement) => moduleByRequirement.moduleId === x.moduleId,
      );
      return x;
    }),
    R.values,
  )(uniqueModules);

  const complianceModules = data.filter(
    ({ moduleId }) => !SYSTEM_MODULES.includes(moduleId),
  );
  const formModules = data.filter(({ moduleId }) =>
    SYSTEM_MODULES.includes(moduleId),
  );

  return {
    complianceModules,
    formModules,
  };
};

/**
 * Get requirements by module subject.
 */
export const getRequirementsByModuleSubject = (
  moduleId,
  subjectId,
  requirements,
) =>
  R.compose(
    R.filter(R.propEq('subjectId', subjectId)),
    R.filter(R.propEq('moduleId', moduleId)),
    R.filter((x) => Boolean(x.subjectId) && Boolean(x.moduleId)),
  )(requirements);

/**
 * Get attribute by attributeId.
 */
export const getAttributeById = (
  attributeId,
  operator,
  requirements = [],
  templates = [],
) => {
  const findAttribute = R.find(R.propEq('attributeId', attributeId));
  const attribute = findAttribute(templates) || findAttribute(requirements);

  //? Since a requirement which can be of either types boolean or number exists in templates just in the form RequirementAttributeType.number,
  //? we need to hardcode its attributeType in case the user has choosen Operator.PRESENT
  const attributeType =
    operator === Operator.PRESENT &&
    attribute.attributeType === AttributeType.Number
      ? AttributeType.Boolean
      : attribute.attributeType;

  return {
    attributeId: attribute.attributeId,
    attributeLabel: attribute.attributeLabel,
    attributeDescription: attribute.attributeDescription,
    attributeType,
    masterDocumentAttributeId: attribute.masterDocumentAttributeId,
    invalidatedBy: attribute.invalidatedBy,
    invalidationScope: attribute.invalidationScope,
  };
};

/**
 * Get is custom subject.
 */
export const getIsCustomSubject = (subjectId, moduleId, ruleDefinitions) =>
  R.compose(
    (subject) => !Boolean(subject),
    R.find(R.propEq('subjectId', subjectId)),
  )(ruleDefinitions) && getIsCustomModule(moduleId, ruleDefinitions);

/**
 * Get is custom module.
 */
export const getIsCustomModule = (moduleId, ruleDefinitions) =>
  R.compose(
    (module) => !Boolean(module),
    R.find(R.propEq('moduleId', moduleId)),
  )(ruleDefinitions);

export const getIsFillableFormModule = (requirement) =>
  requirement?.moduleId === FILLABLE_FORMS_MODULE_ID;

export const getIsSurveyModule = (requirement) =>
  requirement?.moduleId === SURVEY_MODULE_ID;

/**
 * Get requirements by compliance profile rules.
 */
export const getRequirementsByRules = (rules) => {
  const validRules = rules.filter((rule) => Boolean(rule.attributeId));
  const uniqueModules = getUniqModules(validRules);

  return uniqueModules.reduce((requirements, moduleData) => {
    const moduleSubjects = getSubjectsByModule(moduleData.moduleId, validRules);
    const subjectRequirements = moduleSubjects.map((subjectData) =>
      getRequirementsByModuleSubject(
        moduleData.moduleId,
        subjectData.subjectId,
        validRules,
      ),
    );

    return [...requirements, ...R.flatten(subjectRequirements)];
  }, []);
};

/**
 * Group list by key into a multidimensional array
 */
const groupByKey = (list, key) => {
  return list.reduce((acc, e) => {
    if (acc.some((x) => x.id === e[key])) {
      return acc.map((y) =>
        y.id === e[key] ? { ...y, children: [...y.children, e] } : y,
      );
    }

    return [...acc, { id: e[key], children: [e] }];
  }, []);
};

/**
 * Group sorted Rules by Module id and Subject Id
 */
export const groupRules = (rules) => {
  const groupedByModule = groupByKey(rules, 'moduleId');
  return groupedByModule.map((mod) => ({
    ...mod,
    children: groupByKey(mod.children, 'subjectId'),
  }));
};

/**
 * Flat grouped rules
 */
export const flatGroupedRules = (groupedRules) =>
  groupedRules.flatMap((e) => (e.children ? flatGroupedRules(e.children) : e));

/**
 * @deprecated Use getRuleLabelForCompletedByOperator on v2
 */
export const getLabelCompletedBy = ({ targetValue, partyName, orgName }) => {
  return targetValue === 'party'
    ? partyName || 'Party'
    : orgName || 'Requester (Me)';
};

/**
 * Return requirements with isAutomaticallyWaived flag
 * @param {Array<{attributeId: string}>} requirements
 * @param {Array<{attributeId: string}>} automaticallyWaivedAttributes
 */
export const getAutomaticallyWaivedOverridenAcrossProjects = (
  requirements,
  automaticallyWaivedAttributes,
) => {
  const automaticallyWaivedAttributesIds = new Set(
    automaticallyWaivedAttributes.map((a) => a.attributeId),
  );

  return requirements.map((r) => {
    return {
      ...r,
      isAutomaticallyWaivedAcrossProjects: automaticallyWaivedAttributesIds.has(
        r.attributeId,
      ),
    };
  });
};

export * from './getOperatorDisplayValue';
export * from './getRuleLabelForCompletedByOperator';
