import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router';

import usePrevious from '@common/hooks/usePrevious';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { clearPush } from '../actions';
import { getLocationPush } from '../selectors';

export default function LocationListener() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const nextLocation = useAppSelector(getLocationPush, shallowEqual);
  const prevLocation = usePrevious(nextLocation) as any;

  useEffect(() => {
    if (
      !nextLocation ||
      nextLocation?.to === null ||
      (prevLocation && nextLocation && nextLocation.to === prevLocation.to)
    ) {
      return;
    }

    navigate(nextLocation.to);

    dispatch(clearPush());
  }, [nextLocation, prevLocation, navigate, dispatch]);

  return null;
}
