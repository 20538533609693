import { FILTER_OPERATORS } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

const UNASSIGN_COMPLIANCE_PROFILE_MUTATION = graphql(`
  mutation UnassignComplianceProfileFromRequestRecord($data: UnassignComplianceProfileFromInput!) {
    unassignComplianceProfileFrom(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useUnassignComplianceProfileMutation = () => {
  const [unassignComplianceProfileMutation, { loading }] = useMutation(
    UNASSIGN_COMPLIANCE_PROFILE_MUTATION,
  );

  const unassignComplianceProfile = useCallback(
    (requestRecordId: string) =>
      unassignComplianceProfileMutation({
        variables: {
          data: {
            filter: {
              and: [
                {
                  name: 'id',
                  operator: FILTER_OPERATORS.in,
                  value: [requestRecordId],
                },
              ],
            },
          },
        },
      }),
    [unassignComplianceProfileMutation],
  );

  return {
    unassignComplianceProfile,
    isUnassignComplianceProfileLoading: loading,
  };
};
