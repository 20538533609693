import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalConfirm } from '@common/components/ModalConfirm';
import { BulkOperationStatus } from '@graphql/types/graphql';
import { Typography, message } from 'antd';
import { useUnassignComplianceProfileMutation } from '../../hooks/useUnassignComplianceProfileMutation';

type UnassignComplianceProfileModalProps = {
  open: boolean;
  requestRecordId: string;
  onCancel?: () => void;
  onCompleted?: () => void;
};

export const UnassignComplianceProfileModal = ({
  open,
  requestRecordId,
  onCancel,
  onCompleted,
}: UnassignComplianceProfileModalProps) => {
  const { unassignComplianceProfile, isUnassignComplianceProfileLoading } =
    useUnassignComplianceProfileMutation();

  const handleUnassign = async () => {
    if (!requestRecordId) return;

    const res = await unassignComplianceProfile(requestRecordId);

    const status = res.data?.unassignComplianceProfileFrom.operation.status;

    if (status === BulkOperationStatus.Completed) {
      message.success('Compliance profile unassigned successfully.');
      onCompleted?.();
    }

    if (status === BulkOperationStatus.Failed) {
      message.error(
        'The unassign of the compliance profile has failed. Please try again or contact support.',
      );
    }
  };

  return (
    <ModalConfirm
      data-cy="unassign-compliance-profile-modal"
      open={open}
      icon={<ExclamationCircleOutlined />}
      okButtonProps={{
        danger: true,
        loading: isUnassignComplianceProfileLoading,
      }}
      okText="Unassign"
      title={`Are you sure you want to unassign the compliance profile?`}
      onCancel={onCancel}
      onOk={handleUnassign}
    >
      <Typography.Paragraph>
        Unassigning this compliance profile will remove these requirements
      </Typography.Paragraph>
    </ModalConfirm>
  );
};
