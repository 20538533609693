import Icon, {
  ArrowLeftOutlined,
  DownOutlined,
  FolderTwoTone,
  SettingOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router';
import styled from 'styled-components';

import Disable from '@common/components/Disable';
import PillLabel from '@common/components/PillLabel';
import BuildingIcon from '@common/icons/BuildingIcon';
import { getIsAuthenticated } from '@modules/auth/selectors';
import {
  useTestFeatureFlag,
  useTrustLayerV2FeatureFlag,
} from '@modules/feature-flags/hooks';
import CurrentProjectHeader from '@modules/navigation/components/CurrentProjectHeader';
import ProjectSwitcher from '@modules/navigation/containers/ProjectSwitcher';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import {
  getActiveOrganizationData,
  getOrganizationNamespaceUrl,
} from '@modules/organization/selectors';
import SaveProjectModal from '@modules/project/modals/SaveProjectModal';
import {
  getCurrentProject,
  getProjectIsLoading,
} from '@modules/project/selectors';
import { getEmail } from '@modules/user/selectors';

type Project = {
  _id: string;
  name: string;
  isActive: boolean;
};

export const Header = () => {
  const [projectIdToEdit, setProjectIdToEdit] = useState<string | undefined>();
  const [visibleProjectModal, setVisibleProjectModal] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const { isTestFeatureFlagEnabled } = useTestFeatureFlag();

  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();

  const projectIsLoading = useSelector(getProjectIsLoading);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { pathname } = useLocation();

  const showProjectInfo = !pathname.match(/dashboard|settings|reports/);

  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const userEmail = useSelector(getEmail);
  const activeOrganization = useSelector(getActiveOrganizationData);
  const sotredProject = useSelector(getCurrentProject) as Project | undefined;

  const currentProject = isEmpty(sotredProject) ? undefined : sotredProject;

  const openSaveProjectModal = (projectId?: string) => {
    setProjectIdToEdit(projectId);
    setVisibleProjectModal(true);
  };

  if (!userEmail || !isAuthenticated) {
    return null;
  }

  const isProjectContentFocused = visiblePopover;

  return (
    <>
      <SaveProjectModal
        projectId={projectIdToEdit}
        visible={visibleProjectModal}
        onClose={() => setVisibleProjectModal(false)}
        onSuccess={() => setVisibleProjectModal(false)}
      />
      <StyledStickyContainer>
        {!isTlV2FeatureFlagEnabled && (
          <>
            <StyledHeaderWrapper>
              {showProjectInfo ? (
                <StyledProjectInfoContainer>
                  {activeOrganization && (
                    <>
                      {currentProject && !pathname.match(/projects/) && (
                        <Tooltip title="Back to projects">
                          <StyledHeaderLink
                            to={`${organizationNamespace}/projects`}
                          >
                            <ArrowLeftOutlined />
                          </StyledHeaderLink>
                        </Tooltip>
                      )}
                      <Disable isDisabled={false}>
                        <ProjectSwitcher
                          openSaveProjectModal={openSaveProjectModal}
                          visible={visiblePopover && !projectIsLoading}
                          setVisible={setVisiblePopover}
                        >
                          <StyledProjectContent
                            $focused={isProjectContentFocused}
                          >
                            {projectIsLoading ? (
                              'Loading...'
                            ) : (
                              <>
                                {!currentProject ? (
                                  <Icon component={BuildingIcon} />
                                ) : (
                                  <FolderTwoTone />
                                )}
                                <StyledProjectName data-cy="headerCurrentProjectName">
                                  {currentProject?.name ||
                                    activeOrganization?.name}
                                </StyledProjectName>
                                {currentProject &&
                                  !currentProject?.isActive && (
                                    <StyledPillLabel
                                      type="default"
                                      label="inactive"
                                    />
                                  )}
                              </>
                            )}
                            <StyledHeaderSorter>
                              <UpOutlined />
                              <DownOutlined />
                            </StyledHeaderSorter>
                          </StyledProjectContent>
                        </ProjectSwitcher>
                      </Disable>
                      <HideForViewerRole>
                        {currentProject ? (
                          <Disable isDisabled={false}>
                            <Tooltip title="Project settings">
                              <StyledHeaderLink
                                to="#"
                                data-cy="headerEditCurrentProject"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openSaveProjectModal(currentProject?._id);
                                }}
                              >
                                <SettingOutlined />
                              </StyledHeaderLink>
                            </Tooltip>
                          </Disable>
                        ) : null}
                      </HideForViewerRole>
                    </>
                  )}
                </StyledProjectInfoContainer>
              ) : (
                <StyledCurrentOrganization>
                  <BuildingIcon />
                  <h3>{activeOrganization?.name}</h3>
                </StyledCurrentOrganization>
              )}
            </StyledHeaderWrapper>
            {showProjectInfo && currentProject && (
              <CurrentProjectHeader currentProject={currentProject} />
            )}
          </>
        )}
      </StyledStickyContainer>
      {isTestFeatureFlagEnabled && (
        <TestFeatureFlag>🚩 Test feature flag active</TestFeatureFlag>
      )}
    </>
  );
};

const TestFeatureFlag = styled.div`
  padding: 5px 10px;
  font-size: 12px;
  text-align: center;
  color: #835500;
  background-color: #ffd700;
`;

const StyledStickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
`;

const StyledHeaderWrapper = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  padding: 0 24px;
  border-bottom: 1px solid rgba(195, 209, 226, 0.8);
  outline: 2px solid rgba(80, 119, 167, 0.04);
`;

const StyledCurrentOrganization = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;

  h3 {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 10px;
    color: rgba(0, 0, 0, 0.85);
  }
`;

const StyledHeaderLink = styled(Link)`
  padding: 5px 10px 3px;
  color: #555;

  > .anticon {
    font-size: 16px;
  }
`;

const StyledProjectInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledProjectName = styled.div`
  margin-left: 10px;
  margin-right: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;

  + .anticon-setting:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const StyledProjectContent = styled.div<{ $focused: boolean }>`
  height: 32px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-right: 1px solid #e6eaef;
  border: 1px solid ${(props) => (props.$focused ? '#cdd9e7' : '#f0f0f0')};
  border-radius: 4px;
  background-color: ${(props) => (props.$focused ? '#f4f8fd' : 'transparent')};
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.04);

  &:hover {
    background-color: #f4f8fd;
    border: 1px solid #cdd9e7;
  }

  > .anticon-folder {
    font-size: 19px;
    color: #333;
  }
`;

const StyledHeaderSorter = styled.div`
  margin: 0 10px 0 15px;
  display: flex;
  flex-direction: column;
  font-size: 8px;
`;

const StyledPillLabel = styled(PillLabel)`
  margin-left: 5px;
  margin-top: 2px;
`;
