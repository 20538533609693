import { Skeleton } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { LoadMoreButton } from '@common/components/LoadMoreButton';
import { SidebarLoadingError } from 'layouts';

import { SidebarSubtitle } from '@modules/primary-records/components/SidebarSubtitle';
import { useEmailActivities } from './hooks';

export const SidebarEmailActivities = ({ recordId }: { recordId: string }) => {
  const {
    emailActivities,
    totalCount,
    fetchMore,
    isEmailActivitiesLoading,
    isEmailActivitiesLoadingMore,
    error,
    refetch,
  } = useEmailActivities(recordId);

  if (error) {
    return (
      <SidebarLoadingError
        loadingActionLabel="Email Activities"
        onActionClick={() => refetch()}
      />
    );
  }

  if (!isEmailActivitiesLoading && totalCount === 0) {
    return <p>No recent activities</p>;
  }

  return (
    <>
      <Skeleton active loading={isEmailActivitiesLoading}>
        <SidebarSubtitle>Latest activity</SidebarSubtitle>
        {emailActivities?.map((activity) => (
          <StyledActivity key={activity._id}>
            Sent {moment(activity.createdAt).format('MMM DD, YYYY')} by{' '}
            {activity.data?.userName}
          </StyledActivity>
        ))}
        {emailActivities && emailActivities.length < totalCount && (
          <LoadMoreButton
            type="link"
            loading={isEmailActivitiesLoadingMore}
            onClick={() => fetchMore()}
          >
            View more
          </LoadMoreButton>
        )}
      </Skeleton>
    </>
  );
};

const StyledActivity = styled.p`
  margin: 0 0 8px;
  padding: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.mineShaft};
`;
