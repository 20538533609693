import { Skeleton } from 'antd';
import { SidebarLoadingError } from 'layouts';

import { AttributesGrid } from '@modules/attributes/components/AttributesGrid';
import { CONTEXT_RECORDS_TABS } from '@modules/context-records/constants';
import { Link } from 'react-router';
import styled from 'styled-components';
import { useAttributesSidebar } from './hooks/useAttributesSidebar';

export const AttributesSidebar = ({
  contextRecordId,
}: { contextRecordId: string }) => {
  const {
    attributes,
    loading,
    error,
    refetchAttributes,
    attributesTotalCount,
  } = useAttributesSidebar({
    contextRecordId,
  });

  if (error) {
    return (
      <SidebarLoadingError
        loadingActionLabel="Attributes"
        onActionClick={() => refetchAttributes()}
      />
    );
  }

  return (
    <Skeleton active loading={loading}>
      <AttributesGrid attributes={attributes} />
      {attributes.length < attributesTotalCount && (
        <StyledLink to={`./${CONTEXT_RECORDS_TABS.Attributes.key}`}>
          Show more
        </StyledLink>
      )}
    </Skeleton>
  );
};

const StyledLink = styled(Link)`
  display: block;
  margin-top: 10px;
`;
