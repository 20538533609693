import { useStateWithDebouncedCallback } from '@common/hooks';
import { Input } from 'antd';

export function DocumentRequirementIdInput({
  initialValue,
  onChange,
}: {
  initialValue?: string;
  onChange: (value: string | null) => void;
}) {
  const [value, setValue] = useStateWithDebouncedCallback(onChange, {
    initialValue: initialValue,
    delay: 250,
  });

  return (
    <Input
      data-cy="filterRequirementIdInput"
      allowClear
      placeholder="Enter requirement ID..."
      value={value ?? ''}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
