import { Input } from 'antd';
import type { SearchProps } from 'antd/es/input';

const DEFAULT_PLACEHOLDER = 'Find by name';

type SearchInputProps = {
  placeholder?: string;
  allowClear?: boolean;
  defaultValue?: SearchProps['defaultValue'];
  value?: SearchProps['value'];
  loading?: boolean;
  onSearch?: SearchProps['onSearch'];
  onChange?: SearchProps['onChange'];
  className?: string;
};

const SearchInput = ({
  placeholder = DEFAULT_PLACEHOLDER,
  allowClear = true,
  loading,
  defaultValue,
  value,
  onSearch,
  onChange,
  className,
}: SearchInputProps) => (
  <Input.Search
    data-cy="searchByNameInput"
    loading={loading}
    value={value}
    defaultValue={defaultValue}
    allowClear={allowClear}
    placeholder={placeholder}
    onSearch={onSearch}
    onChange={onChange}
    className={className}
  />
);

export default SearchInput;
