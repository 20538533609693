import qs from 'query-string';
import { Navigate, Outlet, useLocation } from 'react-router';

import { APPLICATION_PATH } from '@modules/router/constants';

import { useAppSelector } from '@store/hooks';
import { getIsAuthenticated } from '../selectors';

const AuthenticationGuard = () => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const { pathname, search } = useLocation();

  if (!isAuthenticated) {
    const redirectQsParams = qs.stringify({
      // Saving the destination url in redirect_url
      ...(pathname !== APPLICATION_PATH.root && {
        redirect_url: pathname + search,
      }),
    });
    return <Navigate to={`/login?${redirectQsParams}`} />;
  }

  return <Outlet />;
};

export default AuthenticationGuard;
