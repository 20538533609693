import { CloseCircleFilled } from '@ant-design/icons';
import Flex from '@common/components/Flex';
import { Modal } from '@common/components/Modal';
import { BulkOperationStatus, type Contact } from '@graphql/types/graphql';
import { ContactsInput } from '@modules/contacts/components/ContactsInput';
import { useCreatePrimaryRecordContact } from '@modules/contacts/hooks/useCreatePrimaryRecordContact';
import { useUpdatePrimaryRecordContact } from '@modules/contacts/hooks/useUpdatePrimaryRecordContact';
import { usePrimaryRecordName } from '@modules/primary-records';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Upload,
  type UploadProps,
  message,
} from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useAssignContact } from '../../hooks/useAssignContact';
import { useUploadAvatar } from '../ContactForm/hooks/useUploadAvatar';

type AddContactModalProps = {
  primaryRecordId: string;
  open: boolean;
  onClose: () => void;
  onCompleted?: () => void;
  onScheduled?: () => void;
};

const INVALID_EMAIL_MESSAGE = 'Please enter a valid email address';

type ContactFormValues = Contact & {
  defaultRequestRecipient?: boolean;
  primary?: boolean;
};

enum ContactFormMode {
  Create = 'create',
  Update = 'update',
  Assign = 'assign',
}

export const AddContactModal = ({
  primaryRecordId,
  open,
  onClose,
  onCompleted,
  onScheduled,
}: AddContactModalProps) => {
  const [form] = Form.useForm<ContactFormValues>();
  const avatarImgUrl = Form.useWatch('avatar', form);
  const email = Form.useWatch('email', form);
  const [formMode, setFormMode] = useState<ContactFormMode>(
    ContactFormMode.Create,
  );
  const { name: primaryRecordName } = usePrimaryRecordName({
    primaryRecordId,
  });
  const { uploadAvatar, loading } = useUploadAvatar();
  const {
    createPrimaryRecordContact,
    loading: createPrimaryRecordContactLoading,
  } = useCreatePrimaryRecordContact({ primaryRecordId });
  const {
    updatePrimaryRecordContact,
    loading: updatePrimaryRecordContactLoading,
  } = useUpdatePrimaryRecordContact({
    primaryRecordId,
  });
  const { assignContact, loading: assignContactLoading } = useAssignContact({
    primaryRecordId,
  });

  const handleOnClear = () => {
    form.resetFields();
    form.setFieldValue('avatar', null);
    setFormMode(ContactFormMode.Create);
  };

  const handleCancel = () => {
    handleOnClear();
    setFormMode(ContactFormMode.Create);
    onClose?.();
  };

  const handleUpdateContact = async (formValues: ContactFormValues) => {
    try {
      const response = await updatePrimaryRecordContact({
        contact: {
          _id: formValues._id,
          contactPersonName: formValues.contactPersonName ?? undefined,
          email: formValues.email ?? '',
          companyName: formValues.companyName ?? undefined,
          phone: formValues.phone ?? undefined,
          fax: formValues.fax ?? undefined,
          title: formValues.title ?? undefined,
          avatar: formValues.avatar || undefined, //? this is *||* instead of *??* for support v1 data
        },
        isDefaultRequestRecipient: formValues.defaultRequestRecipient ?? false,
        isPrimary: formValues.primary ?? false,
      });

      return response.data?.updateContacts.operation.status;
    } catch {
      return null;
    }
  };

  const handleCreateContact = async (formValues: ContactFormValues) => {
    try {
      const response = await createPrimaryRecordContact({
        contact: {
          contactPersonName: formValues.contactPersonName ?? undefined,
          email: formValues.email ?? '',
          companyName: formValues.companyName ?? undefined,
          phone: formValues.phone ?? undefined,
          fax: formValues.fax ?? undefined,
          title: formValues.title ?? undefined,
          avatar: formValues.avatar === null ? undefined : formValues.avatar,
        },
        isDefaultRequestRecipient: formValues.defaultRequestRecipient ?? false,
        isPrimary: formValues.primary ?? false,
      });

      return response.data?.createContact;
    } catch {
      return null;
    }
  };

  const handleAssignContact = async (formValues: ContactFormValues) => {
    try {
      const response = await assignContact(formValues._id);
      return response.data?.assignContactsToPrimaryRecord.operation.status;
    } catch {
      return null;
    }
  };

  const handleSubmit = async () => {
    try {
      const isFormValid = await form.validateFields();
      if (!isFormValid) return;

      const formValues = form.getFieldsValue();
      let status: BulkOperationStatus | boolean | undefined | null;

      if (formValues._id && formMode === ContactFormMode.Assign) {
        status = await handleAssignContact(formValues);
      } else if (formValues._id && formMode === ContactFormMode.Update) {
        status = await handleUpdateContact(formValues);
      } else {
        status = await handleCreateContact(formValues);
      }

      const isSyncOperation =
        status === BulkOperationStatus.Completed ||
        (typeof status === 'boolean' && !status);

      if (isSyncOperation) {
        message.success(`Contact added successfully.`);
        onCompleted?.();
        handleOnClear();
      }

      const isScheduledOperation =
        status === BulkOperationStatus.Scheduled ||
        (typeof status === 'boolean' && status);

      if (isScheduledOperation) {
        message.success(`Contact addition has been scheduled.`);
        onScheduled?.();
      }

      if (isSyncOperation || isScheduledOperation) {
        handleOnClear();
      }

      if (status === BulkOperationStatus.Failed) {
        message.error(
          `Failed to add the contact. Please try again or contact support.`,
        );
      }
    } catch {}
  };

  const handleOnSelect = (contact: ContactFormValues) => {
    setFormMode(ContactFormMode.Assign);
    form.setFieldsValue({
      _id: contact._id,
      email: contact.email ?? undefined,
      contactPersonName: contact.contactPersonName ?? undefined,
      companyName: contact.companyName ?? undefined,
      title: contact.title ?? undefined,
      phone: contact.phone ?? undefined,
      fax: contact.fax ?? undefined,
      avatar: contact.avatar ?? undefined,
    });

    // Explicitly set checkbox values since Form.setFieldsValue may not trigger for checkboxes
    form.setFields([
      {
        name: 'defaultRequestRecipient',
        value: contact.defaultRequestRecipient ?? false,
      },
      {
        name: 'primary',
        value: contact.primary ?? false,
      },
    ]);
  };

  const handleUploadCustomRequest = async ({
    file,
    onSuccess,
    onError,
  }: Parameters<NonNullable<UploadProps['customRequest']>>[0]) => {
    try {
      if (!(file instanceof File)) {
        throw new Error('Invalid file type');
      }
      const url = await uploadAvatar(file);
      if (!url) {
        throw new Error('Upload failed');
      }
      onSuccess?.(url);
      form.setFieldValue('avatar', url);
    } catch (error) {
      onError?.(error as Error);
    }
  };

  const handleUploadOnRemove = () => {
    form.setFieldValue('avatar', null);
    setFormMode(ContactFormMode.Update);
  };

  const labels = {
    [ContactFormMode.Create]: {
      title: 'Add Contact',
      okText: 'Add contact',
    },
    [ContactFormMode.Update]: {
      title: 'Update Contact',
      okText: 'Update contact',
    },
    [ContactFormMode.Assign]: {
      title: 'Assign Contact',
      okText: 'Assign contact',
    },
  };

  return (
    <Modal
      open={open}
      width={600}
      title={labels[formMode].title}
      okText={labels[formMode].okText}
      okButtonProps={{
        loading:
          assignContactLoading ||
          createPrimaryRecordContactLoading ||
          updatePrimaryRecordContactLoading,
      }}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => {
          if (formMode === ContactFormMode.Assign) {
            setFormMode(ContactFormMode.Update);
          }
        }}
      >
        <Form.Item hidden={true} name="_id" label="Id">
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: 'Please enter an email address' },
            { type: 'email', message: INVALID_EMAIL_MESSAGE },
          ]}
        >
          {formMode !== ContactFormMode.Create ? (
            <Input
              suffix={<CloseCircleFilled onClick={handleOnClear} />}
              disabled={true}
              placeholder="Enter contact email..."
            />
          ) : (
            <ContactsInput
              onSelect={handleOnSelect}
              onClear={handleOnClear}
              email={email ?? undefined}
              placeholder="Search an existing contact email or type a new one..."
            />
          )}
        </Form.Item>
        <Form.Item label="Name" name="contactPersonName">
          <Input placeholder="Enter contact name..." />
        </Form.Item>
        <Flex
          size="large"
          direction="horizontal"
          justify="space-between"
          align="center"
        >
          <Form.Item label="Company" name="companyName" style={{ flex: 1 }}>
            <Input placeholder="Enter contact company..." />
          </Form.Item>
          <Form.Item label="Title" name="title" style={{ flex: 1 }}>
            <Input placeholder="Enter contact title..." />
          </Form.Item>
        </Flex>
        <Flex
          direction="horizontal"
          size="large"
          justify="space-between"
          align="center"
        >
          <Form.Item label="Phone" name="phone" style={{ flex: 1 }}>
            <Input placeholder="Enter contact phone..." />
          </Form.Item>
          <Form.Item label="Fax" name="fax" style={{ flex: 1 }}>
            <Input placeholder="Enter contact fax..." />
          </Form.Item>
        </Flex>

        <Divider style={{ margin: '8px 0', border: 'none' }} />

        <Form.Item
          name="avatar"
          getValueFromEvent={(e) => {
            if (Array.isArray(e?.fileList) && e.fileList[0]) {
              return e.fileList[0].response || e.fileList[0].url;
            }
            return null;
          }}
        >
          <StyledUpload
            listType="picture"
            name="avatar"
            customRequest={handleUploadCustomRequest}
            onRemove={handleUploadOnRemove}
            maxCount={1}
            accept=".png,.jpg,.jpeg"
            fileList={
              avatarImgUrl
                ? [
                    {
                      uid: '-1',
                      url: avatarImgUrl,
                      name: `avatar${avatarImgUrl ? `.${avatarImgUrl.split('.').pop()}` : ''}`,
                      status: 'done',
                    },
                  ]
                : []
            }
          >
            {!avatarImgUrl && (
              <StyledAvatarUploader>
                <div>
                  <span className="subtitle">Custom avatar</span>
                  <span>Format: PNG or JPG, minimum 80px square or larger</span>
                </div>
                <Button loading={loading}>Upload</Button>
              </StyledAvatarUploader>
            )}
          </StyledUpload>
        </Form.Item>

        <StyledBorderedSection>
          <StyledCustomSection>
            {primaryRecordName && (
              <span className="subtitle">{`Custom settings for ${primaryRecordName}`}</span>
            )}
            <Form.Item name="defaultRequestRecipient" valuePropName="checked">
              <Checkbox>
                {`Use this contact for requests ${primaryRecordName ? `on ${primaryRecordName}` : ''}`}
              </Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name="primary">
              <Checkbox>
                {`Set as primary contact ${primaryRecordName ? `for ${primaryRecordName}` : ''}`}
              </Checkbox>
            </Form.Item>
          </StyledCustomSection>
        </StyledBorderedSection>
        {formMode === ContactFormMode.Update && (
          <Alert
            showIcon
            message="Editing existing contact will update the contact globally."
            type="warning"
          />
        )}
      </Form>
    </Modal>
  );
};

const StyledBorderedSection = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 8px;
`;

const StyledCustomSection = styled.div`
  .subtitle {
    color: ${({ theme }) => theme.colors.subtitleText};
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledUpload = styled(Upload)`
  width: 100%;

  .ant-upload  {
    width: 100%;
  }
`;

const StyledAvatarUploader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.mineShaft};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  padding: 8px;

  .subtitle {
    display: block;
    color: ${({ theme }) => theme.colors.lightTextGray};
  }
`;
