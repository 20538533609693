import { ArrowLeftOutlined, FolderTwoTone } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, Tooltip } from 'antd';
import PSPDFKitWeb from 'pspdfkit';
import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import PdfReader from '@common/components/PdfReader';
import { useDocumentInstance } from '@common/components/PdfReader/hooks';
import CheckIcon from '@common/icons/CheckIcon';
import PspdfkitConfig from '@config/PspdfkitConfig';
import { FILLABLE_FORM_STATUS } from '@modules/fillable-form/constants';
import { getUnstyledAnnotations } from '@trustlayer/common';

const filterToolbarItems = (items) => {
  return items.filter(
    ({ dropdownGroup, type }) =>
      dropdownGroup !== 'actionsGroup' &&
      type !== 'search' &&
      type !== 'document-editor' &&
      type !== 'export-pdf' &&
      !type.startsWith('sidebar-') &&
      dropdownGroup !== 'drawingGroup' &&
      ![
        'annotate',
        'signature',
        'arrow',
        'rectangle',
        'ellipse',
        'polygon',
        'polyline',
        'note',
        'text',
        'line',
      ].includes(type),
  );
};

const FillDocumentForm = ({
  documentForm,
  project,
  filler,
  onBackButtonClick,
  onSubmit,
  onSaveDraft,
}) => {
  const { documentInstance } = useDocumentInstance();
  const [formFieldValues, setFormFieldValues] = useState(() =>
    documentForm?.fillableForm?.formFieldValues.reduce(
      (acc, { name, value }) => ({
        ...acc,
        [name]: value,
      }),
      {},
    ),
  );
  const [formStatus, setFormStatus] = useState({});
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const form = documentForm?.fillableForm ?? {};
  const annotations = form?.annotations ?? [];
  const filledFieldsCount = Object.keys(
    formStatus.requiredFilledFields || {},
  ).length;
  const areAllFieldsFilled =
    filledFieldsCount === formStatus.requiredFieldNames?.length;

  const updateFormStatus = useCallback(async () => {
    const allAnnotations = (
      await Promise.all(
        Array.from({ length: documentInstance.totalPageCount }).map(
          (_, pageIndex) => documentInstance.getAnnotations(pageIndex),
        ),
      )
    ).flatMap((p) => p.toArray());
    const signatures = allAnnotations.filter((a) => a.isSignature);
    const formFields = (await documentInstance.getFormFields()).toArray();

    const fillerAnnotationsName = allAnnotations
      .filter((a) => a.customData?.association === filler)
      .map((f) => f.formFieldName);

    const requiredFieldNames = formFields
      .filter((f) => fillerAnnotationsName.includes(f.name) && f.required)
      .map((f) => f.name);

    const requiredFieldValues = requiredFieldNames.reduce(
      (acc, formFieldName) => {
        const annotation = allAnnotations.find(
          (a) => a.formFieldName === formFieldName,
        );
        const hasOverlappingSignature =
          annotation &&
          signatures.some((s) =>
            s.boundingBox.isRectOverlapping(annotation.boundingBox),
          );

        if (hasOverlappingSignature) {
          acc.set(formFieldName, hasOverlappingSignature);

          return acc;
        }

        acc.set(
          formFieldName,
          formFields.find((a) => a.name === formFieldName)?.value,
        );

        return acc;
      },
      new Map(),
    );
    const nullishValueAccepted = [null, undefined, ''];

    requiredFieldValues.forEach((value, key) => {
      // checkbox
      if (Array.isArray(value) && value[0] === 'Off') {
        requiredFieldValues.delete(key);
      }

      if (nullishValueAccepted.includes(value)) {
        requiredFieldValues.delete(key);
      }
    });

    setFormStatus({
      requiredFieldNames,
      requiredFilledFields: Object.fromEntries(requiredFieldValues),
    });
  }, [documentInstance, filler]);

  const handleOnFormFieldValuesUpdate = useCallback((formFields) => {
    const newFormFields = formFields.toArray().reduce((acc, field) => {
      acc.set(field.name, field.value);
      return acc;
    }, new Map());

    setFormFieldValues((formFieldValues) => {
      const newFormFieldsValues = {
        ...formFieldValues,
      };

      // It ensures that we are only adding/removing new values to the formFieldsValues without overriding the server ones
      newFormFields.forEach((value, key) => {
        const checkboxValue = value?.get && value.get(0);
        // checkbox
        if (checkboxValue === 'Off' || checkboxValue === 'value') {
          // instantJSON set the checkbox value as an array with the value 'Off' or 'value'
          // we do the same here to keep the same format
          newFormFieldsValues[key] = [checkboxValue];
        } else {
          newFormFieldsValues[key] = value;
        }
      });

      return newFormFieldsValues;
    });
  }, []);

  const onInkSignatureCreated = useCallback(
    async (inkSignature) => {
      const annotationsInSamePage = await documentInstance.getAnnotations(
        inkSignature.pageIndex,
      );

      const signedAnnotationField = annotationsInSamePage?.find(
        (annotation) =>
          !annotation.isSignature &&
          annotation?.boundingBox?.isRectOverlapping(inkSignature.boundingBox),
      );

      // ink signature value is set to null from PSPDFKit sdk. In our state we set it to true
      setFormFieldValues((formFieldValues) => ({
        ...formFieldValues,
        [signedAnnotationField?.formFieldName]: true,
      }));
    },
    [documentInstance],
  );

  const handleOnAnnotationsCreate = useCallback(
    (annotations) => {
      const lastAnnotation = annotations.get(0);

      if (lastAnnotation.isSignature) {
        onInkSignatureCreated(lastAnnotation);
      }
    },
    [onInkSignatureCreated],
  );

  const handleOnSaveDraft = async () => {
    const instantJSON = await documentInstance.exportInstantJSON();
    await onSaveDraft({
      _id: documentForm?._id,
      fillableForm: instantJSON,
      saveType: 'PARTIAL',
    });
  };

  const handleOnSubmit = async () => {
    setIsSubmitLoading(true);

    const isLastSigner = form.status === FILLABLE_FORM_STATUS.ONE_TO_GO;
    const instantJSON = await documentInstance.exportInstantJSON();
    const instantJSONWithUnstyledAnnotations = R.assoc(
      'annotations',
      getUnstyledAnnotations(instantJSON?.annotations || []),
      instantJSON,
    );
    const data = {
      _id: documentForm?._id,
      fillableForm: instantJSON,
      saveType: 'ALL',
    };

    if (!isLastSigner) {
      await onSubmit(data);
    } else {
      // @todo: add a loader in the Save button
      const headlessInstance = await PSPDFKitWeb.load({
        ...PspdfkitConfig,
        headless: true,
        document: documentForm?.url,
        instantJSON: instantJSONWithUnstyledAnnotations,
      });

      const documentFile = await headlessInstance.exportPDF({ flatten: true });
      await onSubmit({
        ...data,
        documentFile: new Blob([documentFile]),
      });
    }

    setIsSubmitLoading(false);
  };

  const handleOnBackButtonClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to close?',
      content: 'All unsaved changes will be lost.',
      okText: 'Close anyway',
      cancelText: 'Cancel',
      onOk: onBackButtonClick,
      width: 480,
      zIndex: 1005,
    });
  };

  const getDisabledAnnotations = ({ annotations = [], keepStyledTypes = [] }) =>
    annotations.map((ann) =>
      ann.type !== 'pspdfkit/widget' ||
      keepStyledTypes.includes(ann.customData?.association)
        ? ann
        : {
            ...ann,
            backgroundColor: '#efefef',
            borderWidth: 1,
          },
    );

  useEffect(() => {
    if (!documentInstance) {
      return;
    }

    documentInstance.setIsEditableAnnotation(
      (annotation) => annotation?.customData?.association === filler,
    );
  }, [documentInstance, filler]);

  useEffect(() => {
    if (!documentInstance) {
      return;
    }

    // handles text fields and checkboxes
    documentInstance.addEventListener(
      'formFieldValues.update',
      handleOnFormFieldValuesUpdate,
    );
    // handles signatures as they are treated as annotations
    documentInstance.addEventListener(
      'annotations.create',
      handleOnAnnotationsCreate,
    );

    return () => {
      documentInstance.removeEventListener(
        'formFieldValues.update',
        handleOnFormFieldValuesUpdate,
      );
      documentInstance.removeEventListener(
        'annotations.create',
        handleOnAnnotationsCreate,
      );
    };
  }, [
    documentInstance,
    handleOnFormFieldValuesUpdate,
    handleOnAnnotationsCreate,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    if (documentInstance) {
      updateFormStatus();
    }
  }, [formFieldValues, updateFormStatus, documentInstance]);

  return (
    <FillDocumentForm.Wrapper>
      <main>
        <header>
          <Row justify="space-between" align="middle">
            <Col>
              <Row>
                <Col>
                  <div className="backButton">
                    <ArrowLeftOutlined onClick={handleOnBackButtonClick} />
                  </div>
                </Col>
                <Col>
                  {project?.name && (
                    <div className="projectName">
                      <FolderTwoTone />
                      {project.name}
                    </div>
                  )}
                  <div className="documentFormName">
                    {documentForm?.friendlyName}
                  </div>
                </Col>
              </Row>
            </Col>

            {Boolean(documentInstance) && (
              <Col>
                <Space>
                  <span className="actionButtonHelpLabel">
                    {filledFieldsCount ===
                      formStatus.requiredFieldNames?.length && <CheckIcon />}
                    <span className="actionButtonHelpLabelBadge">
                      {filledFieldsCount}/
                      {formStatus.requiredFieldNames?.length}
                    </span>
                    required fields
                  </span>
                  <Button
                    data-cy="saveDraftDocumentForm"
                    disabled={areAllFieldsFilled}
                    onClick={handleOnSaveDraft}
                  >
                    Save draft
                  </Button>
                  <Tooltip
                    title={
                      'To complete the form you must fill in all the required fields'
                    }
                    open={areAllFieldsFilled ? false : undefined}
                  >
                    <Button
                      type="primary"
                      data-cy="submitDocumentForm"
                      disabled={!areAllFieldsFilled}
                      onClick={handleOnSubmit}
                      loading={isSubmitLoading}
                    >
                      Complete
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            )}
          </Row>
        </header>
        <PdfReader
          document={R.assocPath(
            ['fillableForm', 'annotations'],
            getDisabledAnnotations({ annotations, keepStyledTypes: [filler] }),
            documentForm,
          )}
          filterToolbarItems={filterToolbarItems}
        />
      </main>
    </FillDocumentForm.Wrapper>
  );
};

FillDocumentForm.Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;

  .backButton {
    .anticon {
      font-size: 18px;
      cursor: pointer;
      margin-right: 24px;

      &:hover {
        color: ${(props) => props.theme.colors.blue};
      }
    }
  }

  > main {
    flex: auto;
    display: flex;
    flex-direction: column;

    > header {
      padding: 16px 24px;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray};
    }
  }

  .actionButtonHelpLabel {
    font-size: 12px;
    margin-right: 24px;
    margin-left: 42px;
  }

  .actionButtonHelpLabelBadge {
    border-radius: 30px;
    border: 1px solid #e0e6ee;
    margin: 0 4px;
    padding: 4px 8px;
  }

  .projectName {
    font-size: 12px;
    margin-bottom: 4px;

    /* stylelint-disable-next-line no-descending-specificity */
    .anticon {
      font-size: 15px;
      margin-right: 3px;
    }
  }

  .documentFormName {
    font-size: 16px;
  }
`;
export default FillDocumentForm;
