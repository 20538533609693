import { ControlOutlined } from '@ant-design/icons';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const BaseComplianceProfileIcon = () => {
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  return (
    <Tooltip
      title={
        isComplianceRequirementsAddOnEnabled
          ? `This is a custom compliance profile because some base compliance requirements have been changed.`
          : 'This is a custom checklist because some base requirements have been changed.'
      }
      overlayClassName="baseComplianceProfileTooltip"
    >
      <StyledControlOutlined data-cy="customComplianceProfileIcon" />
    </Tooltip>
  );
};

const StyledControlOutlined = styled(ControlOutlined)`
  color: #fa8c16 !important;
`;

export default BaseComplianceProfileIcon;
