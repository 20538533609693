import { getActiveOrganizationId } from '@modules/organization/selectors';

import { STATE_KEY, defaultSearchParams } from '../constants';

import type { RootState } from '@common/types';

export const getLastUsedEffectiveDates = (state: RootState) => {
  const orgId = getActiveOrganizationId(state);
  return state?.[STATE_KEY]?.[orgId]?.lastUsedEffectiveDates;
};

export const getLastUsedExpirationDates = (state: RootState) => {
  const orgId = getActiveOrganizationId(state);
  return state?.[STATE_KEY]?.[orgId]?.lastUsedExpirationDates;
};

export const getCurrentProjectId = (state: RootState) => {
  const orgId = getActiveOrganizationId(state);
  return state?.[STATE_KEY]?.[orgId]?.currentProjectId ?? null;
};

export const getPreferredSearchParams = (state: RootState) => {
  const orgId = getActiveOrganizationId(state);

  const path = state?.[STATE_KEY]?.[orgId] ?? {};

  const picked = Object.fromEntries(
    Object.entries(path).filter(([key]) =>
      [
        'parties',
        'projects',
        'documents',
        'party-documents',
        'party-projects',
        'settings-integrations-parties',
        'settings-integrations-projects',
      ].includes(key),
    ),
  );

  return {
    ...defaultSearchParams,
    ...picked,
  };
};

export const getUploadedDocumentsPanels = (
  state: RootState,
  requestId: string,
) => {
  return state?.[STATE_KEY]?.uploadedDocumentsPanels?.[requestId] ?? [];
};

export const getAdvancedPartyComplianceIndicator = (state: RootState) => {
  const orgId = getActiveOrganizationId(state);
  return state?.[STATE_KEY]?.[orgId]?.advancedPartyComplianceIndicator;
};
