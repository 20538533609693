import type { QueryOptions } from '@apollo/client';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type {
  ProjectComplianceProfilesModalQueryQuery,
  ProjectComplianceProfilesModalQueryQueryVariables,
} from '@graphql/types/graphql';

const PROJECT_COMPLIANCE_PROFILES_QUERY = graphql(`
  query ProjectComplianceProfilesModalQuery(
    $globalComplianceProfileIds: [ObjectId!]!
    $projectId: ObjectId
    $customFields: [CustomFieldInput!]!
  ) {
    projectComplianceProfiles(
      globalComplianceProfileIds: $globalComplianceProfileIds
      projectId: $projectId
      customFields: $customFields
    ) {
      _id
      name
      context
      rules {
        moduleId
        moduleLabel
        subjectId
        subjectLabel
        attributeId
        attributeLabel
        attributeType
        matchingCriteria
        masterDocumentAttributeId
        operator
        targetValue
        isActive
        complianceProfile
        customFields {
          customField {
            _id
            name
            color
          }
          value
        }
      }
      documentChecklists {
        _id
        type {
          _id
          name
        }
        attributeId
        attributeLabel
        complianceProfile
        isActive
        customFields {
          customField {
            _id
            name
            color
          }
          value
        }
      }
    }
  }
`);
/**
 * Hook to fetch compliance profiles associated with a project
 *
 * @param globalComplianceProfileIds - Array of global compliance profile IDs
 * @param projectId - The ID of the project (optional)
 * @param customFields - Array of custom fields with their values
 * @returns Object containing the computed project compliance profiles data and loading state
 */
export function useProjectComplianceProfiles(
  {
    globalComplianceProfileIds,
    projectId,
    customFields,
  }: {
    globalComplianceProfileIds: string[];
    projectId?: string;
    customFields: Array<{ id: string; value: string }>;
  },
  opts?: QueryOptions<
    ProjectComplianceProfilesModalQueryQueryVariables,
    ProjectComplianceProfilesModalQueryQuery
  >,
) {
  const { data, loading, error, refetch } = useQuery(
    PROJECT_COMPLIANCE_PROFILES_QUERY,
    {
      ...opts,
      variables: {
        globalComplianceProfileIds,
        projectId,
        customFields,
      },
      skip: !globalComplianceProfileIds.length,
    },
  );

  return {
    complianceProfiles: data?.projectComplianceProfiles || [],
    loading,
    error,
    refetch,
  };
}
