import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { AttributesTable } from '@modules/primary-records';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { useParams } from 'react-router';

const PRIMARY_RECORD_QUERY = graphql(`
  query PrimaryRecordAttributes_PrimaryRecord($input: PrimaryRecordInput!) {
    primaryRecord(input: $input) {
      _id
      name
    }
  }
`);

export function PrimaryRecordAttributes() {
  const { recordId } = useParams<PrimaryRecordsRoutesParams>();

  const [onPrimaryRecordNameChanged] = useLazyQuery(PRIMARY_RECORD_QUERY, {
    variables: {
      input: { id: recordId },
    },
  });

  return (
    <AttributesTable
      recordId={recordId!}
      onAttributesChanged={onPrimaryRecordNameChanged}
    />
  );
}
