import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { message } from 'antd';

const CREATE_DOCUMENT_CHECKLIST_MUTATION = graphql(`
  mutation DocumentChecklistModule_CreateDocumentChecklist($payload: CreateComplianceProfileInput!) {
    createComplianceProfile(data: $payload) {
      _id
      documentChecklists {
        _id
        attributeId
        attributeLabel
        isActive
        type {
          _id
          name
        }
      }
    }
  }
`);

export const useCreateDocumentChecklistMutation = () => {
  const [mutate, { loading }] = useMutation(
    CREATE_DOCUMENT_CHECKLIST_MUTATION,
    {
      onCompleted: () => {
        message.success('Checklist created successfully');
      },
    },
  );

  return {
    createDocumentChecklist: mutate,
    isCreateDocumentChecklistLoading: loading,
  };
};
