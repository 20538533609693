import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ARCHIVE_REQUEST_RECORDS_MUTATION = graphql(`
  mutation ArchiveRequestRecords($data: ArchiveRequestRecordsInput!) {
    archiveRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useArchiveRequestRecordsMutation = () => {
  const [
    archiveRequestRecordsMutation,
    { loading: isArchivingRequestRecords },
  ] = useMutation(ARCHIVE_REQUEST_RECORDS_MUTATION);

  const archiveRequestRecords = (filters?: ServerFilterInput) => {
    return archiveRequestRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    archiveRequestRecords,
    isArchivingRequestRecords,
  };
};
