import {
  financialStrengthValues,
  ratingValues,
} from '@modules/compliance-profile';
import { parseCurrency, parseNumber } from '@trustlayer/common';

import { RequirementAttributeType } from '@trustlayer/common';

export const parseAttributeValue = (value: string, type: string) => {
  switch (type) {
    case RequirementAttributeType.boolean:
    case RequirementAttributeType.ai: {
      return Boolean(value) ? 'Present' : '';
    }
    case RequirementAttributeType.number: {
      const amount = parseNumber(value);
      return amount !== null ? parseCurrency(amount) : null;
    }
    case RequirementAttributeType.amBestRating: {
      return ratingValues[value as unknown as keyof typeof ratingValues];
    }
    case RequirementAttributeType.amBestFinancialStrength: {
      return financialStrengthValues[
        value as unknown as keyof typeof financialStrengthValues
      ];
    }
    default: {
      return value;
    }
  }
};
