import { createGlobalStyle } from 'styled-components';

import colors from '../patterns/colors';
import fonts from '../patterns/fonts';
import shadows from '../patterns/shadows';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    line-height: 1.5715;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      transition-delay: 99999s;
    }

    ul,
    ol,
    dl {
      padding-left: 0;
    }

    iframe {
      border: 0;
    }

    a {
      color: #1890ff;
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }

    .ant-btn,
    .ant-input-affix-wrapper,
    .ant-form-item-control-input-content .ant-input,
    .ant-select,
    .ant-checkbox,
    .ant-radio-button-wrapper {
      box-shadow: 0 2px 0 rgba(0, 0, 0, .04);
    }

    .ant-input-affix-wrapper .ant-input,
    .ant-btn-link {
      box-shadow: none;
    }

    .ant-collapse-borderless {
      background-color: #fff;
    }

    .ant-layout {
      background: #f4f8fd;
    }

    .ant-modal-mask,
    .ant-drawer-mask {
      background-color: rgba(5, 14, 42, 0.25);
    }

    .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper,
    .ant-modal-content {
      box-shadow: rgba(5, 14, 42, 0.08) 0 0 0 10px;
    }

    .ant-btn-disabled,
    .ant-btn-disabled.active,
    .ant-btn-disabled:active,
    .ant-btn-disabled:focus,
    .ant-btn-disabled:hover,
    .ant-btn.disabled,
    .ant-btn.disabled.active,
    .ant-btn.disabled:active,
    .ant-btn.disabled:focus,
    .ant-btn.disabled:hover,
    .ant-btn[disabled],
    .ant-btn[disabled].active,
    .ant-btn[disabled]:active,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:hover {
      color: hsla(0, 0%, 75%, 1);
      background-color: hsla(0, 0%, 98%, 1);
      border-color: hsla(0, 0%, 90%, 1);
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #f4f8fd;
    }

    .ant-pagination.mini {
      .ant-pagination-options {
        margin-left: 5px;
      }

      .ant-select-selection-item,
      .ant-pagination-total-text {
        font-size: 12px !important;
        line-height: 22px !important;
      }
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background: #1890ff;

        .ant-steps-icon {
          color: #fff;
        }
      }
    }

    .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) {
      > .ant-steps-item-container[role='button']:hover {
        .ant-steps-item-icon .ant-steps-icon {
          color: #fff;
        }
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      text-shadow: none !important;
    }

    .ant-checkbox-group {
      display: inline-block;
    }
  }

  /**
   * Party Tag global styles.
   *
   * Applied to @common/components/PartyTag
   */
  .partyTagStyles {
    .ant-popover-title {
      min-width: auto;
    }

    .ant-popover-inner {
      padding: 12px;
    }
  }

  .flagBadgePopover {
    .ant-list-pagination:empty {
      display: none;
    }
  }

  .baseComplianceProfileTooltip {
    .ant-tooltip-inner {
      width: 350px;
    }
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-column-sorters {
    display: inline-flex;
  }

  .ant-table-column-sorter {
    margin-left: 8px;
  }
`;

export const theme = {
  colors,
  fonts,
  shadows,
};
