import { getIsChangingComplianceProfile } from '@modules/party/selectors';
import { useSelector } from 'react-redux';

import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { Link } from 'react-router';
import styled from 'styled-components';

import VerticalSeparator from '@common/components/VerticalSeparator';
import { ComplianceProfilesSelect } from './ComplianceProfilesSelect/ComplianceProfilesSelect';

const ComplianceDrawerTitleContainer = ({
  party,
  selectedComplianceProfile,
  complianceProfileId,
  complianceProfileName,
  isParty,
  organizationNamespace,
  onSaveComplianceProfile,
  setComplianceProfileName,
  showConfirmClose,
  onSelectedComplianceProfileChange,
  loadingComplianceProfile,
}) => {
  const isChangingComplianceProfile = useSelector(
    getIsChangingComplianceProfile,
  );

  return (
    <StyledWrapper to={`${organizationNamespace}/parties`}>
      <StyledTitle>
        <StyledArrowLeftOutlined onClick={showConfirmClose} />
        <VerticalSeparator />
        <h2>
          {Boolean(complianceProfileId) || party
            ? 'Edit compliance profile'
            : 'Create new compliance profile'}
        </h2>
      </StyledTitle>
      <StyledSelectProfileWrapper>
        <h3>Compliance profile name</h3>
        {isParty ? (
          <>
            <StyledDropdownWrapper data-cy="complianceDrawerSelectProfile">
              <ComplianceProfilesSelect
                value={selectedComplianceProfile}
                onChange={onSelectedComplianceProfileChange}
              />
              <StyledManageProfile
                to={`${organizationNamespace}/settings/requirements`}
              >
                Manage Profile
              </StyledManageProfile>
            </StyledDropdownWrapper>
          </>
        ) : (
          <Input
            data-cy="complianceProfileNameInput"
            size="large"
            onChange={(e) => setComplianceProfileName(e.target.value)}
            value={complianceProfileName}
          />
        )}
        <StyledButtonsWrapper>
          <StyledButton
            data-cy="closeProfileButton"
            size="large"
            onClick={showConfirmClose}
            disabled={isChangingComplianceProfile}
          >
            Close
          </StyledButton>
          <StyledButton
            data-cy="saveProfileButton"
            icon={<SaveOutlined />}
            size="large"
            type="primary"
            loading={isChangingComplianceProfile}
            onClick={onSaveComplianceProfile}
            disabled={loadingComplianceProfile}
          >
            {Boolean(complianceProfileId) || party
              ? 'Save changes'
              : 'Create compliance profile'}
          </StyledButton>
        </StyledButtonsWrapper>
      </StyledSelectProfileWrapper>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
`;

const StyledArrowLeftOutlined = styled(ArrowLeftOutlined)`
  font-size: 20px;
  cursor: pointer;
`;

const StyledSelectProfileWrapper = styled.div`
  margin-top: 25px;
  padding: 25px 0;
  display: grid;
  grid-template-columns: 270px 1fr auto;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.colors.gray};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};

  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    opacity: 0.8;
  }
`;

const StyledDropdownWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-size: 14px;
`;

const StyledManageProfile = styled(Link)`
  margin-left: 20px;
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`;

const StyledButtonsWrapper = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 20px;
`;

export default ComplianceDrawerTitleContainer;
