import {
  BarChartOutlined,
  BellOutlined,
  DashboardOutlined,
  FileTextOutlined,
  FolderOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import { Badge, Menu } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation } from 'react-router';
import styled from 'styled-components';

import useEntityQueryString from '@common/hooks/useEntityQueryString';
import { useQuery } from '@graphql/hooks';
import {
  useIsBaseAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';
import {
  useMetabaseReports,
  useTrustLayerV2FeatureFlag,
} from '@modules/feature-flags/hooks';
import { openNotificationDrawer } from '@modules/notification/actions';
import { NOTIFICATION_BELL_TOTAL_COUNT_QUERY } from '@modules/notification/containers/NotificationsBell/queries';
import { NOTIFICATION_UPDATES_SUBSCRIPTION } from '@modules/notification/subscriptions';
import { getCurrentOrganizationMember } from '@modules/organization-member/selectors';
import { useAppSelector } from '@store/hooks';
import { StyledPageLayoutWithSideNavigationConstants as Constants } from '../../constants';
import { getPageKeyFromURL } from './NavigationMenu.utils';
type NavigationMenuProps = {
  organizationCode: string;
};

export const NavigationMenu = ({ organizationCode }: NavigationMenuProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  const { getQsForEntityList } = useEntityQueryString();
  const { isMetabaseReportsFeatureFlagEnabled } = useMetabaseReports();
  const { isTlV2FeatureFlagEnabled } = useTrustLayerV2FeatureFlag();
  const pageKey = getPageKeyFromURL(location.pathname);
  const handleOpenNotificationDrawer = () => dispatch(openNotificationDrawer());

  const currentOrganizationMember = useAppSelector(
    getCurrentOrganizationMember,
  );

  const { data, subscribeToMore } = useQuery(
    NOTIFICATION_BELL_TOTAL_COUNT_QUERY,
    {
      variables: {
        filter: {
          isRead: false,
        },
      },
    },
  );

  useEffect(() => {
    subscribeToMore({
      document: NOTIFICATION_UPDATES_SUBSCRIPTION,
      variables: { memberId: currentOrganizationMember?._id },
      updateQuery: (prevData) => {
        const { totalCount } = prevData.notifications || {};
        const newTotalCount = totalCount ?? 0 + 1;

        return {
          notifications: { totalCount: newTotalCount },
        };
      },
    });
  }, [subscribeToMore, currentOrganizationMember?._id]);

  const unreadTotalCount = data?.notifications?.totalCount || 0;

  const items = [
    {
      key: 'notifications',
      icon: (
        <Badge dot={unreadTotalCount > 0}>
          <BellOutlined />
        </Badge>
      ),
      label: 'Notifications',
      onClick: handleOpenNotificationDrawer,
    },
    ...(!isBaseAddOnEnabled
      ? [
          {
            key: 'dashboard',
            icon: <DashboardOutlined />,
            label: (
              <NavLink to={`/${organizationCode}/dashboard`}>Dashboard</NavLink>
            ),
          },
        ]
      : []),
    ...(isMetabaseReportsFeatureFlagEnabled
      ? [
          {
            key: 'reports',
            icon: <BarChartOutlined />,
            label: (
              <NavLink to={`/${organizationCode}/reports`}>Insights</NavLink>
            ),
          },
        ]
      : []),
    {
      key: 'parties',
      icon: <ShopOutlined />,
      label: (
        <NavLink
          to={`/${organizationCode}/parties?${getQsForEntityList('parties')}`}
        >
          Parties
        </NavLink>
      ),
    },
    ...(isContextsAddOnEnabled
      ? [
          {
            key: 'projects',
            icon: <FolderOutlined />,
            label: (
              <NavLink
                to={`/${organizationCode}/projects?${getQsForEntityList('projects')}`}
              >
                Projects
              </NavLink>
            ),
          },
        ]
      : []),
    {
      key: 'documents',
      icon: <FileTextOutlined />,
      label: (
        <NavLink
          to={`/${organizationCode}/documents?${
            isTlV2FeatureFlagEnabled ? '' : getQsForEntityList('documents')
          }`}
        >
          Documents
        </NavLink>
      ),
    },
  ];

  return (
    <StyledMenu
      selectedKeys={pageKey ? [pageKey] : []}
      mode="vertical"
      style={{ border: 'none' }}
      items={items}
    />
  );
};

const StyledMenu = styled(Menu)`
  background-color: transparent;
  padding: 0 ${Constants.spacingMd};

  .ant-menu-item-selected {
    background-color: #e6f4ff !important;
  }

  &.ant-menu-inline-collapsed .ant-badge .ant-badge-dot {
    top: 1em;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
