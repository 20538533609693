import { MinusCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import TagLabel from '@common/components/TagLabel';
import TwoLinesText from '@common/components/TwoLinesText';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import { ComplianceProfilesSelect } from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import { useDisableProjectComplianceFeatureFlag } from '@modules/feature-flags/hooks';

const ProjectPartiesTabView = ({
  party,
  complianceProfiles,
  selectedProfile,
  onDeleteParty,
  onAssignProfile,
}) => {
  const { isDisableProjectComplianceFeatureFlagEnabled } =
    useDisableProjectComplianceFeatureFlag();
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const complianceProfileData = complianceProfiles.find(
    ({ _id }) => _id === selectedProfile,
  );

  /* 
  When a new profile is selected, a getComplianceProfile will be dispatched by the SaveProjectModal
  and complianceProfileData will be undefined until the query is completed. In this timeframe selectedProfileValue will be selectedProfile thus the id,
  which will make the ComplianceProfilesSelect fetches the name of the profile since its missing int he value, 
  even if it will come in a moment because of the getComplianceProfile. In some way its better, in some is redundant.
  To avoid this additional fetch, selectedProfile must become not just the id but an object with also the name
  */
  const selectedProfileValue = complianceProfileData
    ? {
        _id: complianceProfileData._id,
        name: complianceProfileData.name,
      }
    : selectedProfile;

  return (
    <StyledListItem>
      <TwoLinesText>
        <StyledPartyName data-cy="projectPartyName">
          {party.name}
        </StyledPartyName>
        <StyledPartyType>
          {party?.type?.name}
          {!party.isActive && (
            <TagLabel data-cy="inactivePartyTagLabel">inactive</TagLabel>
          )}
        </StyledPartyType>
      </TwoLinesText>
      <StyledActions>
        {isComplianceRequirementsAddOnEnabled && (
          <>
            {party?.isTrackingCompliance ? (
              <ComplianceProfilesSelect
                data-cy="partiesTabSelectComplianceProfile"
                allowClear={isDisableProjectComplianceFeatureFlagEnabled}
                value={selectedProfileValue}
                onChange={({ _id } = {}) => onAssignProfile(_id)}
                size="middle"
              />
            ) : (
              <StyledActions>No compliance tracking</StyledActions>
            )}
          </>
        )}

        <StyledRemove
          data-cy="projectRemovePartyButton"
          onClick={() => onDeleteParty(party._id)}
        />
      </StyledActions>
    </StyledListItem>
  );
};

const StyledListItem = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  padding: 5px 0;
`;

const StyledPartyName = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
`;

const StyledPartyType = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);

  i {
    font-style: normal;
    margin-left: 15px;
    color: #1890ff;
  }
`;

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledNoComplianceTracking = styled.span`
  opacity: 0.4;
`;

const StyledRemove = styled(MinusCircleOutlined)`
  margin-left: 25px;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export default ProjectPartiesTabView;
