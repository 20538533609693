import { CONTEXT_RECORDS_TABS } from '@modules/context-records/constants';
import { Skeleton, Tag } from 'antd';
import { SidebarLoadingError } from 'layouts';
import { Link } from 'react-router';
import styled from 'styled-components';
import { useAttachmentsSidebarPanel } from './hooks/useAttachmentsSidebarPanel';

export const AttachmentsSidebarPanel = ({
  contextRecordId,
}: { contextRecordId: string }) => {
  const {
    attachments,
    attachmentsTotalCount,
    loading,
    error,
    refetchAttachments,
  } = useAttachmentsSidebarPanel({
    contextRecordId,
  });

  if (error) {
    return (
      <SidebarLoadingError
        loadingActionLabel="Attachments"
        onActionClick={() => refetchAttachments()}
      />
    );
  }

  return (
    <Skeleton active loading={loading}>
      <StyledTagWrapepr>
        {attachments.map((attachment) => (
          <Tag key={attachment.s3Key}>{attachment.friendlyName}</Tag>
        ))}
      </StyledTagWrapepr>
      {attachments.length < attachmentsTotalCount && (
        <StyledLink to={`./${CONTEXT_RECORDS_TABS.Attachments.key}`}>
          Show more
        </StyledLink>
      )}
    </Skeleton>
  );
};

const StyledTagWrapepr = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 10px;
`;
