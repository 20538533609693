import { Card as AntCard, Col, Row } from 'antd';
import { Link } from 'react-router';

import Card from '@common/components/Card';

import PartyCustomFieldsTable from './PartyCustomFieldsTable';

const PartyCustomFieldsWrapper = ({
  partyId,
  organizationSlugifyName,
  projectId,
}: {
  partyId: string;
  organizationSlugifyName: string;
  projectId?: string;
}) => {
  return (
    <Row gutter={16}>
      <Col span={17}>
        <PartyCustomFieldsTable
          key={projectId}
          partyId={partyId}
          isInProjectContext={Boolean(projectId)}
        />
      </Col>
      <Col span={7}>
        <Card>
          <AntCard.Meta
            title="Adding New Custom Fields"
            description={
              <>
                Custom fields can help you add specific data to your parties and
                projects. To add a new field, go over to{' '}
                <Link
                  to={`/${organizationSlugifyName}/settings/custom-fields/party`}
                >
                  Organization Settings
                </Link>
              </>
            }
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PartyCustomFieldsWrapper;
