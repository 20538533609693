import useSearchParams from '@common/hooks/useSearchParams';
import { Conversations } from '@modules/conversation';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router';
import type { PrimaryRecordDetailContext } from './types';

const normalizeSearchParams = (param: string | (string | null)[] | null) => {
  if (Array.isArray(param)) {
    return param[0] || undefined;
  }

  return param || undefined;
};

export function PrimaryRecordMessages() {
  const { searchParams } = useSearchParams();
  const { recordId } = useParams<PrimaryRecordsRoutesParams>();
  const { pathname } = useLocation();
  const { handleSendEmailClick } =
    useOutletContext<PrimaryRecordDetailContext>();
  const navigate = useNavigate();

  const normalizedConversationIdParam = normalizeSearchParams(
    searchParams.conversation,
  );
  const selectedConversation = normalizedConversationIdParam
    ? {
        id: normalizedConversationIdParam,
        messageId: normalizeSearchParams(searchParams.message),
      }
    : undefined;

  const handleConversationsChange = ({
    conversationId,
    messageId,
  }: {
    conversationId: string;
    messageId?: string;
  }) => {
    const messageParam = messageId ? `&message=${messageId}` : '';

    const conversationParam = conversationId
      ? `conversation=${conversationId}`
      : '';

    navigate(`${pathname}?${conversationParam}${messageParam}`, {
      replace: true,
    });
  };

  return (
    <Conversations
      primaryRecordId={recordId!}
      selectedConversation={selectedConversation}
      onChange={handleConversationsChange}
      onSendEmailClick={handleSendEmailClick}
    />
  );
}
