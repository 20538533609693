import { AlignLeftOutlined, CaretDownFilled } from '@ant-design/icons';
import { Draggable } from '@hello-pangea/dnd';
import {
  Button,
  Cascader,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Radio,
  Tooltip,
  Typography,
} from 'antd';
import * as R from 'ramda';
import styled from 'styled-components';

import AdCircleInfo from '@common/components/AdCircleInfo';
import CheckIcon from '@common/icons/CheckIcon';
import RuleDisplayValue from '@modules/compliance-profile/components/RuleDisplayValue.legacy';

import { displayRule } from '../utils/rule-helpers';

import CustomRequirement from './CustomRequirement';
import DefinedRequirement from './DefinedRequirement';

const { TextArea } = Input;

const RequirementItem = ({
  description,
  matchingCriteria,
  cascaderOptions,
  isShowForm,
  isCustom,
  isCustomSubject,
  isCustomModule,
  isShowDescription,
  setDescription,
  setMatchingCriteria,
  selectedAttribute,
  selectedOperator,
  onChangeHandle,
  targetValue,
  searchFilter,
  setTargetValue,
  setSelectedAttribute,
  setIsShowDescription,
  setIsShowForm,
  isActiveCreate,
  isCustomRequirement,
  requirement,
  resetState,
  isValidRequirement,
  validateCustomRequirement,
  setIsCustom,
  updateRequirement,
  deleteRequirement,
  index,
  hideActions,
}) => (
  <Draggable draggableId={requirement.attributeId} index={index}>
    {(provided) => (
      <div ref={provided.innerRef} {...provided.draggableProps}>
        <StyledWrapper>
          <StyledRequirementWrapper
            key={requirement._id}
            className={!!isShowForm ? 'open' : ''}
          >
            {isShowForm ? (
              <StyledFormWrapper>
                <div {...provided.dragHandleProps} />
                <StyledInputsWrapper>
                  {!isCustom && (
                    <>
                      <StyledCascader
                        allowClear={false}
                        clearIcon={false}
                        options={cascaderOptions}
                        value={
                          selectedAttribute.attributeId && [
                            selectedAttribute.attributeId,
                            selectedOperator,
                          ]
                        }
                        onChange={onChangeHandle}
                        displayRender={(labels) => displayRule(labels)}
                        showSearch={{ searchFilter }}
                        placeholder="Click to select an item or start typing"
                        dropdownMenuColumnStyle={{
                          fontSize: '14px',
                          fontWeight: '400',
                          padding: '5px',
                        }}
                      />
                      <DefinedRequirement
                        targetValue={targetValue}
                        fieldType={selectedAttribute.attributeType}
                        selectedOperator={selectedOperator}
                        setTargetValue={setTargetValue}
                      />
                    </>
                  )}
                  {isCustom && (
                    <CustomRequirement
                      selectedAttribute={selectedAttribute}
                      setSelectedAttribute={setSelectedAttribute}
                      validateCustomRequirement={validateCustomRequirement}
                      targetValue={targetValue}
                      selectedOperator={selectedOperator}
                      setTargetValue={setTargetValue}
                    />
                  )}
                  <Tooltip title="Provide additional information for this requirement">
                    <Button
                      data-cy="addDescriptionButton"
                      type="default"
                      icon={<AlignLeftOutlined />}
                      onClick={() => setIsShowDescription(!isShowDescription)}
                    />
                  </Tooltip>
                  <Button
                    data-cy="closeRequirementButton"
                    onClick={() => {
                      setIsShowForm(false);
                      resetState();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-cy="updateRequirementButton"
                    type="primary"
                    disabled={!isActiveCreate() && isValidRequirement}
                    onClick={() => {
                      if (isValidRequirement) {
                        updateRequirement();
                      }
                    }}
                  >
                    Update
                  </Button>
                </StyledInputsWrapper>
                {isShowDescription && (
                  <>
                    <StyledDescriptionWrapper>
                      <TextArea
                        rows={2}
                        placeholder="Additional information..."
                        value={description}
                        autoSize={{ minRows: 2, maxRows: 8 }}
                        onChange={({ target }) => setDescription(target.value)}
                      />
                    </StyledDescriptionWrapper>
                    <StyledMatchingCriteria>
                      <span>Matching criteria:</span>
                      <Radio.Group
                        defaultValue={matchingCriteria || 'matchOnce'}
                        onChange={(e) => {
                          setMatchingCriteria(e.target.value);
                        }}
                      >
                        <Radio value="matchOnce">Match once</Radio>
                        <Radio value="matchRepeatedly">Match repeatedly</Radio>
                      </Radio.Group>
                    </StyledMatchingCriteria>
                  </>
                )}
              </StyledFormWrapper>
            ) : (
              <>
                <StyledRowWrapper data-cy="requirementItemRow">
                  <StyledDragIconWrapper
                    {...provided.dragHandleProps}
                    className="dragIconWrapper"
                  >
                    <StyledDragIcon />
                  </StyledDragIconWrapper>
                  <StyledCheckIconWrapper>
                    <CheckIcon muted />
                  </StyledCheckIconWrapper>
                  <div>
                    <RuleDisplayValue
                      rule={requirement}
                      isCustom={isCustomRequirement}
                    />
                  </div>
                  {isCustomRequirement &&
                    !isCustomSubject &&
                    !isCustomModule && (
                      <AdCircleInfo tooltipText="Custom requirement" />
                    )}
                  {!hideActions && (
                    <StyledActionButtons>
                      <Dropdown
                        data-cy="dropdownMenu"
                        dropdownRender={() => (
                          <Menu>
                            <Menu.Item
                              key="edit"
                              onClick={() => {
                                setIsShowForm(!isShowForm);
                                resetState();
                                setIsCustom(isCustomRequirement);
                              }}
                            >
                              Edit{' '}
                              {isCustomRequirement
                                ? 'custom requirement'
                                : 'requirement'}
                            </Menu.Item>
                            <Menu.Item key="delete" onClick={deleteRequirement}>
                              Delete{' '}
                              {isCustomRequirement
                                ? 'custom requirement'
                                : 'requirement'}
                            </Menu.Item>
                          </Menu>
                        )}
                        trigger={['click']}
                      >
                        <StyledActionButton shape="circle" type="link">
                          <CaretDownFilled />
                        </StyledActionButton>
                      </Dropdown>
                    </StyledActionButtons>
                  )}
                </StyledRowWrapper>
                <StyledDescriptionLabel
                  ellipsis={{
                    rows: 2,
                    expandable:
                      R.propOr('', 'attributeDescription', requirement).length >
                      20,
                  }}
                >
                  {requirement.attributeDescription}
                </StyledDescriptionLabel>
              </>
            )}
          </StyledRequirementWrapper>
        </StyledWrapper>
      </div>
    )}
  </Draggable>
);

const StyledCascader = styled(Cascader)`
  && {
    width: 100%;
    flex: 3;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const StyledFormWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid #bfe2ff;
  background: #f5faff;
`;

const StyledInputsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  > * {
    margin: 0 9px 0 0 !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }

  .ant-input-number {
    flex: 1;
  }
`;

const StyledAddNewRequirement = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;

  p {
    margin: 0 0 0 10px;
  }
`;

const StyledAddNote = styled.span`
  width: 130px;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
  text-align: center;
`;

const StyledDescriptionWrapper = styled.div`
  margin: 10px 0 0;
`;

const StyledMatchingCriteria = styled.div`
  margin: 10px 0 0;

  .ant-radio-wrapper,
  > span {
    font-size: 13px;
  }

  > span {
    margin-right: 10px;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 250px !important;
`;

const StyledActionButtons = styled.div`
  position: relative;
  margin-left: 5px;
  font-size: 13px;
`;

const StyledRequirementWrapper = styled.div`
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: column;
  font-size: 13px;

  &.open {
    padding-left: 0;
  }
`;

const StyledRowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 32px;

  p {
    margin: 0 0 0 10px;
  }

  &:hover {
    .dragIconWrapper {
      div {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
`;

const StyledDragIconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  left: -20px;
  width: 20px;
  height: 16px;
  overflow: hidden;
`;

const StyledDragIcon = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/drag-and-drop.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  transform: translateX(100%);
  opacity: 0;
  transition:
    transform 0.2s,
    opacity 0.2s;
`;

const StyledDescriptionLabel = styled(Typography.Paragraph)`
  margin-left: 22px;
  margin-bottom: 5px !important;
  color: ${(props) => props.theme.colors.grayText};
  opacity: 0.85;

  &:empty {
    display: none;
  }
`;

const StyledCheckIconWrapper = styled.div`
  margin-right: 10px;
  flex: 0;
`;

const StyledButtonDelete = styled(Button)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

const StyledButtonEdit = styled(Button)`
  i {
    font-size: 13px !important;
  }
`;

const StyledActionButton = styled(Button)`
  && {
    opacity: 0.5;
    color: ${(props) => props.theme.colors.paleGray};

    &&:hover {
      opacity: 1;
      color: initial;
    }
  }
`;

export default RequirementItem;
