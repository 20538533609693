import {
  DeleteOutlined,
  PoweroffOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { type ActionType, CONTEXT_RECORDS_ACTION_TYPES } from '../constants';

type Params = {
  excludedActions?: ActionType[];
  options?: Record<string, boolean>;
};

export const getActionsMenuItems = ({
  excludedActions = [],
  options,
}: Params) => {
  const actions = [
    {
      code: CONTEXT_RECORDS_ACTION_TYPES.archive,
      label: 'Archive',
      icon: <PoweroffOutlined />,
    },
    {
      code: CONTEXT_RECORDS_ACTION_TYPES.makeActive,
      label: 'Make active',
      icon: <ReloadOutlined />,
    },
    {
      code: CONTEXT_RECORDS_ACTION_TYPES.delete,
      testId: CONTEXT_RECORDS_ACTION_TYPES.delete,
      label: 'Delete',
      icon: <DeleteOutlined />,
    },
  ];

  return actions.filter(
    (action) => excludedActions.includes(action.code) === false,
  );
};
