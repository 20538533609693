import qs from 'query-string';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { SortDirectionsFullMap } from '@common/constants';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';
import { getCurrentProject } from '@modules/project/selectors';

import {
  useIsBaseAddOnEnabled,
  useIsComplianceRequirementsAddOnEnabled,
  useIsContextsAddOnEnabled,
} from '@modules/add-on';
import DocumentsTable from '../components/DocumentsTable';
import NormalizedDocument from '../components/NormalizedDocument';
import { getDocumentsColumns } from '../constants';
import { useDownloadDocument } from '../hooks';

const DocumentsTableContainer = ({
  loading,
  documents,
  updateSort,
  sortField,
  areAllDocumentsSelected,
  sortDirection,
  selectedDocuments,
  paginationOptions,
  onRowSelection,
  onDocumentActionClick,
}) => {
  const { partyId, organizationSlugifyName } = useParams();
  const { downloadDocument, isDownloadingDocument } = useDownloadDocument();

  const location = useLocation();
  const currentProject = useSelector(getCurrentProject);
  const searchParams = qs.parse(location.search);
  const isArchived = R.propEq('status', 'archived', searchParams);

  const memberRole = useMemberRole();
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();
  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();
  const { isBaseAddOnEnabled } = useIsBaseAddOnEnabled();

  const tableConfig = {
    loading: loading || isDownloadingDocument,
    dataSource: documents.map((document) =>
      NormalizedDocument({
        organizationNamespace: `/${organizationSlugifyName}`,
        partyId,
        document,
        isArchived,
        isContextsAddOnEnabled,
        isBaseAddOnEnabled,
        currentProjectId: R.propOr(null, '_id', currentProject),
        memberRole,
        detachFromCurrentParty: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'detachFromCurrentParty',
          }),
        assignToParty: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'assignToParty',
          }),
        detachFromCurrentProject: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'detachDocumentFromCurrentProject',
          }),
        reviewDocument: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'reviewDocument',
          }),
        associateToProject: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'associateToProject',
          }),
        onHandleClickDocumentName: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'documentNameClick',
          }),
        updateDocumentArchiveStatus: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'updateDocumentArchiveStatus',
          }),
        deleteDocument: (document) =>
          onDocumentActionClick({
            document,
            actionType: 'deleteDocument',
          }),
        downloadDocument,
      }),
    ),
    columns: getDocumentsColumns({
      sortField,
      sortDirection,
      isComplianceRequirementsAddOnEnabled,
    }),
    pagination: paginationOptions,
    sortDirections: [
      SortDirectionsFullMap.asc,
      SortDirectionsFullMap.desc,
      SortDirectionsFullMap.asc,
    ],
    showSorterTooltip: false,
    onChange: (_, __, sort) => {
      updateSort(sort);
    },
    rowSelection:
      memberRole !== ROLES.VIEWER
        ? {
            selectedRowKeys: areAllDocumentsSelected
              ? documents.map((document) => document._id)
              : selectedDocuments,
            onChange: onRowSelection,
          }
        : undefined,
  };

  return <DocumentsTable tableConfig={tableConfig} />;
};

export default DocumentsTableContainer;
