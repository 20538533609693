import debounce from 'lodash/debounce';
import * as R from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { getGraphqlResponse } from '@store/helpers';

import { trackEvent } from '@common/utils/track-helpers';
import { getViewedProjects } from '../actions';
import ProjectMultipleSelectView from '../components/ProjectMultipleSelectView';

export const APPLIES_TO_ALL_PROJECTS_LIST_ELEMENT = {
  name: 'Applies to all projects',
  _id: 'appliesToAllProjects',
};

const ProjectMultipleSelectContainer = ({
  initialProjects,
  onChange,
  withAppliesToAllProjects,
  visibleInactiveProjects = false,
}) => {
  const [loadedProjects, setLoadedProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { partyId } = useParams();
  const dispatch = useDispatch();

  const appliesToallProjectsElement = useMemo(
    () =>
      withAppliesToAllProjects ? [APPLIES_TO_ALL_PROJECTS_LIST_ELEMENT] : [],
    [withAppliesToAllProjects],
  );

  const searchProjects = useCallback(
    async (value) => {
      setIsLoading(true);
      const res = await dispatch(
        getViewedProjects({
          first: 100,
          filter: {
            name: value,
            ...(!visibleInactiveProjects && { isActive: true }),
          },
        }),
      );
      const normalizedProjects = getGraphqlResponse(res)?.nodes ?? [];
      setLoadedProjects(
        R.concat(appliesToallProjectsElement, normalizedProjects),
      );
      setIsLoading(false);
    },
    [dispatch, appliesToallProjectsElement, visibleInactiveProjects],
  );

  const onSearchTrigger = debounce(searchProjects, 200);

  return (
    <ProjectMultipleSelectView
      initialProjects={initialProjects}
      loadedProjects={loadedProjects}
      isLoading={isLoading}
      onSearch={(value) => {
        onSearchTrigger(value);
      }}
      onChange={(...args) => {
        const hasAppliesToAllProjects = Boolean(
          R.find(
            ({ key }) => key === APPLIES_TO_ALL_PROJECTS_LIST_ELEMENT._id,
            args[0],
          ),
        );

        if (hasAppliesToAllProjects) {
          trackEvent('User filtered documents using "Applies to all projects"');
        }
        onChange(...args);
      }}
      highlightFirstElement={Boolean(withAppliesToAllProjects)}
    />
  );
};

export default ProjectMultipleSelectContainer;
