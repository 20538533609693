import { useSelector } from 'react-redux';

import { useMutation, useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { EventType } from '@modules/event-log';
import { getActiveOrganizationData } from '@modules/organization/selectors';

type AddMessageParams = {
  message: string;
  onCompleted?: () => void;
  taggedMemberIds: string[];
  user: {
    _id: string;
    avatar?: string;
    name?: string;
    email: string;
  };
};

export const RECORD_ACTIVITIES_QUERY = graphql(`
  query RecordActivities(
    $primaryRecordInput: PrimaryRecordInput!
    $input: PrimaryRecordActivitiesInput
  ) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      name
      activities(input: $input) {
        nodes {
          _id
          type
          data
          createdAt
          primaryRecordName
          primaryRecordId
          contextRecordName
          actorId
          actorType
          referenceId
          referenceType
        }
        totalCount
      }
    }
  }
`);

export const ADD_MESSAGE_MUTATION = graphql(`
  mutation AddMessage($payload: EventLogInput!) {
    createEvent(data: $payload) {
      _id
    }
  }
`);

const DAFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
  sort: {
    createdAt: -1,
  },
};

export const useActivityLogs = ({ recordId }: { recordId: string }) => {
  const organization = useSelector(getActiveOrganizationData);
  const { data, loading, fetchMore, refetch } = useQuery(
    RECORD_ACTIVITIES_QUERY,
    {
      variables: {
        primaryRecordInput: { id: recordId },
        input: {
          ...DEFAULT_INPUT,
          offset: 0,
        },
      },
    },
  );

  const [addMessageMutation, { loading: isAddingMessage }] =
    useMutation(ADD_MESSAGE_MUTATION);

  const addMessage = ({
    message,
    onCompleted,
    user,
    taggedMemberIds,
  }: AddMessageParams) => {
    addMessageMutation({
      variables: {
        payload: {
          type: EventType.CommentCreated,
          data: {
            avatarLink: user.avatar,
            userName: user.name,
            userEmail: user.email,
            message,
            previousVersions: [],
          },
          contextEntityType: 'party',
          contextEntityId: recordId,
          partyId: recordId,
          partyName: data?.primaryRecord?.name,
          actorType: 'user',
          actorId: user._id,
          organization: organization.id,
          usersToSend: taggedMemberIds,
        },
      },
      onCompleted: () => {
        onCompleted?.();
        refetch?.();
      },
    });
  };

  const activityLogs = data?.primaryRecord?.activities?.nodes || [];

  return {
    logs: activityLogs,
    totalLogsCount: data?.primaryRecord?.activities?.totalCount || 0,
    fetchMoreLogs: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: activityLogs.length,
          },
        },
      });
    },
    isLoading: loading,
    addMessage,
    isAddingMessage,
  };
};
