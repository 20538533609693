import { Collapse } from 'antd';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import styled, { createGlobalStyle, keyframes } from 'styled-components';

const pulse = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`;

const Style = createGlobalStyle`
  .pulse {
    animation: 1s ${pulse} ease-out;
  }
`;

const CollapseSubject = ({
  children,
  subject,
  activeKeys,
  onChange,
  header,
}) => {
  const location = useLocation();
  const { subjectId } = subject;

  useEffect(() => {
    if (location.state?.subjectId === subjectId) {
      const node = document.querySelector(`#s_${subjectId}`);
      node.scrollIntoView({ block: 'center' });
    }
  }, [location.state?.subjectId, subjectId]);

  return (
    <div id={`s_${subjectId}`}>
      <Style />
      <div
        className={R.prop('subjectId', location) === subjectId ? 'pulse' : null}
      >
        <CollapseSubject.Collapse
          className={subjectId}
          activeKey={activeKeys}
          onChange={onChange}
          bordered={false}
        >
          <CollapseSubject.Panel
            key={subjectId}
            header={header}
            showArrow={false}
          >
            {children}
          </CollapseSubject.Panel>
        </CollapseSubject.Collapse>
      </div>
    </div>
  );
};

CollapseSubject.Collapse = styled(Collapse)`
  && {
    width: 100%;

    .ant-collapse-header {
      font-size: 13px;
      color: ${(props) => props.theme.colors.grayishBlue};
    }

    .ant-collapse-item {
      margin-top: 5px;
    }
  }
`;

CollapseSubject.Panel = styled(Collapse.Panel)`
  box-shadow: 0 1px 11px 0 rgba(9, 8, 61, 0.04);
`;

export default CollapseSubject;
