import { FilePill } from '@common/components/FilePill';
import { NoteCard } from '@common/components/NoteCard';
import RichTextEditor from '@common/components/RichTextEditor';
import {
  getEditorHTMLContent,
  getEmptyEditorState,
  hasVisibleText,
  isEditorStateBlank,
  setInitialEditorStateFromHTML,
} from '@common/components/RichTextEditor/helpers';
import { downloadFile } from '@common/utils/document-helpers';
import { useSignedUrl } from '@modules/context-records/containers/AttachmentsTable/hooks/useSignedUrl';
import {
  useDeletePortalAttachment,
  useUpdatePortalMessage,
} from '@modules/portal';
import { SidebarSubtitle } from '@modules/primary-records/components/SidebarSubtitle';
import { Button, Space, Typography, message } from 'antd';
import type { EditorState } from 'draft-js';
import { useState } from 'react';
import styled from 'styled-components';
import { UploadAttachmentModal } from '../UploadAttachmentModal';
import { type PrimaryRecordData, usePortalData } from './hooks/usePortalData';

const getMessageContent = (editorState: EditorState) =>
  isEditorStateBlank(editorState) ? null : getEditorHTMLContent(editorState);

export const SidebarPortal = ({ recordId }: { recordId: string }) => {
  const portalLInk = `${import.meta.env.VITE_PORTAL_URL}/p/${recordId}`;

  const { data, refetchPortalData, loading, fetchMoreAttachments } =
    usePortalData({
      recordId,
      onFetchCompleted: (data: PrimaryRecordData) => {
        const updatedEditorState = data.portal.message
          ? setInitialEditorStateFromHTML(data.portal.message)
          : getEmptyEditorState();

        setEditorState(updatedEditorState);
      },
    });
  const { updatePortalMessage, isUpdatingPortalMessage } =
    useUpdatePortalMessage(recordId);
  const { deletePortalAttachment, isDeletingPortalAttachment } =
    useDeletePortalAttachment(recordId);
  const { signUrl } = useSignedUrl();

  const [isUploadAttachmentModalOpen, setIsUploadAttachmentModalOpen] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editorState, setEditorState] = useState(getEmptyEditorState());

  const updateMessage = async (content: string | null) => {
    try {
      await updatePortalMessage(content);
      await refetchPortalData();
      setIsEditing(false);
      message.success('Portal message saved successfully');
    } catch (error) {
      message.error('Error while saving portal message, please try again');
    }
  };

  const handleOnDelete = () => {
    updateMessage(null);
  };

  const handleCloseUploadAttachmentModal = () => {
    setIsUploadAttachmentModalOpen(false);
  };

  const handleCompleteUploadAttachmentModal = () => {
    setIsUploadAttachmentModalOpen(false);
    refetchPortalData();
  };

  const handleDeleteAttachment = async (s3Key: string) => {
    await deletePortalAttachment(s3Key);
    await refetchPortalData();
  };

  const handleDownloadAttachment = async (
    s3Key: string,
    friendlyName: string,
  ) => {
    try {
      const { data } = await signUrl({ variables: { s3Key } });

      downloadFile(data?.signedUrl, friendlyName);
    } catch (error) {
      console.error('Error downloading attachment:', error);
    }
  };

  const { totalCount: attachmentsCount, nodes: attachments = [] } =
    data?.attachments || {};

  const isLoadMoreAttachmentsButtonVisible =
    Boolean(attachmentsCount) &&
    (attachmentsCount ?? 0) > (attachments?.length ?? 0);

  let MessageSectionContent = null;

  if (isEditing) {
    MessageSectionContent = (
      <StyledTextEditor>
        <RichTextEditor
          className="editor"
          minHeight={32}
          maxHeight={150}
          editorState={editorState}
          disableInlineStyleButtons={false}
          focusDependence={false}
          isOneLineEditor={false}
          isValid
          placeholder="Add message..."
          onChange={(e: EditorState) => setEditorState(e)}
        />
        <Space direction="horizontal" align="center">
          <Button
            onClick={() => {
              setIsEditing(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="saveButton"
            loading={isUpdatingPortalMessage || loading}
            type="primary"
            onClick={() => updateMessage(getMessageContent(editorState))}
          >
            Save
          </Button>
        </Space>
      </StyledTextEditor>
    );
  } else {
    MessageSectionContent = hasVisibleText(data?.message) ? (
      <NoteCard
        note={data?.message}
        onEdit={() => setIsEditing(true)}
        onDelete={handleOnDelete}
      />
    ) : (
      <Button block onClick={() => setIsEditing(true)}>
        Add message
      </Button>
    );
  }

  return (
    <>
      <UploadAttachmentModal
        open={isUploadAttachmentModalOpen}
        primaryRecordId={recordId}
        onCancel={handleCloseUploadAttachmentModal}
        onCompleted={handleCompleteUploadAttachmentModal}
      />
      <StyledWrapper>
        <SidebarSubtitle>Optional message</SidebarSubtitle>
        {MessageSectionContent}
      </StyledWrapper>
      <StyledWrapper>
        <SidebarSubtitle>Attachments</SidebarSubtitle>
        {Boolean(attachments?.length) && (
          <StyledAttachments>
            {attachments.map((attachment) => (
              <FilePill
                key={attachment.s3Key}
                fileName={attachment.friendlyName}
                fileS3Key={attachment.s3Key}
                isDeleting={isDeletingPortalAttachment}
                onDelete={() => handleDeleteAttachment(attachment.s3Key)}
                onDownload={() =>
                  handleDownloadAttachment(
                    attachment.s3Key,
                    attachment.friendlyName,
                  )
                }
              />
            ))}
            {isLoadMoreAttachmentsButtonVisible && (
              <Button
                type="link"
                loading={loading}
                onClick={fetchMoreAttachments}
              >
                Load more
              </Button>
            )}
          </StyledAttachments>
        )}
        <Button onClick={() => setIsUploadAttachmentModalOpen(true)}>
          Upload Attachments
        </Button>
      </StyledWrapper>
      <StyledWrapper>
        <SidebarSubtitle>Portal link</SidebarSubtitle>
        <a target="_blank" rel="noopener noreferrer" href={portalLInk}>
          <StyledLinkText copyable ellipsis={{ rows: 1 }}>
            {portalLInk}
          </StyledLinkText>
        </a>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &&:last-child {
    margin-bottom: 0;
  }
`;

const StyledLinkText = styled(Typography.Paragraph)`
  color: ${(props) => props.theme.colors.blue} !important;
  margin: 0 !important;
  font-size: 12px;
`;

const StyledAttachments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  align-items: flex-start;
`;

const StyledTextEditor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;

  .editor {
    width: 100%;
  }

  .saveButton {
    width: 85px;
  }
`;
