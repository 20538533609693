import {
  MoreOutlined,
  PaperClipOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { ArchivedBanner } from '@common/components/ArchivedBanner';
import { CollapseHeader } from '@common/components/CollapseHeader';
import { ComplianceStats } from '@common/components/ComplianceStats';
import { PageTitle } from '@common/components/PageTitleWrapper';
import { TabButtons } from '@common/components/TabButtons';
import { getAntdIconOutlinedByName } from '@common/utils/get-antd-icon-by-name';
import {
  ArchiveContextRecordModal,
  CONTEXT_RECORDS_TABS,
  DeleteContextRecordModal,
  MakeActiveContextRecordModal,
} from '@modules/context-records';
import { AttachmentsSidebarPanel } from '@modules/context-records/containers/AttachmentsSidebarPanel/AttachmentsSidebarPanel';
import { AttributesSidebar } from '@modules/context-records/containers/AttributesSidebar/AttributesSIdebar';
import type { ContextRecordRoutesParams } from '@modules/router/types';
import { ContextRecordStatus } from '@trustlayer/common';
import { Breadcrumb, Button, Collapse, Divider, Dropdown } from 'antd';
import { DetailsPageLayout } from 'layouts';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import {
  useContextObjectGenerality,
  useContextRecordGenerality,
} from './hooks';

enum ACTIONS_ITEMS_KEYS {
  ARCHIVE = 'archive',
  DELETE = 'delete',
  MAKE_ACTIVE = 'make_active',
}

enum SIDEBAR_PANELS_KEYS {
  ATTRIBUTES = 'attributes',
  ATTACHMENTS = 'attachments',
}

export const ContextRecordDetail = () => {
  const {
    organizationSlugifyName,
    objectSlug,
    recordId = '',
  } = useParams<ContextRecordRoutesParams>();
  const { pathname } = useLocation();
  const tab = pathname.split('/')[5];

  const navigate = useNavigate();

  const { contextRecord, refetchContextRecord } = useContextRecordGenerality({
    contextRecordId: recordId,
  });
  const { contextObject } = useContextObjectGenerality({
    objectSlug,
  });

  const activeTab =
    Object.values(CONTEXT_RECORDS_TABS).find((t) => t.key === tab)?.key ?? '';

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [activeSidebarPanelKeys, setActiveSidebarPanelKeys] = useState<
    string | string[]
  >([SIDEBAR_PANELS_KEYS.ATTRIBUTES, SIDEBAR_PANELS_KEYS.ATTACHMENTS]);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [makeActiveModalOpen, setMakeActiveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleClose = () => {
    navigate(`/${organizationSlugifyName}/c/${objectSlug}`);
  };
  const handleCloseArchiveModal = () => {
    setArchiveModalOpen(false);
  };
  const handleCloseMakeActiveModal = () => {
    setMakeActiveModalOpen(false);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };
  const handleSidebarCollapse = (collapsed: boolean) => {
    if (collapsed) setActiveSidebarPanelKeys([]);
    setIsSidebarCollapsed(collapsed);
  };

  return (
    <DetailsPageLayout>
      <ArchiveContextRecordModal
        open={archiveModalOpen}
        contextRecordId={recordId}
        onCancel={handleCloseArchiveModal}
        onCompleted={() => {
          handleCloseArchiveModal();
          refetchContextRecord();
        }}
      />
      <MakeActiveContextRecordModal
        open={makeActiveModalOpen}
        contextRecordId={recordId}
        onCancel={handleCloseMakeActiveModal}
        onCompleted={() => {
          handleCloseMakeActiveModal();
          refetchContextRecord();
        }}
      />
      <DeleteContextRecordModal
        open={deleteModalOpen}
        contextRecord={{
          _id: recordId,
          name: contextRecord?.name,
        }}
        onCancel={handleCloseDeleteModal}
        onCompleted={() => {
          handleCloseDeleteModal();
          navigate(`/${organizationSlugifyName}/c/${objectSlug}`);
        }}
      />
      {/* TOP CONTENT */}
      <DetailsPageLayout.TopBar>
        <DetailsPageLayout.PageActions onClose={handleClose} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <StyledObjectName>{objectSlug}</StyledObjectName>
          </Breadcrumb.Item>
        </Breadcrumb>
      </DetailsPageLayout.TopBar>

      {/* MAIN CONTENT */}
      <DetailsPageLayout.MainContent>
        {contextRecord?.status === ContextRecordStatus.Archived && (
          <ArchivedBanner>
            {`This ${contextObject?.name} is archived`}
          </ArchivedBanner>
        )}

        <DetailsPageLayout.Header>
          <DetailsPageLayout.Header.TopLeftSection>
            <PageTitle.Composite
              leftTitle={
                <PageTitle
                  title={contextRecord?.name}
                  icon={getAntdIconOutlinedByName(contextObject?.icon)}
                />
              }
              middleIcon={<Divider type="vertical" />}
              rightTitle={
                <ComplianceStats
                  compliantCount={
                    contextRecord?.stats.requestRecords.compliantCount || 0
                  }
                  totalCount={
                    contextRecord?.stats.requestRecords.totalCount || 0
                  }
                />
              }
            />
          </DetailsPageLayout.Header.TopLeftSection>

          <DetailsPageLayout.Header.BottomLeftSection>
            <TabButtons
              tabs={[
                CONTEXT_RECORDS_TABS.Requests,
                CONTEXT_RECORDS_TABS.Documents,
                CONTEXT_RECORDS_TABS.Attributes,
                CONTEXT_RECORDS_TABS.Attachments,
              ]}
              activeTab={activeTab}
              onClick={(tabKey) => {
                navigate(`./${tabKey}`, {
                  relative: 'path',
                });
              }}
            />
          </DetailsPageLayout.Header.BottomLeftSection>
          <DetailsPageLayout.Header.BottomRightSection>
            <Dropdown
              menu={{
                items: [
                  {
                    key: ACTIONS_ITEMS_KEYS.ARCHIVE,
                    label: 'Archive',
                    style: {
                      display:
                        contextRecord?.status === ContextRecordStatus.Archived
                          ? 'none'
                          : 'block',
                    },
                    onClick: () => setArchiveModalOpen(true),
                  },
                  {
                    key: ACTIONS_ITEMS_KEYS.MAKE_ACTIVE,
                    label: 'Make Active',
                    style: {
                      display:
                        contextRecord?.status === ContextRecordStatus.Active
                          ? 'none'
                          : 'block',
                    },
                    onClick: () => setMakeActiveModalOpen(true),
                  },
                  {
                    key: ACTIONS_ITEMS_KEYS.DELETE,
                    label: 'Delete',
                    danger: true,
                    onClick: () => setDeleteModalOpen(true),
                  },
                ],
              }}
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </DetailsPageLayout.Header.BottomRightSection>
        </DetailsPageLayout.Header>
        <DetailsPageLayout.Content>
          <DetailsPageLayout.Body>
            <Outlet context={{ contextRecord }} />
          </DetailsPageLayout.Body>
          <DetailsPageLayout.Sidebar
            collapsed={isSidebarCollapsed}
            onCollapse={handleSidebarCollapse}
            resizeStorageKey="contextRecordSidebarWidth"
          >
            <>
              <Collapse
                destroyInactivePanel
                bordered={false}
                expandIconPosition="end"
                activeKey={activeSidebarPanelKeys}
                expandIcon={isSidebarCollapsed ? () => null : undefined}
                onChange={(data) => {
                  setIsSidebarCollapsed(false);
                  setActiveSidebarPanelKeys(data);
                }}
              >
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<UnorderedListOutlined />}
                      label={isSidebarCollapsed ? '' : 'Attributes'}
                    />
                  }
                  key={SIDEBAR_PANELS_KEYS.ATTRIBUTES}
                >
                  <AttributesSidebar contextRecordId={recordId} />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<PaperClipOutlined />}
                      label={isSidebarCollapsed ? '' : 'Attachments'}
                    />
                  }
                  key={SIDEBAR_PANELS_KEYS.ATTACHMENTS}
                >
                  <AttachmentsSidebarPanel contextRecordId={recordId} />
                </Collapse.Panel>
              </Collapse>
            </>
          </DetailsPageLayout.Sidebar>
        </DetailsPageLayout.Content>
      </DetailsPageLayout.MainContent>
    </DetailsPageLayout>
  );
};

const StyledObjectName = styled.span`
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
`;
