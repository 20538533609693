import type { ServerFilterInput } from '@common/constants/filters/serverFilters';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const ACTIVATE_REQUEST_RECORDS_MUTATION = graphql(`
  mutation ActivateRequestRecords($data: ActivateRequestRecordsInput!) {
    activateRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const useActivateRequestRecordsMutation = () => {
  const [
    activateRequestRecordsMutation,
    { loading: isActivatingRequestRecords },
  ] = useMutation(ACTIVATE_REQUEST_RECORDS_MUTATION);

  const activateRequestRecords = (filters?: ServerFilterInput) => {
    return activateRequestRecordsMutation({
      variables: {
        data: {
          filter: filters,
        },
      },
    });
  };

  return {
    activateRequestRecords,
    isActivatingRequestRecords,
  };
};
