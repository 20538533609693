import DocumentChecklistItem from '@modules/document-checklist/components/DocumentChecklistItem';
import DocumentChecklists from '@modules/document-checklist/components/DocumentChecklists';
import { Form, Typography } from 'antd';
import styled from 'styled-components';
import getUuidByString from 'uuid-by-string';
import type {
  DocumentChecklistFormData,
  DocumentChecklistFormItem,
  DocumentTypeItem,
} from './types';

export const DocumentCheckListForm = ({
  documentTypes,
}: {
  documentTypes: DocumentTypeItem[];
}) => {
  const form = Form.useFormInstance<DocumentChecklistFormData>();

  const documentTypesFormValue = Form.useWatch<DocumentChecklistFormItem[]>(
    ['documentTypesChecklists'],
    form,
  );

  const createNewDocumentType = (
    documentType: DocumentTypeItem,
    attributeLabel: string,
  ) => {
    const newAttribute = {
      type: documentType,
      attributeId: getUuidByString(attributeLabel),
      attributeLabel,
      isActive: true,
    };

    return { ...documentType, checklists: [newAttribute] };
  };

  return (
    <StyledWrapper>
      <Typography.Paragraph>
        Customize the document types associated with this checklist.
      </Typography.Paragraph>
      <StyledContentContainer>
        <Form.List name="documentTypesChecklists">
          {(_, { add }) => (
            <>
              {documentTypes.map((documentType) => {
                const formValueName = (documentTypesFormValue || []).findIndex(
                  (type) => type._id === documentType._id,
                );

                const value: DocumentChecklistFormItem = form.getFieldValue([
                  'documentTypesChecklists',
                  formValueName,
                ]);

                if (!value) {
                  return (
                    <DocumentChecklists
                      itemsCount={0}
                      title={documentType.name}
                      onNewItem={(attributeLabel) => {
                        const newDocumentType = createNewDocumentType(
                          documentType,
                          attributeLabel,
                        );
                        add(newDocumentType);
                      }}
                    />
                  );
                }

                const enabledItems = (value?.checklists || []).filter(
                  (item) => item.isActive && item.attributeId,
                );

                return (
                  <Form.List
                    key={formValueName}
                    name={[formValueName, 'checklists']}
                  >
                    {(fields, { add: addChecklist }) => (
                      <>
                        <DocumentChecklists
                          itemsCount={enabledItems.length}
                          title={documentType.name}
                          onNewItem={(attributeLabel) => {
                            addChecklist({
                              type: documentType,
                              attributeId: getUuidByString(attributeLabel),
                              attributeLabel,
                              isActive: true,
                            });
                          }}
                        >
                          {fields.map((field) => (
                            <Form.Item
                              name={[field.name]}
                              key={field.key}
                              noStyle
                            >
                              <DocumentChecklistItem key={field.key} />
                            </Form.Item>
                          ))}
                        </DocumentChecklists>
                      </>
                    )}
                  </Form.List>
                );
              })}
            </>
          )}
        </Form.List>
      </StyledContentContainer>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;
