import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Skeleton } from 'antd';
import DOMPurify from 'dompurify';
import styled from 'styled-components';

type NoteCardProps = {
  note: string;
  onEdit: () => void;
  onDelete: () => void;
};

export const NoteCard = ({ note, onEdit, onDelete }: NoteCardProps) => {
  return (
    <StyledWrapper>
      <div
        className="note"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note) }}
      />
      <div className="actions">
        <Dropdown
          menu={{
            onClick: ({ key }) => {
              if (key === 'edit') {
                onEdit();
              } else if (key === 'delete') {
                onDelete();
              }
            },
            items: [
              { key: 'edit', label: 'Edit', icon: <EditOutlined /> },
              {
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined />,
                danger: true,
              },
            ],
          }}
        >
          <StyledMenuIcon />
        </Dropdown>
      </div>
    </StyledWrapper>
  );
};

NoteCard.Skeleton = () => {
  return (
    <StyledNoteSkeleton fullSize active>
      {''}
    </StyledNoteSkeleton>
  );
};

const StyledWrapper = styled.div`
  --card-padding: 10px;

  position: relative;
  padding: var(--card-padding);
  padding-right: calc(var(--card-padding) * 3);
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.paleYellow};

  .note {
    font-size: 14px;
  }

  .actions {
    position: absolute;
    top: var(--card-padding);
    right: var(--card-padding);
  }
`;

const StyledNoteSkeleton = styled(Skeleton.Node)`
  width: 100% !important;
`;

const StyledMenuIcon = styled(MoreOutlined)`
  cursor: pointer;
`;
