import { WarningFilled } from '@ant-design/icons';
import { Popconfirm, Tag, Typography } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

interface FilePillProps {
  fileName: string;
  fileS3Key: string;
  onDelete?: () => void;
  onDownload?: (fileS3Key: string) => void;
  isDeleting?: boolean;
}

export const FilePill = ({
  fileS3Key,
  fileName,
  onDelete,
  onDownload,
  isDeleting = false,
}: FilePillProps) => {
  const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(false);
  return (
    <Popconfirm
      open={isPopconfirmOpen}
      title="Delete the file"
      description="Are you sure to delete this file?"
      okText="Yes"
      cancelText="No"
      onConfirm={onDelete}
      okButtonProps={{ loading: isDeleting, danger: true }}
      cancelButtonProps={{ disabled: isDeleting }}
      onCancel={() => setIsPopconfirmOpen(false)}
      icon={<WarningFilled style={{ color: 'red' }} />}
    >
      <StyledTag
        closable
        onClose={(e) => {
          e.preventDefault();
          setIsPopconfirmOpen(true);
        }}
        onClick={() => onDownload?.(fileS3Key)}
      >
        <StyledText ellipsis>{fileName}</StyledText>
      </StyledTag>
    </Popconfirm>
  );
};

const StyledTag = styled(Tag)`
  border-radius: 15px;
  padding: 3px 9px;
  max-width: 100%;
`;

const StyledText = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.lightTextGray};
  font-size: 12px;

  && {
    max-width: 93%;
    cursor: pointer;
  }
`;
