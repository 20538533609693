import styled from 'styled-components';

export const SidebarSubtitle = ({
  children,
}: { children: React.ReactNode }) => {
  return <StyledSubtitle>{children}</StyledSubtitle>;
};

const StyledSubtitle = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.lightTextGray};
  font-weight: normal;
  font-size: 14px;
`;
