import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { mapTableFiltersToServerFilters } from '@common/utils/filters';
import { useCustomLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { mapTableSortToServerSort } from '../../../../../common/utils/filters/mapTableSortToServerSort';
import { SERVER_FILTERS_CONFIG } from '../constants';

export const PRIMARY_RECORDS_QUERY = graphql(`
  query PrimaryRecordsList(
    $primaryObjectInput: PrimaryObjectInput!
    $recordsInput: PrimaryRecordsInput!
  ) {
    primaryObject(input: $primaryObjectInput) {
      _id
      records(input: $recordsInput) {
        totalCount
        nodes {
          _id
          name
          status
          nextComplianceExpiration
          stats {
            requestRecords {
              totalCount
              compliantCount
            }
            documents {
              allReviewed
              unreviewedCount
              totalCount
            }
            messages {
              totalCount
              totalIssuesCount
            }
          }
          tags {
            nodes {
              _id
              color
              expiresAt
              name
            }
          }
        }
      }
    }
  }
`);

export const usePrimaryRecords = ({
  primaryObjectSlug,
}: {
  primaryObjectSlug: string;
}) => {
  const getPrimaryRecordsQuery = useCustomLazyQuery(PRIMARY_RECORDS_QUERY);

  const getPrimaryRecords: GetRowData = useCallback(
    async ({ startRow, endRow, filters, sorts }) => {
      const activeFilters = mapTableFiltersToServerFilters({
        tableFilters: filters,
        serverFiltersConfig: SERVER_FILTERS_CONFIG,
      });

      const sort = mapTableSortToServerSort(sorts);

      const { data } = await getPrimaryRecordsQuery({
        variables: {
          primaryObjectInput: { slug: primaryObjectSlug },
          recordsInput: {
            offset: startRow,
            first: endRow - startRow,
            filter: activeFilters,
            sort,
          },
        },
      });

      const records = data?.primaryObject.records || {};

      return {
        rowData: records.nodes || [],
        rowCount: records.totalCount || 0,
      };
    },
    [primaryObjectSlug, getPrimaryRecordsQuery],
  );

  return {
    getPrimaryRecords,
  };
};
