export const PRIMARY_RECORDS_TABS = {
  Requests: {
    name: 'Requests',
    key: 'requests',
  },
  Activity: {
    name: 'Activity',
    key: 'activity',
  },
  Documents: {
    name: 'Documents',
    key: 'documents',
  },
  Attributes: {
    name: 'Attributes',
    key: 'attributes',
  },
  Contacts: {
    name: 'Contacts',
    key: 'contacts',
  },
  Messages: {
    name: 'Emails',
    key: 'messages',
  },
} as const;

export const PRIMARY_RECORDS_ACTION_TYPES = {
  sendRequest: 'sendRequest',
  enableAutomation: 'enableAutomation',
  disableAutomation: 'disableAutomation',
  archive: 'archive',
  delete: 'delete',
  makeActive: 'makeActive',
  sendEmails: 'sendEmails',
} as const;

export type ActionType =
  (typeof PRIMARY_RECORDS_ACTION_TYPES)[keyof typeof PRIMARY_RECORDS_ACTION_TYPES];

export const PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES = {
  createPrimaryRecords: 'mutation createPrimaryRecords',
  deletePrimaryRecords: 'mutation deletePrimaryRecords',
  archivePrimaryRecords: 'mutation archivePrimaryRecords',
  makeActivePrimaryRecords: 'mutation activatePrimaryRecords',
  sendPrimaryRecordsRequest: 'mutation sendPrimaryRecordsRequest',
} as const;

export type PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES =
  (typeof PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES)[keyof typeof PRIMARY_RECORDS_ASYNC_OPERATIONS_NAMES];
