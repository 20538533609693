import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Button, Collapse, Dropdown, Menu, Modal, Switch } from 'antd';
import * as R from 'ramda';
import { Fragment } from 'react';
import styled from 'styled-components';

import AdCircleInfo from '@common/components/AdCircleInfo';
import DotsIcon from '@common/icons/Dots';
import RequirementContainer from '@modules/compliance-profile/containers/RequirementContainer';
import {
  getIsFillableFormModule,
  getIsSurveyModule,
  getRequirementsByModuleSubject,
} from '@modules/compliance-profile/utils/compliance-attributes-helpers';

import deleteEntityModal from './DeleteEntityModal';

const ComplianceModuleSubjects = ({
  activeKey,
  setActiveKey,
  isCustomSubject,
  isCustomModule,
  activeRequirementsCount,
  onExpand,
  onDisableSubject,
  isRequirements,
  moduleId,
  subject,
  requirements,
  complianceProfile,
  moduleLabel,
  index,
  draggable,
  setCurrentRequirements,
  setEditSubjectId,
  setEditSubjectValue,
}) => {
  const Comp = ({ draggableElementProps }) => (
    <StyledSubjectWrapper data-cy="moduleSubjectWrapper">
      <StyledCollapse
        bordered={false}
        activeKey={activeKey}
        expandIcon={({ isActive }) => (
          <StyledSwitch
            data-cy="removeRequirementsSwitch"
            size="small"
            checked={isActive}
            onClick={(e) => {
              if (isActive && activeRequirementsCount) {
                Modal.confirm({
                  title:
                    'Are you sure you want to remove all requirements within this group?',
                  okText: 'Remove',
                  onOk: () => {
                    onDisableSubject(requirements, subject.subjectId);
                    setActiveKey('');
                    onExpand(e);
                  },
                  okButtonProps: {
                    'data-cy': 'confirmRemoveRequirementsGroup',
                  },
                  onCancel: () => {},
                  zIndex: 1200,
                });
              } else {
                onExpand(e);
              }
            }}
          />
        )}
      >
        <Collapse.Panel
          key={subject.subjectId}
          header={
            <StyledCollapseHeader data-cy="complianceModuleCollapseHeader">
              {draggableElementProps && (
                <StyledDragIconWrapper
                  data-cy="dragModuleSubject"
                  {...draggableElementProps}
                  className="dragIconWrapper"
                >
                  <StyledDragIcon />
                </StyledDragIconWrapper>
              )}
              <div onClick={onExpand}>
                <StyledPanelTitle data-cy="complianceModuleHeaderPanelTitle">
                  {subject.subjectLabel}
                </StyledPanelTitle>
                {isCustomSubject && !isCustomModule && (
                  <AdCircleInfo
                    tooltipText="Custom requirement group"
                    fontSize="15px"
                  />
                )}
                {activeRequirementsCount > 0 && (
                  <StyledRequirementsCount>
                    {activeRequirementsCount} requirement
                    {activeRequirementsCount === 1 ? '' : 's'}
                  </StyledRequirementsCount>
                )}
              </div>
              {isCustomSubject && (
                <StyledKebabContainer>
                  <Dropdown
                    data-cy="dropdownMenu"
                    dropdownRender={() => (
                      <Menu>
                        <Menu.Item
                          key="edit"
                          onClick={() => {
                            setEditSubjectId(subject.subjectId);
                            setEditSubjectValue(subject.subjectLabel);
                          }}
                        >
                          Edit requirement group
                        </Menu.Item>
                        <Menu.Item
                          key="delete"
                          onClick={() => {
                            deleteEntityModal({
                              entityName: 'requirement group',
                              entityLabel: subject.subjectLabel,
                              onOk: () => {
                                const requirementsToUpdate =
                                  requirements.filter(
                                    (x) => x.subjectId !== subject.subjectId,
                                  );
                                const customRequirement = R.head(
                                  requirements.filter(
                                    (x) => x.subjectId === subject.subjectId,
                                  ),
                                );

                                const isOnlyOne =
                                  requirements.filter(
                                    (x) =>
                                      x.moduleId ===
                                      R.prop('moduleId', customRequirement),
                                  ).length ===
                                  requirements.filter(
                                    (x) => x.subjectId === subject.subjectId,
                                  ).length;

                                if (isOnlyOne) {
                                  customRequirement.subjectId = '';
                                  customRequirement.subjectLabel = '';

                                  setCurrentRequirements([
                                    ...requirementsToUpdate,
                                    customRequirement,
                                  ]);
                                } else {
                                  setCurrentRequirements(requirementsToUpdate);
                                }
                              },
                            });
                          }}
                        >
                          Delete requirement group
                        </Menu.Item>
                      </Menu>
                    )}
                    trigger={['click']}
                  >
                    <StyledActionButton shape="circle" type="link">
                      <DotsIcon width="21" height="24" />
                    </StyledActionButton>
                  </Dropdown>
                </StyledKebabContainer>
              )}
            </StyledCollapseHeader>
          }
        >
          <div>
            <Droppable
              droppableId={`${subject.subjectId}`}
              type={`${moduleId}_${subject.subjectId}_attribute`}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {getRequirementsByModuleSubject(
                    moduleId,
                    subject.subjectId,
                    requirements,
                  )
                    .filter((x) => Boolean(x.attributeId))
                    .map((requirement, index) => (
                      <Fragment key={`key_${requirement.attributeId}`}>
                        <RequirementContainer
                          subject={subject}
                          moduleId={moduleId}
                          moduleLabel={moduleLabel}
                          isRequirementRow
                          requirements={requirements}
                          requirement={requirement}
                          complianceProfile={complianceProfile}
                          index={index}
                        />
                      </Fragment>
                    ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {isRequirements(moduleId, subject.subjectId) &&
              !getIsFillableFormModule({ moduleId }) &&
              !getIsSurveyModule({ moduleId }) && (
                <RequirementContainer
                  subject={subject}
                  moduleId={moduleId}
                  moduleLabel={moduleLabel}
                  isRequirementRow={false}
                  requirements={requirements}
                  requirement={{}}
                  complianceProfile={complianceProfile}
                />
              )}
          </div>
        </Collapse.Panel>
      </StyledCollapse>
    </StyledSubjectWrapper>
  );

  if (!subject.subjectId) {
    return null;
  }

  if (!draggable) {
    return <Comp />;
  }

  return (
    <Draggable draggableId={`${subject.subjectId}`} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Comp draggableElementProps={provided.dragHandleProps} />
        </div>
      )}
    </Draggable>
  );
};

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 0 !important;
  }
`;

const StyledSubjectWrapper = styled.div`
  margin: 0 20px;
`;

const StyledCollapseHeader = styled.div`
  padding: 7px 0 7px 40px;
  display: flex;
  align-items: center;

  &:hover {
    .dragIconWrapper {
      div {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
`;

const StyledDragIconWrapper = styled.div`
  position: absolute;
  left: -18px;
  width: 20px;
  height: 16px;
  overflow: hidden;
`;

const StyledDragIcon = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/drag-and-drop.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  transform: translateX(-100%);
  opacity: 0;
  transition:
    transform 0.2s,
    opacity 0.2s;
`;

const StyledSwitch = styled(Switch)`
  &&& {
    &&& {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }
`;

const StyledPanelTitle = styled.span`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
`;

const StyledRequirementsCount = styled.span`
  margin-left: 20px;
  font-size: 12px;
  color: #999;

  ins::before {
    content: '·';
    display: inline-block;
    margin: 0 3.5px;
  }

  ins:hover {
    text-decoration: none;
  }
`;

const StyledKebabContainer = styled.div`
  margin-left: auto;
`;

const StyledActionButton = styled(Button)`
  && {
    opacity: 0.5;
    font-size: 16px;
    top: 2px;
    color: ${(props) => props.theme.colors.paleGray};

    &&:hover {
      opacity: 1;
      color: initial;
    }
  }
`;

export default ComplianceModuleSubjects;
