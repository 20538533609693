import {
  IntegrationAuthType,
  IntegrationProvider,
} from '@graphql/types/graphql';

export const DEFAULT_PROCORE_CREDENTIAL = {
  provider: IntegrationProvider.Procore,
  authType: IntegrationAuthType.OauthFlow,
  externalId: null,
  organizationId: null,
  config: {
    clientId: null,
    clientSecret: null,
  },
};

export const DEFAULT_QUICKBOOKS_CREDENTIAL = {
  provider: IntegrationProvider.Quickbooks,
  authType: IntegrationAuthType.OauthFlow,
  externalId: null,
  organizationId: null,
  config: {
    refreshToken: null,
    refreshTokenExpiresAt: null,
  },
};

export const DEFAULT_VISTA_CREDENTIAL = {
  provider: IntegrationProvider.Vista,
  authType: IntegrationAuthType.Agave,
  externalId: null,
  config: {},
};

export const DEFAULT_CMIC_CREDENTIAL = {
  provider: IntegrationProvider.Cmic,
  authType: IntegrationAuthType.Agave,
  externalId: null,
  config: {},
};

export const defaultIntegrationsCredentials = [
  DEFAULT_PROCORE_CREDENTIAL,
  DEFAULT_QUICKBOOKS_CREDENTIAL,
  DEFAULT_VISTA_CREDENTIAL,
  DEFAULT_CMIC_CREDENTIAL,
  {
    authType: IntegrationAuthType.None,
    externalId: null,
    organizationId: null,
    config: {},
  },
];

export type Providers =
  | 'QUICKBOOKS'
  | 'VISTA'
  | 'PROCORE'
  | 'CMIC'
  | 'SAGE300'
  | 'INTACCT';
type ProvidersConfigs<T extends string> = {
  [key in T]: {
    key: string;
    title: string;
    logoUrl: string;
    supports: string[];
    featureFlag?: string;
  };
};

export const PROVIDERS: ProvidersConfigs<Providers> = {
  PROCORE: {
    key: 'procore',
    title: 'Procore',
    logoUrl: '/images/procore-logo.png',
    supports: ['parties', 'projects'],
  },
  QUICKBOOKS: {
    key: 'quickbooks',
    title: 'QuickBooks',
    logoUrl: '/images/quickbooks-logo.png',
    supports: ['parties'],
  },
  VISTA: {
    key: 'vista',
    title: 'Vista',
    logoUrl: '/images/vista-logo.png',
    supports: ['parties', 'projects'],
  },
  CMIC: {
    key: 'cmic',
    title: 'Cmic',
    logoUrl: '/images/cmic-logo.png',
    supports: ['parties', 'projects'],
  },
  SAGE300: {
    key: 'sage300',
    title: 'Sage 300',
    logoUrl: '/images/sage300-logo.png',
    supports: ['parties', 'projects'],
  },
  INTACCT: {
    key: 'intacct',
    title: 'Intacct',
    logoUrl: '/images/intacct-logo.png',
    supports: ['parties', 'projects'],
  },
};

export const PROVIDERS_LIST = Object.values(PROVIDERS).map((values) => values);
