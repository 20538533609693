import { Empty } from 'antd';
import styled from 'styled-components';

export const TableEmptyState = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <StyledTableEmptyState className={'table-empty-state ' + className}>
      {children}
    </StyledTableEmptyState>
  );
};

TableEmptyState.Image = () => {
  return Empty.PRESENTED_IMAGE_SIMPLE;
};

const StyledTableEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 62px;
  background-color: #FAFAFA;
  border-radius: 4px;

  & > svg {
    width: 150px;
    height: 150px;
  }
`;
