import { InfoCircleOutlined } from '@ant-design/icons';
import { FullWidthSpace } from '@trustlayer/ui';
import { Card, Checkbox, Input, Modal, Popover, Typography } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import FullWidthDatePicker from '@common/components/FullWidthDatePicker';
import { utc } from '@common/utils/date';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import RuleDisplayValue from '@modules/compliance-profile/components/RuleDisplayValue.legacy';
import { getIsCustomRequirement } from '@modules/requirement/utils/requirement-helpers';

const { TextArea } = Input;

const OverrideRequirementModal = ({
  visible,
  requirement,
  onClose,
  onSubmit,
  loading,
}: {
  visible: boolean;
  requirement?: {
    attributeId: string;
    party: string;
    notes: string;
    resetRequirementOn: string;
    organization: string;
  } | null;
  loading: boolean;
  onClose: () => void;
  onSubmit: (props: {
    notes?: string;
    resetRequirementOn?: Date | null;
    applyToAllProjects: boolean;
  }) => void | Promise<void>;
}) => {
  const [notes, setNotes] = useState(requirement?.notes ?? '');
  const [expiringDate, setExpiringDate] = useState<Dayjs | null>(
    requirement?.resetRequirementOn
      ? dayjs(requirement.resetRequirementOn)
      : null,
  );
  const [applyToAllProjects, setToApplyAllProjects] = useState(false);
  const onCancelHandle = () => {
    setNotes('');
    setExpiringDate(null);
    setToApplyAllProjects(false);
    onClose();
  };
  const resetRequirementOn = expiringDate ? utc(expiringDate) : null;
  const onOkHandle = () => {
    if (!requirement) return;

    const res = onSubmit({
      notes,
      resetRequirementOn: resetRequirementOn?.toDate(),
      applyToAllProjects,
    });

    if (res instanceof Promise) {
      res.finally(() => {
        setNotes('');
        setExpiringDate(null);
        setToApplyAllProjects(false);
      });
    } else {
      setNotes('');
      setExpiringDate(null);
      setToApplyAllProjects(false);
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current < dayjs().endOf('day');
  };

  useEffect(() => {
    if (visible) {
      setNotes(requirement?.notes ?? '');
      setExpiringDate(
        requirement?.resetRequirementOn
          ? // The date is stored as UTC but we need to display it in the user's timezone
            dayjs(requirement.resetRequirementOn)
          : null,
      );
    }
  }, [requirement?.notes, requirement?.resetRequirementOn, visible]);

  return (
    <Modal
      title="Override requirement"
      open={visible}
      onCancel={onCancelHandle}
      okButtonProps={{
        // @ts-expect-error - Cypress selector
        'data-cy': 'confirmOverrideButton',
        disabled: !requirement,
      }}
      confirmLoading={loading}
      onOk={onOkHandle}
      centered={true}
      width={685}
      okText="Override"
    >
      <FullWidthSpace direction="vertical" size="middle">
        <Typography.Text>
          Please confirm that you want to override the requirement below:
        </Typography.Text>
        <StyledWrapper size="small">
          <RuleDisplayValue
            rule={requirement}
            isCustom={getIsCustomRequirement(requirement)}
            partyName={undefined}
            orgName={undefined}
          />
        </StyledWrapper>
        <FullWidthSpace direction="vertical" size="small">
          <label>Notes:</label>
          <TextArea
            data-cy="waivedNotesInput"
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </FullWidthSpace>
        <FullWidthSpace direction="vertical" size="small">
          <label>Remove override on:</label>
          <FullWidthDatePicker
            onChange={setExpiringDate}
            value={expiringDate}
            disabledDate={disabledDate}
            format={defaultDateFormats}
          />
        </FullWidthSpace>
        <FullWidthSpace align="center" size="small">
          <Checkbox
            checked={applyToAllProjects}
            onChange={() =>
              setToApplyAllProjects((applyToAllProjects) => !applyToAllProjects)
            }
            id="apply-override-across-all-projects"
          />
          <label htmlFor="apply-override-across-all-projects">
            Apply override across all projects
          </label>
          <Popover
            content={
              <>
                This waiver will be applied to all <strong>existing</strong> and{' '}
                <strong>future</strong> projects
              </>
            }
          >
            <InfoCircleOutlined />
          </Popover>
        </FullWidthSpace>
      </FullWidthSpace>
    </Modal>
  );
};

const StyledWrapper = styled(Card)`
  background-color: #f7f9fc;
`;

export default OverrideRequirementModal;
