import { CloseCircleFilled } from '@ant-design/icons';
import { Alert, Divider } from 'antd';
import pluralize from 'pluralize';
import { useState } from 'react';
import styled from 'styled-components';

import FileName from '@common/components/FileName/FileName';
import { downloadDocumentFile } from '@modules/document/utils/document-helpers';
import DocumentUploader from '@modules/request/components/DocumentUploader';

interface Attachment {
  _id: string;
  s3Key: string;
  friendlyName: string;
  url?: string;
}

interface RejectedFile {
  file: File;
}

interface ProjectAttachmentsTabContainerProps {
  uploadFiles: File[];
  onSetUploadFiles: (files: File[]) => void;
  attachments: Attachment[];
  onSetAttachments: (attachments: Attachment[]) => void;
}

const ProjectAttachmentsTabContainer = ({
  uploadFiles,
  onSetUploadFiles,
  attachments,
  onSetAttachments,
}: ProjectAttachmentsTabContainerProps) => {
  const [rejectedFiles, setRejectedFiles] = useState<RejectedFile[]>([]);

  return (
    <>
      <StyledTitle>
        Attach one or more files to this project using the control below:
      </StyledTitle>
      <DocumentUploader
        onUpload={(acceptedFiles: File[], rejectedFiles: RejectedFile[]) => {
          onSetUploadFiles(acceptedFiles);
          setRejectedFiles(rejectedFiles);
        }}
      />
      <StyledFilesWrapper>
        {rejectedFiles?.length > 0 && (
          <StyledAlert
            type="error"
            description={
              <>
                <p>
                  The files below cannot be uploaded because the file format is
                  not supported or is corrupted
                </p>
                <StyledRejectedFiles>
                  {rejectedFiles.map(({ file }) => (
                    <li key={`key_${file.name}`}>{file.name}</li>
                  ))}
                </StyledRejectedFiles>
              </>
            }
            icon={<CloseCircleFilled />}
            showIcon
          />
        )}
        {uploadFiles.length > 0 && (
          <>
            <StyledFilesTitle>
              {pluralize('attachment', uploadFiles.length, true)} ready to be
              uploaded:
            </StyledFilesTitle>
            <StyledFileWrapper>
              {uploadFiles.map((file) => (
                <FileName
                  key={`key_${file.name}`}
                  filename={file.name}
                  // @ts-expect-error - Missing types for FileName component
                  onRemove={() =>
                    onSetUploadFiles(
                      uploadFiles.filter(
                        (uploadFile) => file.name !== uploadFile.name,
                      ),
                    )
                  }
                />
              ))}
            </StyledFileWrapper>
          </>
        )}
      </StyledFilesWrapper>
      <Divider />
      <StyledFilesWrapper>
        {Boolean(attachments.length) && (
          <StyledFileWrapper>
            {attachments.map((attachment) => (
              <FileName
                key={`key_${attachment._id}`}
                filename={attachment.friendlyName}
                onClick={() => downloadDocumentFile(attachment)}
                confirmRemoveMessage={`Are you sure you want to remove the attachment ${attachment.friendlyName}?`}
                // @ts-expect-error - Missing types for FileName component
                onRemove={() =>
                  onSetAttachments(
                    attachments.filter((el) => el._id !== attachment._id),
                  )
                }
              />
            ))}
          </StyledFileWrapper>
        )}
      </StyledFilesWrapper>
    </>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 30px;
`;

const StyledRejectedFiles = styled.ul`
  padding-left: 30px;
`;

const StyledTitle = styled.div`
  font-size: 13px;
  margin-bottom: 30px;
`;

const StyledFilesTitle = styled.div`
  font-size: 13px;
  margin: 10px 0;
`;

const StyledFilesWrapper = styled.div`
  margin: 16px 0;
`;

const StyledFileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > div {
    width: auto;
    margin-right: 5px;
  }
`;

export default ProjectAttachmentsTabContainer;
