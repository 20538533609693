import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { PrimaryRecord_AdditionalNotesQuery } from '@graphql/types/graphql';

export type PrimaryRecordNote =
  PrimaryRecord_AdditionalNotesQuery['primaryRecord']['additionalNotes'];

export const ADDITIONAL_NOTES_QUERY = graphql(`
  query PrimaryRecord_AdditionalNotes($primaryRecordInput: PrimaryRecordInput!) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      additionalNotes {
        message
      }
    }
  }
`);

export const usePrimaryRecordNote = ({
  primaryRecordId,
  onCompleted,
}: {
  primaryRecordId: string;
  onCompleted?: (notes: PrimaryRecordNote) => void;
}) => {
  const { data, loading, error, refetch } = useQuery(ADDITIONAL_NOTES_QUERY, {
    variables: { primaryRecordInput: { id: primaryRecordId } },
    onCompleted: (data) => {
      onCompleted?.(data?.primaryRecord.additionalNotes);
    },
    skip: !primaryRecordId,
    notifyOnNetworkStatusChange: true,
  });

  return {
    notes: data?.primaryRecord.additionalNotes || [],
    loading,
    error,
    refetch,
  };
};
