import { useIsContextsAddOnEnabled } from '@modules/add-on';
import {
  DOCUMENT_ACTION_TYPES,
  DocumentsWrapper,
  useConnectionBetweenTableAndURL as useDocumentsTableStateAndURL,
} from '@modules/document';
import type { ActionType } from '@modules/document/constants';
import useMemberRole from '@modules/organization-member/hooks/useMemberRole';
import { ROLES } from '@modules/organization-member/models/data';

import { getCurrentProject } from '@modules/project/selectors';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const EXCLUDED_ACTIONS = [
  DOCUMENT_ACTION_TYPES.assignToParty,
  DOCUMENT_ACTION_TYPES.detachFromParty,
  DOCUMENT_ACTION_TYPES.associateToProject,
];

const VIEWER_EXCLUDED_ACTIONS = Object.values(DOCUMENT_ACTION_TYPES);

export function PrimaryRecordDocuments() {
  const { isContextsAddOnEnabled } = useIsContextsAddOnEnabled();

  const {
    updateURLFromTableState: handleTableStateChanged,
    getTableStateFromURL: getDocumentsTableState,
  } = useDocumentsTableStateAndURL();
  const { recordId, organizationSlugifyName } =
    useParams<PrimaryRecordsRoutesParams>();
  const currentProjectId = useSelector(
    getCurrentProject as (state: any) => { _id: string },
  )._id;

  const memberRole = useMemberRole();

  const excludedActions = useMemo(() => {
    let excludedActions: ActionType[] = [];

    if (memberRole === ROLES.VIEWER) {
      excludedActions = [...excludedActions, ...VIEWER_EXCLUDED_ACTIONS];
    }

    if (!isContextsAddOnEnabled) {
      excludedActions = [
        ...excludedActions,
        DOCUMENT_ACTION_TYPES.associateToProject,
        DOCUMENT_ACTION_TYPES.detachFromProject,
        DOCUMENT_ACTION_TYPES.matchRequirements,
        DOCUMENT_ACTION_TYPES.addRequirement,
      ];
    }

    return [...excludedActions, ...EXCLUDED_ACTIONS];
  }, [isContextsAddOnEnabled, memberRole]);

  return (
    <DocumentsWrapper
      showViews={false}
      initialState={getDocumentsTableState()}
      partyId={recordId}
      projectId={currentProjectId}
      organizationSlugifyName={organizationSlugifyName!}
      excludedActions={excludedActions}
      onStateChanged={handleTableStateChanged}
    />
  );
}
