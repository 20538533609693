import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { setOrganizationSlugifyName } from '@modules/organization/actions';

const useOrganizationSlugify = () => {
  const { organizationSlugifyName } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    if (organizationSlugifyName) {
      dispatch(setOrganizationSlugifyName(organizationSlugifyName));
    }
  }, [dispatch, organizationSlugifyName]);

  return true;
};

export default useOrganizationSlugify;
