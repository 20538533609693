import { SaveOutlined } from '@ant-design/icons';
import { LoadingSpinner } from '@common/components/Spinner/LoadingSpinner';
import { withControlledMount } from '@common/hoc/withControlledMount';
import {
  ComplianceProfilesSelect,
  type ProfileValue,
} from '@modules/compliance-profile/containers/ComplianceProfilesSelect/ComplianceProfilesSelect';
import { ChecklistProfileAlert } from '@modules/document-checklist/components/ChecklistProfileAlert';
import type { DocumentChecklist } from '@modules/document-checklist/types';
import { Button, Form } from 'antd';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  DocumentCheckListForm,
  type DocumentChecklistFormData,
} from '../../components/DocumentCheckListForm';

import {
  DocumentCheckListFormDrawer,
  getFormValuesAsUpdatePayload,
} from '@modules/document-checklist/components/DocumentCheckListFormDrawer';
import { getFormInitialValues } from '@modules/document-checklist/components/DocumentCheckListFormDrawer';
import { useCreateDocumentChecklistMutation } from '@modules/document-checklist/hooks/useCreateDocumentChecklistMutation';
import { useDocumentChecklistsQuery } from '@modules/document-checklist/hooks/useDocumentChecklistsQuery';
import { useDocumentTypesQuery } from '@modules/document-checklist/hooks/useDocumentTypesQuery';
import { useUpdateDocumentChecklistMutation } from '@modules/document-checklist/hooks/useUpdateDocumentChecklistMutation';
import { useAssignComplianceProfileMutation } from './hooks';

type RequestRecordDocumentCheckListFormDrawerProps = {
  requestRecord: {
    _id: string;
    name: string;
  };
  checklist: ProfileValue;
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const RequestRecordDocumentCheckListFormDrawerInner = ({
  requestRecord,
  checklist,
  onClose,
  onSuccess,
  visible,
}: RequestRecordDocumentCheckListFormDrawerProps) => {
  const requirementsChangedRef = useRef(false);
  const [currentComplianceProfile, setCurrentComplianceProfile] =
    useState<ProfileValue>(checklist);

  const { complianceProfile, isComplianceProfileLoading } =
    useDocumentChecklistsQuery({
      checklistId:
        typeof currentComplianceProfile === 'string'
          ? currentComplianceProfile
          : currentComplianceProfile?._id,
      onCompleted: (data) => {
        form.setFieldsValue(
          getFormInitialValues(
            data.documentChecklists as DocumentChecklist[],
            data.name || '',
          ),
        );
        requirementsChangedRef.current = false;
      },
    });

  const { documentTypes, isDocumentTypesLoading } = useDocumentTypesQuery({
    skip: !visible,
  });

  const [form] = Form.useForm<DocumentChecklistFormData>();

  const { createDocumentChecklist, isCreateDocumentChecklistLoading } =
    useCreateDocumentChecklistMutation();
  const { updateDocumentChecklist, isUpdateDocumentChecklistLoading } =
    useUpdateDocumentChecklistMutation();
  const { assignComplianceProfile, isAssignComplianceProfileLoading } =
    useAssignComplianceProfileMutation();

  const isSubmitting =
    isCreateDocumentChecklistLoading ||
    isUpdateDocumentChecklistLoading ||
    isAssignComplianceProfileLoading;

  const handleSubmit = async (values: DocumentChecklistFormData) => {
    const payload = getFormValuesAsUpdatePayload(
      values,
      currentComplianceProfile._id,
    );

    const profileChanged = currentComplianceProfile._id !== checklist._id;

    // when change a custom checklist, we need to update the compliance profile
    if (!profileChanged && checklist.baseComplianceProfile) {
      payload.context = 'requestRecord';
      payload.contextId = requestRecord._id;
      payload.name = checklist.name;
      payload.baseComplianceProfile = checklist.baseComplianceProfile;

      await updateDocumentChecklist({
        variables: { payload },
      });
      return onSuccess();
    }

    if (!profileChanged && !requirementsChangedRef.current) {
      return onClose();
    }

    // reassign the compliance profile to the request record
    if (profileChanged && !requirementsChangedRef.current) {
      await assignComplianceProfile({
        variables: {
          data: {
            id: currentComplianceProfile._id,
            requestRecords: [{ requestRecordId: requestRecord._id }],
          },
        },
      });
      return onSuccess();
    }

    // create custom checklist
    payload.context = 'requestRecord';
    payload.contextId = requestRecord._id;
    payload.name = currentComplianceProfile.name;
    payload.baseComplianceProfile = currentComplianceProfile._id;

    delete payload._id;

    const { data } = await createDocumentChecklist({ variables: { payload } });

    await assignComplianceProfile({
      variables: {
        data: {
          id: data?.createComplianceProfile._id,
          requestRecords: [{ requestRecordId: requestRecord._id }],
        },
      },
    });
    return onSuccess();
  };

  const isEditingLoading = checklist._id && isComplianceProfileLoading;
  const isLoading = isEditingLoading || isDocumentTypesLoading;

  return (
    <DocumentCheckListFormDrawer
      onClose={onClose}
      onSubmit={handleSubmit}
      visible={visible}
      form={form}
      onValuesChange={() => {
        if (isLoading) return;
        requirementsChangedRef.current = true;
      }}
    >
      <StyledHeader>
        <StyledNameContainer>
          <div className="label">Checklist</div>
          <div className="field">
            <ComplianceProfilesSelect
              value={currentComplianceProfile}
              onChange={(value) => setCurrentComplianceProfile(value)}
            />
          </div>
        </StyledNameContainer>

        <Button
          data-cy="saveProfileButton"
          icon={<SaveOutlined />}
          size="large"
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
        >
          Save Changes
        </Button>
      </StyledHeader>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {complianceProfile?._id && (
            <ChecklistProfileAlert
              requestName={requestRecord.name}
              profileName={complianceProfile?.name || ''}
            />
          )}
          <DocumentCheckListForm documentTypes={documentTypes} />
        </>
      )}
    </DocumentCheckListFormDrawer>
  );
};

const StyledNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  gap: 20px;

  .field {
    flex: 1;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 20px;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.veryPaleBlue};
  margin-bottom: 20px;
  gap: 20px;

  h3 {
    margin: 0;
  }
`;

export const RequestRecordDocumentCheckListFormDrawer = withControlledMount(
  RequestRecordDocumentCheckListFormDrawerInner,
  {
    mountWhen: ({ visible }) => visible,
  },
);
