import { CollapseCard } from '@common/components/CollapseCard';
import Flex from '@common/components/Flex';
import useSetState from '@common/hooks/useSetState';
import type {
  ComplianceModule,
  ComplianceRequirement,
  ComplianceSubject,
  FillableForm,
} from '@graphql/types/graphql';
import ComplianceProfileDrawer from '@modules/compliance-profile/containers/ComplianceProfileDrawer';
import { useComplianceProfileEditor } from '@modules/feature-flags/hooks';
import { FIELD_ASSOCIATION_TYPES } from '@modules/fillable-form/constants';
import { FillableFormSignContainer } from '@modules/fillable-form/containers/FillableFormSignContainer';
import { HideForViewerRole } from '@modules/organization-member';
import {
  ACTION_TYPES,
  CircleProgress,
  ComplianceSummary,
  ActionKeys as ComplianceSummaryActionKeys,
  Requirement,
  Subject,
} from '@modules/request-records';
import { RequestRecordDocumentsChecklist } from '@modules/request-records/components/RequestRecordDocumentsChecklist';
import { getRequirementActions } from '@modules/request-records/components/Requirement/Requirement.utils';
import { getSubjectActions } from '@modules/request-records/components/Subject/Subject.utils';
import { SubjectFillableForm } from '@modules/request-records/components/SubjectFillableForm';
import { AssignComplianceProfileModal } from '@modules/request-records/containers/AssignComplianceProfileModal';
import { UnassignComplianceProfileModal } from '@modules/request-records/containers/UnassignComplianceProfileModal/UnassignComplianceProfileModal';
import type { RequestRecordsRoutesParams } from '@modules/router/types';
import { RequirementComplianceStatus } from '@trustlayer/common';
import { FullWidthSpace, ThreeDotsMenu } from '@trustlayer/ui';
import { Card, Empty, Result, Row, Typography, message } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { NoteModal } from './components/NoteModal';
import { RemoveWaiveOverrideModal } from './containers/RemoveWaiveOverrideModal/RemoveWaiveOverrideModal';
import {
  ActionType as ModalActionType,
  ItemType as ModalItemType,
  WaiveAndOverrideModal,
} from './containers/WaiveAndOverrideModal';
import { getActionKey } from './containers/WaiveAndOverrideModal/WaiveAndOverrideModal.utils';
import { usePublicNote } from './hooks/usePublicNote';
import { useRequestRecordComplianceQuery } from './hooks/useRequestRecordComplianceQuery';

type SubjectData = {
  code: ComplianceSubject['code'];
  label: string;
  status: ComplianceSubject['status'];
  notes?: ComplianceSubject['notes'];
  resetOn?: ComplianceSubject['resetOn'];
  publicNote?: ComplianceRequirement['publicNotes'];
};

type RequirementData = {
  code: ComplianceRequirement['attributeCode'];
  label: string;
  publicNote?: ComplianceRequirement['publicNotes'];
  status: ComplianceRequirement['status'];
  notes?: ComplianceRequirement['notes'];
  resetOn?: ComplianceRequirement['resetOn'];
};

type WaiveAndOverrideModalState =
  | {
      visible: false;
      actionType?: ModalActionType;
      itemType?: ModalItemType;
      itemData?: SubjectData | RequirementData;
    }
  | {
      visible: true;
      actionType: ModalActionType;
      itemType: ModalItemType;
      itemData: SubjectData | RequirementData;
    };

type FillableFormSignModalState =
  | {
      visible: false;
      fillableForm?: FillableForm;
    }
  | {
      visible: true;
      fillableForm: FillableForm;
    };

type NoteModalState =
  | {
      visible: false;
      isSubmitting?: boolean;
    }
  | {
      visible: true;
      itemData: SubjectData | RequirementData;
      isSubmitting: boolean;
      isEdit: boolean;
    };

type RemoveWaiverOverrideModalState =
  | {
      visible: false;
      actionType?: ModalActionType;
      itemType?: ModalItemType;
      itemData?: {
        code: SubjectData['code'] | RequirementData['code'];
      };
    }
  | {
      visible: true;
      actionType: ModalActionType;
      itemType: ModalItemType;
      itemData: {
        code: SubjectData['code'] | RequirementData['code'];
      };
    };

export function RequestRecordCompliance() {
  const [noteModalState, setNoteModalState] = useSetState<NoteModalState>({
    visible: false,
  });

  const { isComplianceProfileEditorFeatureFlagEnabled } =
    useComplianceProfileEditor();

  const [isComplianceEditorVisible, setIsComplianceEditorVisible] =
    useState(false);

  const [
    isAssignComplianceProfileModalVisible,
    setIsAssignComplianceProfileModalVisible,
  ] = useState(false);

  const [
    isUnassignComplianceProfileModalVisible,
    setIsUnassignComplianceProfileModalVisible,
  ] = useState(false);

  const [waiveAndOverrideModalState, setWaiveAndOverrideModalState] =
    useSetState<WaiveAndOverrideModalState>({
      visible: false,
    });
  const [removeWaiverOverrideModalState, setRemoveWaiverOverrideModalState] =
    useState<RemoveWaiverOverrideModalState>({
      visible: false,
    });

  const [fillableFormSignModalState, setFillableFormSignModalState] =
    useState<FillableFormSignModalState>({
      visible: false,
    });

  const { requestId, recordId } = useParams<RequestRecordsRoutesParams>();

  const {
    requestRecord,
    loadingRequestRecordCompliance,
    errorRequestRecordCompliance,
    refetchRequestRecordCompliance,
  } = useRequestRecordComplianceQuery({ requestId });

  const { createPublicNote, deletePublicNote, isDeletingNoteList } =
    usePublicNote();
  const complianceProfile = requestRecord?.complianceProfile;
  const complianceStats = requestRecord?.complianceStats;

  const documentsChecklist = requestRecord?.documentsChecklist || [];

  // TODO replace 'archived' with proper constant as soon as PR 4300 is mergerd
  const isRequestRecordArchived = requestRecord?.status === 'archived';

  const complianceModules = (requestRecord?.complianceModules ?? []).filter(
    (m) => m,
  ) as ComplianceModule[];

  const handleRequirementActionClick = ({
    actionKey,
    requirementData,
  }: { actionKey: ACTION_TYPES; requirementData: RequirementData }) => {
    if (actionKey === ACTION_TYPES.addNote) {
      setNoteModalState({
        visible: true,
        itemData: requirementData,
        isEdit: false,
      });
    }

    if (actionKey === ACTION_TYPES.editNote) {
      setNoteModalState({
        visible: true,
        itemData: requirementData,
        isEdit: true,
      });
    }

    if (
      actionKey === ACTION_TYPES.waive ||
      actionKey === ACTION_TYPES.override ||
      actionKey === ACTION_TYPES.edit
    ) {
      setWaiveAndOverrideModalState({
        visible: true,
        actionType: getActionKey(actionKey, requirementData.status),
        itemType: ModalItemType.requirement,
        itemData: requirementData,
      });
    }

    if (actionKey === ACTION_TYPES.remove) {
      setRemoveWaiverOverrideModalState({
        visible: true,
        actionType:
          requirementData.status === RequirementComplianceStatus.Waived
            ? ModalActionType.waive
            : ModalActionType.override,
        itemType: ModalItemType.requirement,
        itemData: {
          code: requirementData.code,
        },
      });
    }
  };

  const handleSucceededWaiveOverrideModal = () => {
    setWaiveAndOverrideModalState({ visible: false });
    refetchRequestRecordCompliance();
  };

  const handleSucceededRemoveWaiveOverrideModal = () => {
    setRemoveWaiverOverrideModalState({ visible: false });
    refetchRequestRecordCompliance();
  };

  const handleSubjectActionClick = ({
    actionKey,
    subjectData,
  }: { actionKey: ACTION_TYPES; subjectData: SubjectData }) => {
    if (
      actionKey === ACTION_TYPES.waive ||
      actionKey === ACTION_TYPES.override ||
      actionKey === ACTION_TYPES.edit
    ) {
      setWaiveAndOverrideModalState({
        visible: true,
        actionType: getActionKey(actionKey, subjectData.status),
        itemType: ModalItemType.subject,
        itemData: subjectData,
      });
    }

    if (actionKey === ACTION_TYPES.remove) {
      setRemoveWaiverOverrideModalState({
        visible: true,
        actionType:
          subjectData.status === RequirementComplianceStatus.Waived
            ? ModalActionType.waive
            : ModalActionType.override,
        itemType: ModalItemType.subject,
        itemData: {
          code: subjectData.code,
        },
      });
    }
  };

  const handleAddRequirementNote = (note: string) => {
    if (!noteModalState.visible) return;
    setNoteModalState({ isSubmitting: true });

    createPublicNote({
      variables: {
        attributeCode: noteModalState.itemData.code,
        id: requestId!,
        publicNotes: note,
      },
      onCompleted: () => {
        setNoteModalState({ isSubmitting: false, visible: false });
        refetchRequestRecordCompliance();
      },
      onError: () => {
        message.error('Failed to add a note, please try again later.');
        setNoteModalState({ isSubmitting: false });
      },
    });
  };
  const handleRemoveRequirementNote = (code: string) => {
    deletePublicNote({
      variables: {
        attributeCode: code,
        id: requestId!,
      },
      onCompleted: () => {
        refetchRequestRecordCompliance();
      },
      onError: () => {
        message.error('Failed to remove note, please try again later.');
      },
    });
  };

  if (errorRequestRecordCompliance) {
    return (
      <StyledErrorWrapper direction="vertical" justify="center">
        <Result
          status="500"
          title="500"
          subTitle="Oops! Something went wrong. Please try again later."
        />
      </StyledErrorWrapper>
    );
  }

  const handleComplianceSummaryActionClick = (
    actionKey: ComplianceSummaryActionKeys,
  ) => {
    if (actionKey === ComplianceSummaryActionKeys.edit) {
      if (isComplianceProfileEditorFeatureFlagEnabled) {
        return setIsComplianceEditorVisible(true);
      }
      return setIsAssignComplianceProfileModalVisible(true);
    }

    if (actionKey === ComplianceSummaryActionKeys.assign) {
      setIsAssignComplianceProfileModalVisible(true);
    }

    if (actionKey === ComplianceSummaryActionKeys.unassign) {
      setIsUnassignComplianceProfileModalVisible(true);
    }
  };
  return (
    <>
      {requestId && (
        <>
          <ComplianceProfileDrawer
            complianceProfileId={complianceProfile?._id}
            requestRecordId={requestId}
            partyName={requestRecord?.name}
            open={isComplianceEditorVisible}
            onClose={() => setIsComplianceEditorVisible(false)}
            onSaved={() => {
              refetchRequestRecordCompliance();
            }}
          />

          <WaiveAndOverrideModal
            open={waiveAndOverrideModalState.visible}
            actionType={waiveAndOverrideModalState?.actionType!}
            itemType={waiveAndOverrideModalState?.itemType!}
            itemData={waiveAndOverrideModalState?.itemData!}
            requestRecordId={requestId}
            onCancel={() => setWaiveAndOverrideModalState({ visible: false })}
            onCompleted={handleSucceededWaiveOverrideModal}
            defaultState={{
              notes: waiveAndOverrideModalState?.itemData?.notes,
              expiringDate: waiveAndOverrideModalState?.itemData?.resetOn,
              status: waiveAndOverrideModalState?.itemData?.status,
            }}
          >
            {waiveAndOverrideModalState?.itemType === ModalItemType.subject &&
            waiveAndOverrideModalState?.itemData?.label &&
            waiveAndOverrideModalState?.actionType ? (
              <WaiveAndOverrideModal.SubjectInfo
                name={waiveAndOverrideModalState?.itemData?.label}
                actionType={waiveAndOverrideModalState?.actionType}
              />
            ) : (
              <span>{waiveAndOverrideModalState?.itemData?.label}</span>
            )}
          </WaiveAndOverrideModal>
          <RemoveWaiveOverrideModal
            open={removeWaiverOverrideModalState.visible}
            actionType={removeWaiverOverrideModalState.actionType!}
            itemType={removeWaiverOverrideModalState.itemType!}
            itemData={removeWaiverOverrideModalState.itemData!}
            requestRecordId={requestId}
            onCancel={() =>
              setRemoveWaiverOverrideModalState({ visible: false })
            }
            onCompleted={handleSucceededRemoveWaiveOverrideModal}
          />

          <AssignComplianceProfileModal
            isShow={isAssignComplianceProfileModalVisible}
            requestRecordId={requestId}
            complianceProfile={complianceProfile!}
            onSuccess={() => {
              setIsAssignComplianceProfileModalVisible(false);
              refetchRequestRecordCompliance();
            }}
            onClose={() => setIsAssignComplianceProfileModalVisible(false)}
          />

          <UnassignComplianceProfileModal
            open={isUnassignComplianceProfileModalVisible}
            requestRecordId={requestId}
            onCancel={() => setIsUnassignComplianceProfileModalVisible(false)}
            onCompleted={() => {
              setIsUnassignComplianceProfileModalVisible(false);
              refetchRequestRecordCompliance();
            }}
          />
          <FillableFormSignContainer
            open={fillableFormSignModalState.visible}
            onClose={() => setFillableFormSignModalState({ visible: false })}
            onSuccess={() => {
              refetchRequestRecordCompliance();
            }}
            fillableForm={fillableFormSignModalState.fillableForm!}
            primaryRecordId={recordId!}
            contextRecord={requestRecord?.contextRecord!}
            requestId={requestId}
            filler={FIELD_ASSOCIATION_TYPES.REQUESTER}
          />
        </>
      )}
      {noteModalState.visible && (
        <NoteModal
          isEdit={noteModalState.isEdit}
          isSubmitting={noteModalState.isSubmitting}
          onClose={() => setNoteModalState({ visible: false })}
          defaultValue={noteModalState.itemData.publicNote}
          onSubmit={handleAddRequirementNote}
        />
      )}
      <StyledWrapper size="small" direction="vertical">
        <ComplianceSummary
          hideActions={isRequestRecordArchived}
          hasProfile={Boolean(complianceProfile)}
          isCustomComplianceProfile={Boolean(
            complianceProfile?.baseComplianceProfile,
          )}
          name={complianceProfile?.name}
          onActionClick={handleComplianceSummaryActionClick}
          loading={loadingRequestRecordCompliance}
        >
          <CircleProgress
            currentActiveItemsCount={
              !loadingRequestRecordCompliance &&
              complianceStats?.compliantSubjects !== undefined
                ? complianceStats?.compliantSubjects
                : 0
            }
            totalItemsCount={
              !loadingRequestRecordCompliance &&
              complianceStats?.totalSubjects !== undefined
                ? complianceStats?.totalSubjects
                : 0
            }
          />
        </ComplianceSummary>

        {loadingRequestRecordCompliance ? (
          <>
            <Card loading />
            <Card loading />
            <Card loading />
          </>
        ) : (
          <>
            {Boolean(complianceModules.length) &&
              complianceModules.map((module) => (
                <CollapseCard key={module.code}>
                  <CollapseCard.Header>
                    <StyledTitle level={5}>{module.label}</StyledTitle>
                  </CollapseCard.Header>
                  <CollapseCard.Body>
                    {/* SUBJECTS */}
                    {module.subjects.length ? (
                      <div>
                        {module.subjects.map((subject) => (
                          <Subject
                            key={subject.code}
                            subject={subject}
                            actionsComponent={
                              isRequestRecordArchived ? null : (
                                <HideForViewerRole>
                                  <ThreeDotsMenu<ACTION_TYPES>
                                    items={getSubjectActions(subject.status)}
                                    onClick={(actionKey) =>
                                      handleSubjectActionClick({
                                        actionKey,
                                        subjectData: {
                                          label: subject.label,
                                          code: subject.code,
                                          status: subject.status,
                                          resetOn: subject.resetOn,
                                          notes: subject.notes,
                                        },
                                      })
                                    }
                                  />
                                </HideForViewerRole>
                              )
                            }
                          >
                            {/* REQUIREMENTS */}
                            {subject.requirements.length > 0 ? (
                              subject.requirements.map((requirement) => (
                                <Row
                                  key={requirement.attributeCode}
                                  gutter={12}
                                  align="top"
                                  wrap={false}
                                >
                                  <Requirement
                                    requirement={requirement}
                                    subjectStatus={subject.status}
                                    actionsComponent={
                                      isRequestRecordArchived ? null : (
                                        <HideForViewerRole>
                                          <ThreeDotsMenu<ACTION_TYPES>
                                            items={getRequirementActions({
                                              status: requirement.status,
                                              hasNote: Boolean(
                                                requirement.publicNotes,
                                              ),
                                              inherited:
                                                subject.status ===
                                                  RequirementComplianceStatus.Overridden ||
                                                subject.status ===
                                                  RequirementComplianceStatus.Waived,
                                            })}
                                            onClick={(actionKey) => {
                                              handleRequirementActionClick({
                                                actionKey,
                                                requirementData: {
                                                  label:
                                                    requirement.attributeLabel,
                                                  code: requirement.attributeCode,
                                                  publicNote:
                                                    requirement.publicNotes,
                                                  status: requirement.status,
                                                  resetOn: requirement.resetOn,
                                                  notes: requirement.notes,
                                                },
                                              });
                                            }}
                                          />
                                        </HideForViewerRole>
                                      )
                                    }
                                    onDeletePublicNote={() =>
                                      handleRemoveRequirementNote(
                                        requirement.attributeCode,
                                      )
                                    }
                                    isDeletingPublicNote={isDeletingNoteList?.includes(
                                      requirement.attributeCode,
                                    )}
                                  />
                                </Row>
                              ))
                            ) : (
                              <Empty description="No requirements found for this subject" />
                            )}
                            <SubjectFillableForm
                              fillableForm={requestRecord?.fillableForms.find(
                                (form) => form._id === subject.code,
                              )}
                              onFillClick={(form) =>
                                setFillableFormSignModalState({
                                  visible: true,
                                  fillableForm: form,
                                })
                              }
                            />
                          </Subject>
                        ))}
                      </div>
                    ) : (
                      <Empty description="No requirements found for this module" />
                    )}
                  </CollapseCard.Body>
                </CollapseCard>
              ))}

            {Boolean(documentsChecklist.length) && (
              <CollapseCard key={'documents-checklist'}>
                <CollapseCard.Header>
                  <StyledTitle level={5}>Documents checklist</StyledTitle>
                </CollapseCard.Header>
                <CollapseCard.Body>
                  <RequestRecordDocumentsChecklist
                    documentsChecklist={documentsChecklist}
                  />
                </CollapseCard.Body>
              </CollapseCard>
            )}
          </>
        )}
      </StyledWrapper>
    </>
  );
}

const StyledWrapper = styled(FullWidthSpace)`
  padding: 16px;
`;

const StyledErrorWrapper = styled(Flex)`
  height: 100%;
`;

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;
