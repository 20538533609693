import { FullWidthSpace } from '@trustlayer/ui';
import { Space, Typography } from 'antd';
import styled from 'styled-components';

export const RequestRecordDocumentsChecklist = ({
  documentsChecklist,
}: {
  documentsChecklist: {
    documentType: {
      _id: string;
      name: string;
    };
    attributes: {
      code: string;
      label: string;
    }[];
  }[];
}) => {
  return documentsChecklist.map(({ documentType, attributes }) => {
    return (
      <StyledChecklistWrapper direction="vertical" key={documentType._id}>
        <StyledChecklistTitle level={5} key={documentType._id}>
          {documentType.name}
        </StyledChecklistTitle>

        {attributes.map(({ code, label }) => (
          <Space key={code} size="small">
            <DocumentChecklistIcon size="small" />
            <Typography.Text>{label}</Typography.Text>
          </Space>
        ))}
      </StyledChecklistWrapper>
    );
  });
};

const StyledChecklistWrapper = styled(FullWidthSpace)`
  padding-left: 0;
  margin-bottom: 24px;
`;

const StyledChecklistTitle = styled(Typography.Title)`
  padding-bottom: 0;
`;

const DocumentChecklistIcon = ({
  size = 'small',
}: { size?: 'small' | 'medium' }) => {
  return (
    <div
      style={{
        width: size === 'small' ? 10 : 15,
        height: size === 'small' ? 10 : 15,
        borderRadius: ' 50%',
        backgroundColor: '#737373',
      }}
    />
  );
};
