import { Modal, Popover, Skeleton } from 'antd';
import { useNavigate } from 'react-router';

import { useQuery } from '@graphql/hooks';
import {
  AUTOMATION_CODES,
  AutomationSwitch,
  DocumentRemindersAutomationSchedules,
} from '@modules/automations';
import { DisableForViewerRole } from '@modules/organization-member/containers/DisableForRoles';
import { hooks as legacyPartyHooks } from '@modules/party';
import { PartyAutomationScheduledActions } from '@modules/party/components/PartyAutomationScheduledActions';
import type { QueryPartyAutomation } from '@modules/party/queries';
import {
  type PartyAutomation,
  getPartyAutomationsMap,
} from '@modules/party/utils';
import { SidebarLoadingError } from 'layouts';

import { SidebarSubtitle } from '@modules/primary-records/components/SidebarSubtitle';
import { MISSING_AUTOMATION_ACTIONS_MESSAGE } from './constants';
import { AUTOMATIONS_QUERY } from './queries';

/**
 * @module - SidebarAutomations
 * @note - Things to improve in automations:
 * - [ ] check if the logic of mapping automations and appending it new props to complement the data, is correct.
 * - [X] get rid of the mocks and implement the real logic for the new data model (primary-records)
 * - [ ] get rid of all "party" references and replace it with the new data model (primary-records)
 * - [ ] avoid the use of utilities imported from **Party** module
 */

type GetDocumentRemindersPopoverArgs = {
  automation?: PartyAutomation;
};

/**
 * START: Moks - For now we decided to mock this since we need to finish the implementation in the backend
 */
const isTrackingCompliance = true;
const isPrimaryContactInvalid = false;
/**
 * END: Moks - For now we decided to mock this since we need to finish the implementation in the backend
 */

interface SidebarAutomationsProps {
  recordId: string;
  disabled?: boolean;
}

export const SidebarAutomations: React.FC<SidebarAutomationsProps> = ({
  recordId,
  disabled = false,
}) => {
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery(AUTOMATIONS_QUERY, {
    variables: { primaryRecordInput: { id: recordId } },
    skip: !recordId,
    notifyOnNetworkStatusChange: true,
  });

  const {
    updatePartyAutomation,
    isEnablePartyAutomationLoading: isAutomationChanging,
  } = legacyPartyHooks.usePartyAutomationUpdate();

  const automationsMap = getPartyAutomationsMap({
    partyAutomations: data?.primaryRecord
      ?.automations as QueryPartyAutomation[],
  });

  const documentRemindersAutomation =
    automationsMap?.[AUTOMATION_CODES.documentReminders];

  const expiringDocumentRemindersAutomation =
    automationsMap?.[AUTOMATION_CODES.expiringDocumentReminders];

  /**
   * @note Is visible when there is at least one scheduled action
   */
  const isAutomationEnabledPopoverVisible = Boolean(
    documentRemindersAutomation?.scheduledActions?.length,
  );

  const getDocumentRemindersPopoverProps = ({
    automation,
  }: GetDocumentRemindersPopoverArgs) => {
    if (!automation) {
      return {};
    }

    if (!automation.isEnablable) {
      return {
        content: MISSING_AUTOMATION_ACTIONS_MESSAGE,
      };
    }

    if (!automation.isEnabled) {
      return {
        title: 'Reminders',
        content: (
          <DocumentRemindersAutomationSchedules
            branches={automation.branches?.nodes}
          />
        ),
      };
    }

    const handleAutomationActionClick = ({
      partyConversationId,
      messageId,
    }: {
      partyConversationId?: string;
      messageId?: string;
    }) => {
      navigate({
        pathname: `messages/${partyConversationId}`,
        search: `?messageId=${messageId}`,
      });
    };

    return {
      trigger: isAutomationEnabledPopoverVisible ? ['hover'] : [],
      title: 'Reminders',
      content: (
        /**
         * @note Since Popover is coupled to the party we need to change a bit the logic for the new data model (primary-records)
         */
        <PartyAutomationScheduledActions
          actions={automation.scheduledActions}
          onViewClick={handleAutomationActionClick}
        />
      ),
    };
  };

  const DocumentRemindersLabel = () => {
    return (
      <Popover
        {...(disabled
          ? { trigger: [] }
          : getDocumentRemindersPopoverProps({
              automation: documentRemindersAutomation,
            }))}
      >
        <div className="title">{documentRemindersAutomation?.title}</div>
        {Boolean(documentRemindersAutomation?.isEnabled) && (
          <div className="subtitle">
            {documentRemindersAutomation?.subtitle}
          </div>
        )}
      </Popover>
    );
  };

  const handleOnAutomationChange = ({
    value,
    code,
  }: {
    value: any;
    code: any;
  }) => {
    const update = async () =>
      await updatePartyAutomation({
        id: recordId,
        isEnabled: value,
        automationCode: code,
      });

    if (code === AUTOMATION_CODES.documentReminders && !value) {
      Modal.confirm({
        title:
          'Are you sure you want to disable Document Reminders for document requests?',
        content: 'Re-enabling will start the reminder cadence over.',
        okText: 'Disable automation',
        cancelText: 'Cancel',
        onOk: update,
      });
      return;
    }
    update();
  };

  const automations = [
    {
      automation: expiringDocumentRemindersAutomation,
    },
    {
      automation: documentRemindersAutomation,
      renderLabel: () => <DocumentRemindersLabel />,
    },
  ];

  if (error) {
    return (
      <SidebarLoadingError
        loadingActionLabel="Automations"
        onActionClick={() => refetch()}
      />
    );
  }

  return (
    <>
      {Boolean(isTrackingCompliance) && (
        <Skeleton active loading={loading}>
          <SidebarSubtitle>Automations</SidebarSubtitle>
          <DisableForViewerRole>
            {automations.map(
              ({ automation, renderLabel }) =>
                automation && (
                  <AutomationSwitch
                    key={automation.code}
                    automation={automation}
                    isPrimaryContactInvalid={isPrimaryContactInvalid}
                    onAutomationChange={handleOnAutomationChange}
                    isAutomationChanging={isAutomationChanging}
                    disabled={disabled}
                  >
                    {renderLabel?.()}
                  </AutomationSwitch>
                ),
            )}
          </DisableForViewerRole>
        </Skeleton>
      )}
    </>
  );
};
