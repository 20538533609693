import {
  FolderOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Input, Popover } from 'antd';
import debounce from 'lodash/debounce';
import * as R from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import styled from 'styled-components';

import PillLabel from '@common/components/PillLabel';
import Spinner from '@common/components/Spinner';
import BuildingIcon from '@common/icons/BuildingIcon';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import {
  getActiveOrganizationData,
  getOrganizationNamespaceUrl,
} from '@modules/organization/selectors';
import { getViewedProjects } from '@modules/project/actions';
import { setProjectAsCurrent } from '@modules/system-settings/actions';
import { getGraphqlResponse } from '@store/helpers';

const ProjectSwitcher = ({
  children,
  visible,
  setVisible,
  openSaveProjectModal,
}) => {
  const [selectedProject, setSelectedProject] = useState(0);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const organization = useSelector(getActiveOrganizationData);
  const organizationNamespace = useSelector(getOrganizationNamespaceUrl);
  const dispatch = useDispatch();

  const inputRef = useRef();

  const fetchProjects = useCallback(
    (value) => {
      setIsLoading(true);
      dispatch(getViewedProjects({ first: 12, filter: { name: value } })).then(
        (payload) => {
          const projects = getGraphqlResponse(payload)?.nodes;
          projects && setProjects(projects);
          setIsLoading(false);
        },
      );
    },
    [dispatch],
  );

  useEffect(() => {
    visible && fetchProjects();
  }, [visible, fetchProjects]);

  useEffect(() => {
    if (visible) {
      setTimeout(
        () => inputRef && inputRef.current && inputRef.current.focus(),
        400,
      );
    }
  }, [visible]);

  const onSelectProject = (projectId) => {
    setVisible(false);
    dispatch(setProjectAsCurrent(projectId));
  };

  const onChangeHandle = useCallback(
    (value) => fetchProjects(value),
    [fetchProjects],
  );

  const debOnChangeHandle = debounce(onChangeHandle, 200);

  const onKeyPressHandler = (e) => {
    switch (e.key) {
      case 'Tab':
      case 'ArrowDown':
        e.preventDefault();
        setSelectedProject((prevState) =>
          prevState === projects.length ? 0 : R.inc(prevState),
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedProject((prevState) =>
          prevState === 0 ? projects.length : R.dec(prevState),
        );
        break;
      case 'Enter':
        onSelectProject(R.pathOr(null, [selectedProject - 1, '_id'], projects));
        break;
      default:
        break;
    }
  };

  return (
    <Popover
      destroyTooltipOnHide
      placement="bottomLeft"
      open={visible}
      onOpenChange={setVisible}
      content={
        <StyledContent onKeyDown={onKeyPressHandler}>
          <Input
            ref={inputRef}
            allowClear
            placeholder="Find projects by name..."
            prefix={<SearchOutlined />}
            onChange={(e) => debOnChangeHandle(e.target.value)}
          />
          <StyledOrganizationName
            data-cy="backToOrganizationContext"
            onClick={() => onSelectProject(null)}
            $isSelected={selectedProject === 0}
            onMouseEnter={() => setSelectedProject(0)}
          >
            <BuildingIcon />
            <span>{organization?.name}</span>
          </StyledOrganizationName>
          {isLoading ? (
            <Spinner />
          ) : (
            <StyledProjectsList data-cy="projectSwitcherList">
              {projects.map((project, i) => (
                <StyledProjectListItem
                  data-cy="projectSwitcherListItem"
                  $isSelected={i + 1 === selectedProject}
                  onMouseEnter={() => setSelectedProject(i + 1)}
                  key={`key_${project._id}`}
                  onClick={() => {
                    onSelectProject(project._id);
                  }}
                >
                  <StyledLeftContent>
                    <FolderOutlined />
                    <StyledProjectName>{project.name}</StyledProjectName>
                  </StyledLeftContent>
                  <StyledRightActions>
                    {!project.isActive && (
                      <PillLabel type="default" label="inactive" />
                    )}
                    <HideForViewerRole>
                      <SettingOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          openSaveProjectModal(project._id);
                          setVisible(false);
                        }}
                      />
                    </HideForViewerRole>
                  </StyledRightActions>
                </StyledProjectListItem>
              ))}
            </StyledProjectsList>
          )}

          <StyledFooter>
            <HideForViewerRole>
              <Button
                data-cy="projectSwitcherCreateNewProjectButton"
                icon={<PlusOutlined />}
                onClick={() => {
                  setVisible(false);
                  openSaveProjectModal(null);
                }}
              >
                Create new project
              </Button>
            </HideForViewerRole>
            <Link
              to={`${organizationNamespace}/projects`}
              onClick={() => setVisible(false)}
            >
              View all projects
            </Link>
          </StyledFooter>
        </StyledContent>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

const StyledContent = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const StyledLeftContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledRightActions = styled.div`
  position: relative;
  display: flex;
  gap: 6px;
  align-items: center;
`;

const popoverPadding = 12;

const StyledOrganizationName = styled.div`
  position: relative;
  padding: 5px 0;
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;

  &::before {
    position: absolute;
    left: ${popoverPadding * -1}px;
    z-index: 0;
    content: '';
    width: calc(100% + ${popoverPadding * 2}px);
    height: 100%;
    background-color: ${({ $isSelected }) => $isSelected && '#f4f8fd'};
  }

  * {
    margin-right: 10px;
    position: relative;
  }
`;

const StyledProjectsList = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

const StyledProjectListItem = styled.div`
  position: relative;
  padding-left: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  min-height: 34px;
  z-index: 1;

  &::before {
    position: absolute;
    left: ${popoverPadding * -1}px;
    z-index: 0;
    content: '';
    width: calc(100% + ${popoverPadding * 2}px);
    height: 100%;
    background-color: ${({ $isSelected }) => $isSelected && '#f4f8fd'};
  }

  .anticon-setting {
    opacity: ${({ $isSelected }) => ($isSelected ? '1' : '0.35')};
  }

  .anticon-setting:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const StyledProjectName = styled.span`
  font-size: 13px;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

const StyledFooter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default ProjectSwitcher;
