import { Typography } from 'antd';
import type React from 'react';
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router';
import styled from 'styled-components';

type LinkProps =
  | (RouterLinkProps & {
      children: React.ReactNode;
    })
  | {
      onClick?: () => void;
      children: React.ReactNode;
    };

export const LinkCell = ({ children, ...rest }: LinkProps) => {
  if ('to' in rest) {
    return (
      <RouterLink {...rest}>
        <StyledLink>{children}</StyledLink>
      </RouterLink>
    );
  }
  return <StyledLink onClick={rest.onClick}>{children}</StyledLink>;
};

const StyledLink = styled(Typography.Text)`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.2s ease-in-out;
  }
`;

export default LinkCell;
