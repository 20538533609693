import { Col, Modal, Row } from 'antd';
import * as R from 'ramda';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { AUTOMATION_CODES } from '@modules/automations/constants';
import PartyEventLogContainer from '@modules/event-log/containers/PartyEventLogContainer';
import { FIELD_ASSOCIATION_TYPES } from '@modules/fillable-form/constants';
import FillableFormRequirementsListContainer from '@modules/fillable-form/containers/FillableFormRequirementsListContainer';
import AdditionalNoteBox from '@modules/party/components/AdditionalNoteBox';
import {
  usePartyAutomationUpdate,
  usePartyAutomations,
} from '@modules/party/hooks';

import { trackEvent } from '@common/utils/track-helpers';
import { ComplianceRequirementsAddOnFeature } from '@modules/add-on';
import ComplianceSummary, { BoxType } from '../../components/ComplianceSummary';
import { ComplianceTrackingSwitch } from '../../components/ComplianceTrackingSwitch';
import { DocumentRequestsBox } from '../../components/DocumentRequestsBox';
import Documents from '../../components/Documents';
import { isPrimaryContactEmailEmpty } from '../../constants';

const TabOverviewContent = ({
  organizationId,
  currentProjectId,
  organizationNamespace,
  party,
  partyComplianceProfile,
  requirements,
  setCurrentRequirements,
  setCurrentComplianceProfile,
  onSendReminder,
  onSendRequest,
  latestRequest,
  updateAdditionalNotes,
  onDocumentFormUpdated,
  isPartyComplianceProfileLoading,
}) => {
  const navigate = useNavigate();
  const [isShowProfileDrawer, setIsShowProfileDrawer] = useState(false);
  const defaultPartiesUrl = `${organizationNamespace}/parties`;
  const isTrackingCompliance = party?.isTrackingCompliance || false;
  const isPrimaryContactInvalid = isPrimaryContactEmailEmpty(party);

  const documentReviewURL = `${defaultPartiesUrl}/${party?._id}/documents-review`;

  const handleAutomationActionClick = ({ partyConversationId, messageId }) => {
    navigate({
      pathname: `${defaultPartiesUrl}/${party?._id}/messages/${partyConversationId}`,
      search: `?messageId=${messageId}`,
    });
  };

  const { updatePartyAutomation, isEnablePartyAutomationLoading } =
    usePartyAutomationUpdate();

  const { data: partyAutomations, isLoading: isPartyAutomationsLoading } =
    usePartyAutomations({
      partyId: party?._id,
      queryOptions: {
        skip: !party?._id,
      },
    });

  const requestLandingLink = `${
    window.location.origin
  }/request-landing/${R.prop('_id', latestRequest)}`;

  const handleOnAutomationChange = ({ value, code }) => {
    const update = async () =>
      await updatePartyAutomation({
        id: party?._id,
        isEnabled: value,
        automationCode: code,
      });

    if (code === AUTOMATION_CODES.expiringDocumentReminders && value) {
      trackEvent('User enabled the "Expiring Document Reminder" automation');
    }
    if (code === AUTOMATION_CODES.documentReminders && value) {
      trackEvent('User enabled the "Document Reminders" automation');
    }

    if (code === AUTOMATION_CODES.documentReminders && !value) {
      Modal.confirm({
        title:
          'Are you sure you want to disable Document Reminders for document requests?',
        content: 'Re-enabling will start the reminder cadence over.',
        okText: 'Disable automation',
        cancelText: 'Cancel',
        onOk: update,
      });

      return;
    }

    update();
  };

  return (
    <div>
      <Row gutter={24}>
        <Col span={16}>
          <div>
            {party?.isTrackingCompliance && (
              <>
                <ComplianceRequirementsAddOnFeature>
                  <ComplianceSummary
                    organizationNamespace={organizationNamespace}
                    party={party}
                    partyComplianceProfile={partyComplianceProfile}
                    requirements={requirements}
                    type={BoxType.Overview}
                    setSelectedComplianceProfile={setCurrentComplianceProfile}
                    setCurrentRequirements={setCurrentRequirements}
                    isShowProfileDrawer={isShowProfileDrawer}
                    setIsShowProfileDrawer={setIsShowProfileDrawer}
                    isPartyComplianceProfileLoading={
                      isPartyComplianceProfileLoading
                    }
                  />
                </ComplianceRequirementsAddOnFeature>
                <FillableFormRequirementsListContainer
                  organizationId={organizationId}
                  filler={FIELD_ASSOCIATION_TYPES.REQUESTER}
                  partyId={party?._id}
                  selectedProjectId={currentProjectId}
                  onDocumentFormUpdated={onDocumentFormUpdated}
                  canSign={true}
                />
              </>
            )}
            <AdditionalNoteBox
              party={party}
              updateAdditionalNotes={updateAdditionalNotes}
            />
            <div>
              <PartyEventLogContainer partyId={party?._id} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <StyledColumnWrapper>
            <ComplianceRequirementsAddOnFeature>
              <ComplianceTrackingSwitch
                isTrackingCompliance={isTrackingCompliance}
                partyId={party?._id}
              />
            </ComplianceRequirementsAddOnFeature>
            <DocumentRequestsBox
              isTrackingCompliance={isTrackingCompliance}
              isPrimaryContactInvalid={isPrimaryContactInvalid}
              latestRequest={latestRequest}
              requestLandingLink={requestLandingLink}
              automations={partyAutomations}
              isLoadingAutomations={isPartyAutomationsLoading}
              onSendRequest={onSendRequest}
              onSendReminder={onSendReminder}
              onAutomationChange={handleOnAutomationChange}
              isAutomationChanging={isEnablePartyAutomationLoading}
              onAutomationActionClick={handleAutomationActionClick}
            />

            {currentProjectId ? (
              <>
                <Documents
                  title="Applicable documents"
                  projectId={currentProjectId}
                  organizationNamespace={organizationNamespace}
                  reviewPath={documentReviewURL}
                  totalDocumentsCount={party?.totalApplicableDocumentsCount}
                  documents={party?.applicableDocuments || []}
                />
                <Documents
                  title="All other documents"
                  projectId={currentProjectId}
                  organizationNamespace={organizationNamespace}
                  reviewPath={documentReviewURL}
                  totalDocumentsCount={
                    party?.totalDocumentsCount -
                    party?.totalApplicableDocumentsCount
                  }
                  documents={party?.documents || []}
                />
              </>
            ) : (
              <Documents
                title="Documents"
                projectId={currentProjectId}
                organizationNamespace={organizationNamespace}
                reviewPath={documentReviewURL}
                totalDocumentsCount={party?.totalDocumentsCount}
                documents={party?.documents || []}
              />
            )}
          </StyledColumnWrapper>
        </Col>
      </Row>
    </div>
  );
};

const StyledColumnWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
`;

export default TabOverviewContent;
