import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import type { RootState } from '@common/types';
import { useLazyQuery } from '@graphql/hooks';
import useLazyQueryWithRedux from '@graphql/hooks/useLazyQueryWithRedux';
import useQueryWithRedux from '@graphql/hooks/useQueryWithRedux';
import type { PartyWithDetailsQuery } from '@graphql/types/graphql';
import { getOperationName } from '@graphql/utils';
import { getActiveOrganizationId } from '@modules/organization/selectors';
import { GET_NEW_MESSAGES_COUNT } from '@modules/party-conversation/actions';
import { getNewMessagesCount } from '@modules/party-conversation/selectors';
import { FETCH_PARTY_WITH_DETAILS } from '@modules/party/actions';
import { getParty } from '@modules/party/selectors';

import { NetworkStatus } from '@apollo/client';
import {
  NEW_MESSAGES_COUNT_QUERY,
  PARTY_COMPLIANCE_PROFILE_QUERY,
  PARTY_WITH_DETAILS_QUERY,
} from '../queries';

type UsePartyDetailsProps = {
  partyId: string;
};

export const usePartyDetails = ({ partyId }: UsePartyDetailsProps) => {
  const navigate = useNavigate();
  const activeOrganizationId = useSelector(getActiveOrganizationId);

  const onPartyFetched = (data: PartyWithDetailsQuery) => {
    const party = data?.getPartyData;
    const partyOrg = party?.organization;

    if (partyOrg && partyOrg !== activeOrganizationId) {
      navigate('/not-found');
    }
  };

  const [
    fetchPartyWithDetails,
    {
      data: partyRes,
      loading: isPartyWithDetailsLoading,
      refetch: refetchPartyWithDetails,
      networkStatus,
    },
  ] = useLazyQueryWithRedux(PARTY_WITH_DETAILS_QUERY, {
    reduxActionKey: getOperationName(PARTY_WITH_DETAILS_QUERY),
    reduxActionType: FETCH_PARTY_WITH_DETAILS,
    reduxSelector: (state: RootState) => getParty(state, partyId),
    onCompleted: onPartyFetched,
    variables: { id: partyId },
    notifyOnNetworkStatusChange: true,
  });

  const party = partyRes?.getPartyData;

  const { data: newMessagesCountRes, refetch: fetchNewMessagesCount } =
    useQueryWithRedux(NEW_MESSAGES_COUNT_QUERY, {
      reduxActionKey: getOperationName(NEW_MESSAGES_COUNT_QUERY),
      reduxActionType: GET_NEW_MESSAGES_COUNT,
      reduxSelector: getNewMessagesCount,
      variables: { partyId },
    });

  const [
    fetchComplianceProfile,
    { data: complianceProfileRes, loading: isPartyComplianceProfileLoading },
  ] = useLazyQuery(PARTY_COMPLIANCE_PROFILE_QUERY);

  useEffect(() => {
    if (party?.partyComplianceProfile?.complianceProfile) {
      fetchComplianceProfile({
        variables: { id: party?.partyComplianceProfile?.complianceProfile },
      });
    }
  }, [
    party?.partyComplianceProfile?.complianceProfile,
    fetchComplianceProfile,
  ]);

  const fetchPartyDetails = useCallback(() => {
    fetchPartyWithDetails();
    fetchNewMessagesCount();
  }, [fetchPartyWithDetails, fetchNewMessagesCount]);

  /*
   Use the fetched compliance profile only if the party has a compliance profile - since it can be null
   At the moment we cannot use the party.partyComplianceProfile because the API does not return the data needed
  */
  const partyComplianceProfile = party?.partyComplianceProfile
    ?.complianceProfile
    ? complianceProfileRes?.getComplianceProfile
    : undefined;

  const isPartyRefetchinng = networkStatus === NetworkStatus.refetch;

  return {
    isPartyLoading: !isPartyRefetchinng && isPartyWithDetailsLoading,
    isPartyRefetchinng: isPartyRefetchinng,
    party,
    isPartyComplianceProfileLoading,
    partyComplianceProfile,
    newMessagesCount: newMessagesCountRes?.getNewMessagesCount,
    fetchPartyDetails,
    refetchPartyDetails: refetchPartyWithDetails,
  };
};
