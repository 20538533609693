import type { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { SearchType } from '@graphql/types/graphql';

const SEARCH_QUERY = graphql(`
query search($data: SearchInput!) {
  search(data: $data)
}`);

export const useSearch = (
  {
    type,
    query,
    limit = 10,
  }: {
    type: SearchType;
    query: string;
    limit?: number;
  },
  opts?: QueryHookOptions,
) => {
  const { data, loading, error } = useQuery(SEARCH_QUERY, {
    ...opts,
    fetchPolicy: 'no-cache',
    variables: { data: { type, query, options: { limit } } },
  });

  return { data: data?.search, loading, error };
};
