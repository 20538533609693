import { Button, Dropdown, Menu } from 'antd';
import type { ReactNode } from 'react';
import styled from 'styled-components';

type Item<Code = string, Options = any> = {
  code: Code;
  label: string;
  disabled?: boolean;
  icon?: ReactNode;
  testId?: string;
  options?: Options;
  danger?: boolean;
};

type ActionsMenuWithButtonsProps<Code = string, Options = any> = {
  items: Item<Code, Options>[];
  onClick: (itemCode: Code, options?: Options) => void;
  expandedItemsCount?: number;
};

export const ActionsMenuWithButtons = <Code, Options>({
  items,
  onClick,
  expandedItemsCount = 4,
}: ActionsMenuWithButtonsProps<Code, Options>) => {
  const expandedItems = items.slice(0, expandedItemsCount);
  const collapsedItems = items.slice(expandedItemsCount);

  return (
    <StyledButtons>
      {expandedItems.map((item, index) => (
        <Button
          key={`${item.code}-${index}`}
          data-cy={item.testId}
          icon={item.icon}
          onClick={() => onClick(item.code)}
          disabled={item.disabled}
          danger={item.danger}
        >
          {item.label}
        </Button>
      ))}

      {Boolean(collapsedItems.length) && (
        <Dropdown
          trigger={['click']}
          dropdownRender={() => (
            <Menu data-cy="bulk-actions-menu-more">
              {collapsedItems.map((item, index) => (
                <Menu.Item
                  key={`${item.code}-${index}`}
                  data-cy={item.testId}
                  onClick={() => onClick(item.code, item.options)}
                  disabled={item.disabled}
                  danger={item.danger}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          )}
        >
          <Button data-cy="bulk-actions-menu-more-trigger">More...</Button>
        </Dropdown>
      )}
    </StyledButtons>
  );
};

const StyledButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
