import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { Button, Empty, Input } from 'antd';
import * as R from 'ramda';
import { Fragment, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { reorder } from '@common/utils/filter-helpers';
import {
  flatGroupedRules,
  groupRules,
} from '@modules/compliance-profile/utils/compliance-attributes-helpers';
import { FILLABLE_FORMS_MODULE_ID } from '@modules/fillable-form/constants';
import { FEATURE_FLAGS } from '@modules/organization/constants';
import useFeatures from '@modules/organization/hooks/useFeatures';
import { ConnectedRequirementsAttributeIds } from '@modules/requirement/constants';
import { getTemplates } from '@modules/requirement/selectors';

import { setCurrentRequirements, setIsActiveSubject } from '../actions';
import ComplianceModuleLabelsContainer from '../containers/ComplianceModuleLabelsContainer';
import DocumentChecklistContainer from '../containers/DocumentChecklistContainer';
import { getCurrentRequirements } from '../selectors';

import { groupByModule } from '@common/utils/group-by-module';
import ComplianceDrawerModule from './ComplianceDrawerModule';
import ComplianceProfileAlert from './ComplianceProfileAlert';

const ComplianceProfileEditor = ({
  activeKeyTab,
  isParty,
  party,
  setActiveKeyTab,
  showConfirmClose,
  isCustomField,
  organizationNamespace,
  currentDocumentChecklists,
  complianceProfile,
  setIsVisibleAddModuleModal,
  setIsVisibleAddChecklistModal,
  setIsVisibleAddFillableFormModal,
  setEditSubjectId,
  setEditSubjectValue,
  setEditModuleId,
  setEditModuleValue,
  setIsVisibleAddSubjectModal,
  setSelectedSubjectData,
  openSelectSurveyModal,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const { hasFeature } = useFeatures();
  const hasFillableForm = hasFeature(FEATURE_FLAGS.BETA_FILLABLE_FORMS);
  const hasNPN = hasFeature(FEATURE_FLAGS.FEATURE_NPN);

  const filterUnflaggedRequirements = useCallback(
    (requirements) =>
      requirements.filter(({ attributeId, moduleId }) => {
        if (!hasFillableForm && moduleId === FILLABLE_FORMS_MODULE_ID) {
          return false;
        }

        if (!hasNPN && attributeId === ConnectedRequirementsAttributeIds.NPN) {
          return false;
        }

        return true;
      }),
    [hasFillableForm, hasNPN],
  );

  const currentRequirements = filterUnflaggedRequirements(
    useSelector(getCurrentRequirements),
  );
  const templates = filterUnflaggedRequirements(useSelector(getTemplates));
  const activeModules = groupByModule(currentRequirements);
  const modulesRefs = useMemo(() => ({}), []);
  const checklistsRefs = useMemo(() => ({}), []);

  const onSetIsActiveSubject = (moduleId, subjectId, isRequirements) =>
    dispatch(setIsActiveSubject(moduleId, subjectId, isRequirements));

  const onSetCurrentRequirements = (data) =>
    dispatch(setCurrentRequirements(data));

  const onScrollHandle = () => {
    const currentScroll = ref.current.scrollTop;
    const refs = [...R.toPairs(modulesRefs), ...R.toPairs(checklistsRefs)];

    R.compose(
      R.reduce((acc, item) => {
        if (currentScroll >= acc) {
          setActiveKeyTab(item.moduleId);
          return item.height;
        }

        return acc;
      }, 0),
      R.sort((a, b) => a.height - b.height),
      R.map(([moduleId, scrollData = {}]) => ({
        moduleId,
        height:
          R.prop('clientHeight', scrollData) + R.prop('offsetTop', scrollData),
      })),
    )(refs);
  };

  const reorderRequirements = (id, type, sourceIndex, destinationIndex) => {
    const groupedRules = groupRules(currentRequirements);

    if (type.endsWith('_module')) {
      const sortedRules = reorder(groupedRules, sourceIndex, destinationIndex);
      return flatGroupedRules(sortedRules);
    }

    if (type.endsWith('_subject')) {
      const sortedRules = groupedRules.map((mod) =>
        mod.id === id
          ? {
              ...mod,
              children: reorder(mod.children, sourceIndex, destinationIndex),
            }
          : mod,
      );

      return flatGroupedRules(sortedRules);
    }

    if (type.endsWith('_attribute')) {
      const [moduleId] = type.split('_');
      const sortedRules = groupedRules.map((mod) => {
        if (mod.id !== moduleId) {
          return mod;
        }

        return {
          ...mod,
          children: mod.children.map((subject) => {
            if (subject.id !== id) {
              return subject;
            }

            return {
              ...subject,
              children: reorder(
                subject.children,
                sourceIndex,
                destinationIndex,
              ),
            };
          }),
        };
      });

      return flatGroupedRules(sortedRules);
    }

    return currentRequirements;
  };

  const onDragEnd = ({ destination, source, type }) => {
    if (!destination || destination.index === source.index) {
      return;
    }

    const orderedRequirements = reorderRequirements(
      destination.droppableId,
      type,
      source.index,
      destination.index,
    );

    dispatch(setCurrentRequirements(orderedRequirements));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ComplianceProfileEditor.Wrapper>
        <ComplianceProfileEditor.Content>
          <ComplianceModuleLabelsContainer
            activeKeyTab={activeKeyTab}
            setIsVisibleAddModuleModal={setIsVisibleAddModuleModal}
            setIsVisibleAddChecklistModal={setIsVisibleAddChecklistModal}
            setIsVisibleAddFillableFormModal={setIsVisibleAddFillableFormModal}
          />
          <ComplianceProfileEditor.ModulesContainer
            data-cy="modulesContainerDrawer"
            ref={ref}
            onScroll={onScrollHandle}
          >
            {!isCustomField && (
              <ComplianceProfileAlert
                isParty={isParty}
                partyName={R.propOr('', 'name', party)}
                complianceProfileName={R.propOr('', 'name', complianceProfile)}
                organizationNamespace={organizationNamespace}
              />
            )}
            {!Boolean(activeModules.length) && (
              <ComplianceProfileEditor.Empty
                description="No requirements to show"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            )}
            <Droppable
              droppableId={`complianceProfile`}
              type={`complianceProfile_module`}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {activeModules.map(({ moduleId, moduleLabel }, index) => (
                    <ComplianceDrawerModule
                      key={moduleId}
                      moduleId={moduleId}
                      moduleLabel={moduleLabel}
                      modulesRefs={modulesRefs}
                      setEditSubjectId={setEditSubjectId}
                      setEditSubjectValue={setEditSubjectValue}
                      setEditModuleId={setEditModuleId}
                      setEditModuleValue={setEditModuleValue}
                      setIsVisibleAddSubjectModal={setIsVisibleAddSubjectModal}
                      setIsVisibleAddFillableFormModal={
                        setIsVisibleAddFillableFormModal
                      }
                      setSelectedSubjectData={setSelectedSubjectData}
                      index={index}
                      templates={templates}
                      requirements={currentRequirements}
                      setIsActiveSubject={onSetIsActiveSubject}
                      setCurrentRequirements={onSetCurrentRequirements}
                      openSelectSurveyModal={openSelectSurveyModal}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {R.compose(
              R.map(({ type }) => (
                <Fragment key={type._id}>
                  <DocumentChecklistContainer
                    type={type}
                    checklistsRefs={checklistsRefs}
                  />
                  <div style={{ height: '30px' }} />
                </Fragment>
              )),
              R.uniq,
              R.filter(({ isActive }) => isActive),
              R.map(({ isActive, type }) => ({ isActive, type })),
            )(currentDocumentChecklists)}
          </ComplianceProfileEditor.ModulesContainer>
        </ComplianceProfileEditor.Content>
      </ComplianceProfileEditor.Wrapper>
    </DragDropContext>
  );
};

ComplianceProfileEditor.Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

ComplianceProfileEditor.Empty = styled(Empty)`
  padding: 30px;
`;

ComplianceProfileEditor.NameInput = styled(Input)`
  width: 400px !important;
`;

ComplianceProfileEditor.TitleLabel = styled.label`
  margin-left: 15px;
  font-size: 18px;
`;

ComplianceProfileEditor.ManageProfile = styled.span`
  margin-left: 20px;
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`;

ComplianceProfileEditor.Content = styled.div`
  display: flex;
  width: 100%;
`;

ComplianceProfileEditor.ModulesContainer = styled.div`
  margin-top: 0;
  padding: 0 0 25px 25px;
  flex: 1;

  > div:last-child {
    padding-bottom: 30px;
  }
`;

ComplianceProfileEditor.SaveButton = styled(Button)`
  margin-left: auto;
`;

ComplianceProfileEditor.Input = styled(Input)`
  width: 200px !important;
  margin-left: 10px !important;
  font-size: 18px;
`;

ComplianceProfileEditor.RequirementsCount = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grayText};
`;

ComplianceProfileEditor.ManageProfile = styled.span`
  margin-left: 20px;
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`;

ComplianceProfileEditor.DropdownWrapper = styled.div`
  width: 400px;
`;

export default ComplianceProfileEditor;
