import qs from 'query-string';
import * as R from 'ramda';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

import ListPageFilterTags from '@common/components/ListPageFilterTags';
import { getDocumentTypes } from '@modules/document-type/selectors';
import { ReportType } from '@modules/report/constants';
import SaveReportModal from '@modules/report/containers/SaveReportModal.legacy';

import { Skeleton } from 'antd';
import { normalizedDocumentsFilters } from '../../utils/normalizedDocumentsFilters';
import { useDocumentsFilterTags } from './hooks';

const DocumentsFilterTagsContainer = ({
  url,
  loadedProjects,
  onResetFilters,
  onCloseChip,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isVisibleSaveReportModal, setIsVisibleSaveReportModal] =
    useState(false);
  const documentTypes = useSelector(getDocumentTypes);

  const searchParams = qs.parse(search);

  const handleOnCloseChip = (filterCode) => {
    const omittedParams = R.compose(
      (keys) => R.omit(keys, searchParams),
      R.filter(
        (filterKey) => ~filterKey.indexOf(filterCode) || filterKey === 'report',
      ),
      R.keys,
    )(searchParams);
    navigate(`${url}?${qs.stringify(omittedParams)}`, { replace: true });
    onCloseChip();
  };

  const { subjects, isFilterTagsDataLoading } = useDocumentsFilterTags({
    activeFilters: searchParams,
  });

  const normalizedFilters = normalizedDocumentsFilters({
    documentTypes,
    subjects,
    projects: loadedProjects,
    activeFilters: searchParams,
  });

  if (isFilterTagsDataLoading) {
    return <StyledInputSkeleton active />;
  }

  return (
    <StyledWrapper>
      <SaveReportModal
        reportsType={ReportType.Documents}
        isVisible={isVisibleSaveReportModal}
        onClose={() => setIsVisibleSaveReportModal(false)}
      />
      <ListPageFilterTags
        isSaveReportVisible={!searchParams?.report}
        filters={normalizedFilters}
        onSaveReport={() => setIsVisibleSaveReportModal(true)}
        onResetFilters={onResetFilters}
        onClose={handleOnCloseChip}
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
`;

const StyledInputSkeleton = styled(Skeleton.Input)`
  vertical-align: middle;
  height: 35px
`;

export default DocumentsFilterTagsContainer;
